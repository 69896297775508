import React from "react";
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import PageSpinner from "./components/PageSpinner";
import Testimonial1 from "./pages/testimonials/Testimonial1";
import Testimonial2 from "./pages/testimonials/Testimonial2";
import Testimonial3 from "./pages/testimonials/Testimonial3";
import './fonts/Cormorant_Garamond/CormorantGaramond-Light.ttf';
import './fonts/Ibarra_Real_Nova/IbarraRealNova-Regular.ttf';
import './fonts/Amatic_SC/AmaticSC-Regular.ttf';
import './fonts/Calligraffitti/Calligraffitti-Regular.ttf';
import './fonts/Playfair_Display/PlayfairDisplay-VariableFont_wght.ttf'
import './fonts/Beth_Ellen/BethEllen-Regular.ttf'
import HomePageSwitch from "./pages/HomePageSwitch";
import ServicesPageSwitch from "./pages/ServicesPageSwitch";
import TestimonialsPageSwitch from "./pages/testimonials/TestimonialsPageSwitch";
import AboutMeSwitch from "./pages/AboutMeSwitch";
import BlogPageSwitch from "./pages/BlogPageSwitch";
import CareerCrossroadsSwitch from "./pages/CareerCrossroadsSwitch";
import Testimonial4 from "./pages/testimonials/Testimonial4";
import BlogItem2Switch from "./pages/BlogItem2Switch";
import ExpressCoachingPowerHourMobile from "./pages/ExpressCoachingPowerHourMobile";
import CvAndLinkedInPackageMobile from "./pages/CvAndLinkedInPackageMobile";

class App extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <React.Suspense fallback={<PageSpinner/>}>
                        <Route exact path="/" component={HomePageSwitch}/>
                        <Route exact path="/testimonials" component={TestimonialsPageSwitch}/>
                        <Route exact path="/services" component={ServicesPageSwitch}/>
                        <Route exact path="/testimonialOne" component={Testimonial1}/>
                        <Route exact path="/testimonialTwo" component={Testimonial2}/>
                        <Route exact path="/testimonialThree" component={Testimonial3}/>
                        <Route exact path="/testimonialFour" component={Testimonial4}/>
                        <Route exact path="/about-me" component={AboutMeSwitch}/>
                        <Route exact path="/career-crossroads" component={CareerCrossroadsSwitch}/>
                        <Route exact path="/power-hour" component={ExpressCoachingPowerHourMobile}/>
                        <Route exact path="/cv-linkedIn" component={CvAndLinkedInPackageMobile}/>
                        <Route exact path="/blog" component={BlogPageSwitch}/>
                        <Route exact path="/blog/13012024" component={BlogItem2Switch}/>
                    </React.Suspense>
                    <Redirect to="/"/>
                </Switch>
            </BrowserRouter>
        )
    }
}
export default App;