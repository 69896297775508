import React from "react";
import {Card, CardText} from "reactstrap";
import {Col, Grid} from "gymnast";
import Fade from "react-reveal";

const boxStyleGrey = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...boxStyleGrey, minHeight: '20vw', align: "top"},
    TALL: {...boxStyleGrey, minHeight: '50vw'},
    XTALL: {...boxStyleGrey, minHeight: '100vw', align: "top"},
    SMALL: {...boxStyleGrey, minHeight: 100},
    MEDIUM_WHITE: {...boxStyleGrey, minHeight: 300},
    XSMALL: {...boxStyleGrey, minHeight: 30},
};

class HowIHelpIndividuals extends React.Component {
    render() {
        return (
            <Grid marginTop="S" paddingTop="0">
                <Col size={12} style={style.MEDIUM}>
                    <Card className=' width100 border-0'
                          style={{backgroundColor: '#FFFFFF', marginLeft: '10px', marginRight: '10px', width: '100%'}}>
                        <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                  style={{color: "black", textAlign: 'left'}}>
                            <Fade right >
                                <div>
                                    <h2 className='playfair_Display' style={{fontSize: '30px'}}> How I Help
                                        Individuals </h2>
                                </div>
                            </Fade>
                            <Fade left >
                                <div>
                                    <p className='playfair_Display' style={{fontSize: '16px'}}>Are you:</p>
                                </div>
                            </Fade>
                            <ul>
                                <Fade left >
                                    <div>
                                        <li style={{fontSize: '14px'}}>Feeling <h4
                                            style={{fontWeight: 'bold', fontSize: '14px'}}>frustrated</h4> in your
                                            career - like something has to change
                                        </li>
                                        <li style={{fontSize: '14px'}}><h4
                                            style={{fontWeight: 'bold', fontSize: '14px'}}>Lacking in motivation </h4>
                                            for a role you used to love
                                        </li>
                                        <li style={{fontSize: '14px'}}>Desperate for a better <h4
                                            style={{fontWeight: 'bold', fontSize: '14px'}}>work life balance</h4></li>
                                        <li style={{fontSize: '14px'}}>Worried that you're <h4
                                            style={{fontWeight: 'bold', fontSize: '14px'}}>fast approaching
                                            burnout</h4></li>
                                    </div>
                                </Fade>
                            </ul>
                        </CardText>
                    </Card>
                </Col>

                <Col size={12} style={style.MEDIUM}>
                    <Card className=' width100 border-0 center'
                          style={{backgroundColor: '#FFFFFF', marginLeft: '10px', marginRight: '10px', width: '100%'}}>
                        <CardText style={{textAlign: 'center'}}>
                            <Fade right >
                                <div>
                                    <p className='CormorantGaramondWithoutSizeAndSpacing'
                                       style={{fontSize: '16px', color: "black"}}>Then click here to find out how I can
                                        support
                                        you.
                                    </p>
                                    <br/>
                                    <a className='CormorantGaramondWithoutSizeAndSpacing' style={{
                                        color: '#000000',
                                        backgroundColor: '#F9EBEA',
                                        borderRadius: '10px',
                                        border: '0',
                                        fontSize: '16px'
                                    }} href="#my_services_for_individuals">My Services For Individuals</a>
                                    <br/>
                                </div>
                            </Fade>
                        </CardText>
                    </Card>
                </Col>
            </Grid>
        )
    }
}

export default HowIHelpIndividuals;