import React from "react";
import {Card, CardText} from "reactstrap";

class LifeCoachQuote extends React.Component {
    render() {
        return (
            <Card className='width100 border-0 h-100 '
                  style={{fontSize: '24px', color: "black", backgroundColor: '#F9EBEA'}}>
                <CardText className='ibarra'>
                    <br/>
                    <p className='ibarra '> Investing in yourself is the best investment you will ever make. It will not
                        only improve your life, it will improve the lives of others around you. </p>
                    <br/>
                </CardText>
            </Card>
    )
    }
}

export default LifeCoachQuote;