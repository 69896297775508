import React from 'react';
import '../css/Alignment.css';
import '../css/Components.css';
import '../css/Animations.css';
import '../css/Elements.css'
import '../css/Fonts.css'
import '../css/Parallax.css'
import '../css/Size.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Grid, GymnastProvider} from "gymnast";
import MyNavBar from "../components/NavBar";
import {Card, CardText} from "reactstrap";
import TheProblemWithToday from "../components/TheProblemWithToday";
import StopThinkingJustDoIt from "../components/StopThinkingJustDoIt";
import {CardImg} from "react-bootstrap";
import MakeAChange from "../components/MakeAChange";
import woman2 from "../resources/images/coffee.jpg";
import AboutMe from "../components/AboutMe";
import Footer from "../components/Footer";
import TestimonialQuotes from "../components/TestimonialQuotes";
import saltburn from "../resources/images/quaid-lagan-OPYDe-bOGzs-unsplash.png";
import {Parallax} from "react-parallax";
import Fade from "react-reveal";
import BlockA2 from "../components/BlockA2";
import TypesOfCoachingHomePage from "../components/TypesOfCoachingHomePage";
import HomePageTestimonial from "../components/HomePageTestimonial";
import WhatCoachingCanDoForYou from "../components/WhatCoachingCanDoForYou";
import TodayIsNotJustAnotherDay from "../components/TodayIsNotJustAnotherDay";
import OnceHubPopUpWidget from "../components/OnceHubPopUpWidget";
import Alert from "react-bootstrap/Alert";
import {Sidetab} from "@typeform/embed-react";


const boxStyleGrey = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};


const boxStyleWhite = {
    color: "#FFFFFF",
    backgroundColor: "#FFFFFF"
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...boxStyleGrey, minHeight: '20vw', align: "top"},
    TALL: {...boxStyleGrey, minHeight: '50vw'},
    XTALL: {...boxStyleGrey, minHeight: '100vw', align: "top"},
    SMALL: {...boxStyleGrey, minHeight: 100},
    MEDIUM_WHITE: {...boxStyleWhite, minHeight: 300},
    XSMALL: {...boxStyleGrey, minHeight: 30},
};

class HomePageLaptop extends React.Component {
    render() {
        function FullWidthGrid() {
            return (
                <div className="background">
                    <GymnastProvider>
                        <MyNavBar/>
                        <Alert className='playfair_Display' style={{backgroundColor: '#F9EBEA', fontSize:'18px', textAlign: 'center', fontWeight: 'bold'}}>
                            Take a look at my Career Change Coaching Program{' '}
                            <Alert.Link style={{color: "black", fontStyle: 'italic'}} target="_blank" href="/career-crossroads"> here</Alert.Link>.
                        </Alert>
                        <Sidetab id="sYb8zoh0" buttonText="Career Quiz!" buttonColor='#4FB0AE'
                                 customIcon={"<img src=\"favicon.ico\" alt=\"logo\"/>"} autoClose='2000' open='time' openValue='2000' />

                        <Grid marginTop="S" paddingTop="0">
                            <Parallax bgImage={saltburn} strength={500}>
                                <div style={{height: 1000, width: '100vw'}}>
                                    <Card className='no-background width100 border-0'>
                                        <Col style={style.MEDIUM}>
                                            <Col size={4} style={style.XSMALL}>
                                                <Card className='avatar border-0'
                                                      style={{backgroundColor: 'rgba(0,0,0,0)'}}>
                                                    <br/>
                                                    <CardImg src={woman2} className="card-image viaducts"/>
                                                </Card>
                                            </Col>
                                            <Col size={8} style={style.SMALL}>
                                                <Card className='width100 border-0 no-background'>
                                                    <CardText className='CormorantGaramondWithoutSize'>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <h1 style={{fontSize: '48px', color: "black"}}>I’m Joanna Stokes
                                                            And I Coach Further Education Leaders To
                                                            Achieve Ultimate Career Fulfilment
                                                        </h1>
                                                        <br/>
                                                        <h2 className='ibarra' style={{fontSize: '22px', color: "black", fontWeight: 'bold'}} >Are you ready to explore your potential?</h2>
                                                        <CardText className="playfair_Display margin-left " style={{
                                                            fontSize: '22px',
                                                            color: "black"
                                                        }}>
                                                        </CardText>
                                                    </CardText>
                                                </Card>
                                            </Col>
                                        </Col>
                                    </Card>
                                </div>
                            </Parallax>
                            <OnceHubPopUpWidget/>
                            <Col style={style.XSMALL}/>
                            <Col style={style.SMALL}>
                                <BlockA2/>
                            </Col>

                            <Col style={style.SMALL}>
                                <TodayIsNotJustAnotherDay/>
                            </Col>

                            <Col style={style.SMALL}>
                                <Col size={1} style={style.XSMALL}/>
                                <Col size={10} style={style.XSMALL}>
                                <Card className=' border-0 no-background' style={{backgroundColor: 'white'}}>
                                    <br/>
                                    <h2 className='CormorantGaramondWithoutSize word-breaks'
                                        style={{fontSize: '40px', color: "black", fontWeight: 'bold'}}>
                                        <Fade left >
                                            <div className='word-breaks'>
                                        Joanna Stokes – Leadership And Career Coach For Leaders – North East, Yorkshire And
                                        Worldwide
                                            </div>
                                        </Fade>
                                    </h2>
                                    <Fade right >
                                        <div>
                                    <CardText>
                                        <p className='CormorantGaramondWithoutSizeAndSpacing'
                                           style={{fontSize: '24px', color: "black"}}>I’m Joanna Stokes,
                                            leadership coach, career coach and trainer in North East England (covering
                                            everywhere from Newcastle to Middlesbrough to York – and of course worldwide).
                                            I specialise in helping Further Education leaders find their
                                            <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                style={{fontWeight: 'bold'}}
                                            > ultimate career fulfilment. </h4>
                                        </p>
                                        <br/>
                                    </CardText>
                                        </div>
                                    </Fade>
                                </Card>
                                </Col>
                                <Col size={1} style={style.XSMALL}/>
                            </Col>
                            <Col style={style.SMALL}>
                                <TypesOfCoachingHomePage/>
                            </Col>

                            <Col style={style.MEDIUM}>
                                <MakeAChange/>
                            </Col>

                            <Col style={style.SMALL}>
                                <Card className='width100 border-0'>
                                    <CardText className='ibarraSize22' style={{fontSize: '56px', color: "black"}}>
                                        <Fade right big>
                                            <h2 style={{fontSize: '56px', fontFamily: 'Ibarra'}}>Learn About Joanna Stokes - Coaching Leaders To A Happier Life </h2>
                                        </Fade>
                                    </CardText>
                                </Card>
                            </Col>
                            <Col style={style.TALL}>
                                <AboutMe/>
                            </Col>

                            <Col style={style.MEDIUM_WHITE}>
                                <TheProblemWithToday/>
                            </Col>
                            <Col style={style.MEDIUM_WHITE}>
                                <HomePageTestimonial/>
                            </Col>

                            <Col style={style.MEDIUM_WHITE}>
                                <WhatCoachingCanDoForYou/>
                            </Col>

                            <Col style={style.SMALL}>
                                <Card className='width100 height100 border-0'>
                                    <CardText className='ibarra quote' style={{fontSize: '56px', color: "black", backgroundColor: '#F9EBEA'}}>
                                        Testimonials
                                    </CardText>
                                </Card>
                            </Col>

                            <Col style={style.MEDIUM}>
                                <TestimonialQuotes/>
                            </Col>

                            <Col style={style.SMALL}>
                                <StopThinkingJustDoIt/>
                            </Col>
                        </Grid>
                        <Footer/>
                    </GymnastProvider></div>

            );
        }

        return (
            <FullWidthGrid/>
        );
    }
}

export default HomePageLaptop;
