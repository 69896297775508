import React from "react";
import '../App.css';

class CustomButton extends React.Component {
    fetchScript = () => new Promise((resolve) => {
        if (window.EBWidgets) {
            return resolve(window.EBWidgets);
        }

        const script = document.createElement('script');
        script.id = 'scriptId';
        script.async = true;
        script.src = "https://cdn.oncehub.com/mergedjs/so.js";
        script.addEventListener('load', () => resolve(window.EBWidgets));

        document.head.appendChild(script);
    });

    async componentWillMount() {
        try {
            await this.fetchScript();
        } catch (e) {
            console.log("Could not load widget")
        }
    }

    render() {
        console.log(this.props);
        return (
            button(this.props)
        );
    }
}

const button = (something) => {
    return <button id="SOIBTN_JoannaStokes"
                   style={{fontWeight: something.fontWeight, backgroundColor: something.color, color: something.textColour, padding: something.padding, border: '0', font: 'bold Ibarra', borderRadius: something.borderRadius, cursor: 'pointer'}}
                   data-height="580" data-psz="00" data-so-page="JoannaStokes" data-delay="1">{something.text}</button>
};

button.defaultProps = {
    padding: '10px 20px',
    borderRadius: '10px',
    textColour: '#000000',
    fontWeight: 'normal'
};

export default CustomButton

