import React from "react";
import {Card, CardText} from "reactstrap";
import {Col, Grid} from "gymnast";
import Fade from "react-reveal";

const boxStyleGrey = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...boxStyleGrey, minHeight: '20vw', align: "top"},
    TALL: {...boxStyleGrey, minHeight: '50vw'},
    XTALL: {...boxStyleGrey, minHeight: '100vw', align: "top"},
    SMALL: {...boxStyleGrey, minHeight: 100},
    MEDIUM_WHITE: {...boxStyleGrey, minHeight: 300},
    XSMALL: {...boxStyleGrey, minHeight: 30},
};

class TwoLessonsPart2 extends React.Component {
    render() {
        return (
            <Card className='width100 border-0 ' style={{color: "black", backgroundColor: '#F9EBEA'}}>
                <Grid marginTop="S" paddingTop="0">
                    <Col style={style.SMALL}/>
                    <Col size={1} style={style.XSMALL}/>
                    <Col size={10} style={style.XSMALL}>
                        <Card className='width100 border-0 no-background ' style={{color: "black"}}>
                            <CardText>
                                <br/>
                                <Fade top big >
                                    <div>
                                        <p style={{fontSize: '32px'}}><h4
                                            className='ibarra'
                                            style={{fontWeight: 'bold', fontSize: '36px', fontStyle: 'italic'}}>If you
                                            are a leader, learn how to be the best leader you can be.
                                        </h4>
                                        </p>
                                    </div>
                                </Fade>
                            </CardText>

                        </Card>
                    </Col>
                    <Col size={1} style={style.XSMALL}/>
                    <Col size={2} style={style.XSMALL}/>
                    <Col size={8} style={style.XSMALL}>
                        <Card className=' width100 border-0 no-background'
                              style={{
                                  backgroundColor: '#FFFFFF',
                                  marginLeft: '10px',
                                  marginRight: '10px',
                                  width: '100%',
                              }}>
                            <Fade right big >
                                <div>
                                    <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                              style={{color: "black", fontSize: '24px'}}>
                                        <br/>
                                        <p style={{fontSize: '38px'}}>Never be afraid to listen, watch and embrace
                                            leadership training.</p>
                                        <br/>
                                        <p style={{fontSize: '38px'}}>And then show up as a human being first, and
                                            inspire.</p>
                                        <br/>
                                        <p style={{fontSize: '38px'}}>Because strong leaders create prosperous teams –
                                            and I know you deserve that kind of success.</p>
                                    </CardText>
                                </div>
                            </Fade>
                        </Card>
                    </Col>
                    <Col size={2} style={style.XSMALL}/>
                    <Col size={1} style={style.XSMALL}/>
                    <Col size={10} style={style.XSMALL}>
                        <Card className=' width100 border-0 center no-background'
                              style={{
                                  backgroundColor: '#FFFFFF',
                                  marginLeft: '10px',
                                  marginRight: '10px',
                                  width: '100%',
                              }}>
                            <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                      style={{color: "black", textAlign: 'left', fontSize: '24px'}}>
                                <br/>
                                <Fade left big >
                                    <div>
                                        <p style={{fontSize: '30px'}}><h4
                                            className='ibarra' style={{fontWeight: '', fontSize: '26px'}}>If you want to
                                            know
                                            more about how I can help you create your perfect working life, whatever
                                            your
                                            situation, visit my Services page to discover more.
                                        </h4>
                                        </p>
                                        <br/>
                                        <a className='CormorantGaramondWithoutSizeAndSpacing' style={{
                                            color: '#000000',
                                            backgroundColor: '#C0DAE2',
                                            borderRadius: '10px',
                                            border: '0',
                                            fontSize: '26px',
                                            fontWeight: 'bold'
                                        }} href="/services">I'm Ready To Find Out More
                                        </a>
                                    </div>
                                </Fade>
                            </CardText>
                        </Card>
                    </Col>
                    <Col size={1} style={style.XSMALL}/>
                </Grid>

                <Col style={style.SMALL}/>

            </Card>
        )


    }
}

export default TwoLessonsPart2;