import React from "react";
import {Card, CardText} from "reactstrap";

const TEXT_COLLAPSE_OPTIONS = {
    collapse: false, // default state when component rendered
    collapseText: '... show more', // text to show when collapsed
    expandText: 'show less', // text to show when expanded
    minHeight: 120, // component height when closed
    maxHeight: 250, // expanded to
    textStyle: { // pass the css for the collapseText and expandText here
        color: "grey",
        fontSize: "20px"
    }
};

class LeadershipAndManagementTestimonialsOne extends React.Component {

    render() {
        return (
            <Card className='width100 border-0 no-background'>
                <CardText className='CormorantGaramondWithoutSizeAndSpacing text-padding'
                          style={{fontSize: '1.8vw', color: "black"}}>
                        <p className='CormorantGaramondWithoutSizeAndSpacing'>
                            <br/>
                            "Joanna took over the Director’s reigns at Swarthmore in April 2018 at a time of great
                            change for the organisation.

                            She <h4 className='CormorantGaramondWithoutSizeAndSpacing' style={{fontWeight: 'bold', fontSize: '1.8vw'}}>gently supported all staff to work together</h4> for the betterment of Swarthmore resulting
                            in a cultural shift to team spirit.
                            <h4 className='CormorantGaramondWithoutSizeAndSpacing' style={{fontWeight: 'bold', fontSize: '1.8vw'}}>Many positive changes</h4> were made, including a systematic overhaul of HR, improved facilities,
                            a new discipline to review and cost all courses, and a refreshing approach to Governance.
                            Her skills included upward management and her influencing of the Board as well as an
                            authentic way of <h4 className='CormorantGaramondWithoutSizeAndSpacing' style={{fontWeight: 'bold', fontSize: '1.8vw'}}>cutting to the root of the problem.</h4>
                            We continue to grow through COVID-19 and develop some of the <h4 className='CormorantGaramondWithoutSizeAndSpacing' style={{fontWeight: 'bold', fontSize: '1.8vw'}}>positive building blocks </h4>
                            established during Joanna’s tenure."
                            <br/>
                        </p>
                    <p className='CormorantGaramondWithoutSizeAndSpacing'>
                        <h4 className='CormorantGaramondWithoutSizeAndSpacing' style={{fontWeight: 'bold', fontSize: '1.8vw'}}> Julie
                            Badon, Chair
                            Swarthmore Adult Education Centre
                            June 2020 </h4>
                    </p>
                    <br/>

                </CardText>
            </Card>
        )
    }
}

export default LeadershipAndManagementTestimonialsOne;