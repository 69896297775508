import React from "react";
import {Card, CardText} from "reactstrap";
import {Parallax} from "react-parallax";
import {Col, Grid} from "gymnast";
import {CardImg} from "react-bootstrap";
import woman2 from "../../resources/images/steps.jpg";
import scales from "../../resources/images/blue-scales-fade.png";
import Fade from "react-reveal";

const boxStyleGrey = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...boxStyleGrey, minHeight: '20vw', align: "top"},
    TALL: {...boxStyleGrey, minHeight: '50vw'},
    XTALL: {...boxStyleGrey, minHeight: '100vw', align: "top"},
    SMALL: {...boxStyleGrey, minHeight: 100},
    XSMALL: {...boxStyleGrey, minHeight: 75},
};

class TypesOfCoachingHomePage extends React.Component {
    render() {
        return (
            <Parallax bgImage={scales} strength={500}>
                <Card className='border-0 no-background '>
                    <Grid marginTop="S" paddingTop="0">
                        <Col size={2} style={style.XSMALL}/>
                        <Col size={9} style={style.XSMALL} className='border-0'>
                            <Card className=' border-0 no-background center'>
                                <br/>
                                <CardText>
                                    <Fade left >
                                        <div>
                                            <h3 className='playfair_Display '
                                                style={{
                                                    fontSize: '22px',
                                                    color: "black",
                                                    fontWeight: 'bold',
                                                    textAlign: "center"
                                                }}>Coaching For Individuals</h3>
                                        </div>
                                    </Fade>
                                </CardText>
                            </Card>
                        </Col>
                        <Col size={1} style={style.XSMALL}/>

                        <Col size={12} style={style.XSMALL}>
                            <Card className=' border-0 no-background'>
                                <Fade right >
                                    <div>
                                        <CardText>
                                            <p className='CormorantGaramondWithoutSizeAndSpacing'
                                               style={{fontSize: '14px', color: "black"}}>Whether you want to stay in
                                                your role
                                                and
                                                learn how to excel, or whether you are ready to explore alternative
                                                paths, I use
                                                my
                                                25 years of management experience to guide you every step of the way.
                                                I understand the challenges you face because I’ve been there.
                                            </p>
                                        </CardText>
                                    </div>
                                </Fade>
                            </Card>
                        </Col>

                        <Col size={12} style={style.SMALL}>
                            <Card className=' border-0 no-background'>
                                <CardText style={{textAlign: 'left'}}>
                                    <p className='CormorantGaramondWithoutSizeAndSpacing'
                                       style={{fontSize: '14px', color: "black", fontWeight: 'bold', textIndent: '1%'}}>
                                        <Fade left >
                                            <div>
                                                <h4 style={{
                                                    fontWeight: 'bold',
                                                    fontSize: '14px',
                                                    fontFamily: 'CormorantGaramondWithoutSizeAndSpacing'
                                                }}>
                                                    I coach leaders like you to:
                                                </h4>
                                            </div>
                                        </Fade>
                                    </p>
                                    <ul className='CormorantGaramondWithoutSizeAndSpacing'
                                        style={{
                                            fontSize: '14px',
                                            color: "black",
                                            listStyleType: 'initial',
                                            listStylePosition: 'inherit'
                                        }}>
                                        <Fade right big >
                                            <div>
                                                <li>Feel <h4 style={{
                                                    fontWeight: 'bold',
                                                    fontSize: '14px',
                                                    fontFamily: 'CormorantGaramondWithoutSizeAndSpacing'
                                                }}> motivated, energised </h4> and ready to lead again
                                                </li>
                                                <li>Find your <h4 style={{
                                                    fontWeight: 'bold',
                                                    fontSize: '14px',
                                                    fontFamily: 'CormorantGaramondWithoutSizeAndSpacing'
                                                }}> perfect work life balance </h4></li>
                                                <li>Manage your time , so you can <h4 style={{
                                                    fontWeight: 'bold',
                                                    fontSize: '14px',
                                                    fontFamily: 'CormorantGaramondWithoutSizeAndSpacing'
                                                }}> reclaim your weekends </h4> and switch off during
                                                    holidays
                                                </li>
                                                <li>Organise your workload to become more productive, <h4 style={{
                                                    fontWeight: 'bold',
                                                    fontSize: '14px',
                                                    fontFamily: 'CormorantGaramondWithoutSizeAndSpacing'
                                                }}> so you feel in control </h4> rather
                                                    than perpetually sinking
                                                </li>
                                                <li>Build the resilience and mental strength you need to manage your
                                                    team and
                                                    finish
                                                    your day <h4 style={{
                                                        fontWeight: 'bold',
                                                        fontSize: '14px',
                                                        fontFamily: 'CormorantGaramondWithoutSizeAndSpacing'
                                                    }}> feeling fulfilled </h4>
                                                </li>
                                                <li>Say goodbye to the <h4 style={{
                                                    fontWeight: 'bold',
                                                    fontSize: '14px',
                                                    fontFamily: 'CormorantGaramondWithoutSizeAndSpacing'
                                                }}> threat of burnout </h4></li>
                                                <li>And finally <h4 style={{
                                                    fontWeight: 'bold',
                                                    fontSize: '14px',
                                                    fontFamily: 'CormorantGaramondWithoutSizeAndSpacing'
                                                }}> fall in love with your career </h4> again – whether that’s through
                                                    staying
                                                    in your role or moving on
                                                </li>
                                            </div>
                                        </Fade>
                                    </ul>
                                    <br/>
                                </CardText>
                            </Card>
                        </Col>

                        <Col style={style.XSMALL}/>
                        <Col size={1} style={style.XSMALL}/>
                        <Col size={10} style={style.XSMALL}>
                            <Card className='width100 border-0 no-background '>
                                <br/>
                                <Fade left >
                                    <div>
                                        <h3 className='playfair_Display '
                                            style={{fontSize: '22px', color: "black", fontWeight: 'bold'}}>
                                            Leadership Coaching And Training For Organisations
                                        </h3>
                                    </div>
                                </Fade>
                            </Card>
                        </Col>
                        <Col size={1} style={style.XSMALL}/>

                        <Col size={12} style={style.XSMALL}>
                            <Card className='width100 border-0 no-background'>
                                <CardText style={{textAlign: 'left'}}>
                                    <Fade right >
                                        <div>
                                            <p className='CormorantGaramondWithoutSizeAndSpacing'
                                               style={{
                                                   fontSize: '14px',
                                                   color: "black",
                                                   fontWeight: 'bold',
                                                   textIndent: '1%'
                                               }}>
                                                <h4 style={{
                                                    fontWeight: 'bold',
                                                    fontSize: '14px',
                                                    fontFamily: 'CormorantGaramondWithoutSizeAndSpacing'
                                                }}>
                                                    I also provide leadership coaching and training for
                                                </h4>
                                            </p>
                                            <p className='CormorantGaramondWithoutSizeAndSpacing'
                                               style={{
                                                   fontSize: '14px',
                                                   color: "black",
                                                   fontWeight: 'bold',
                                                   textIndent: '1%'
                                               }}>
                                                <h4 style={{
                                                    fontWeight: 'bold',
                                                    fontSize: '14px',
                                                    fontFamily: 'CormorantGaramondWithoutSizeAndSpacing'
                                                }}>
                                                    organisations, to help
                                                    your
                                                    leaders:
                                                </h4>
                                            </p>
                                        </div>
                                    </Fade>
                                    <ul className='CormorantGaramondWithoutSizeAndSpacing'
                                        style={{
                                            fontSize: '14px',
                                            color: "black",
                                            listStyleType: 'initial',
                                            listStylePosition: 'inherit'
                                        }}>
                                        <Fade left >
                                            <div>
                                                <li>Stand out and lead with confidence</li>
                                                <li>Become excellent communicators – to keep your workplace thriving
                                                </li>
                                                <li>Lead with empathy and emotional intelligence</li>
                                                <li>Motivate their teams to work more efficiently</li>
                                                <li>Deliver outstanding results</li>
                                            </div>
                                        </Fade>
                                    </ul>
                                </CardText>
                            </Card>
                        </Col>

                            <Grid marginTop="S" paddingTop="0">
                                <Col size={7} style={style.SMALL} >
                                    <Card className=' border-0' style={{backgroundColor: 'rgba(0,0,0,0)'}}>
                                        <CardImg src={woman2} style={{width: '90%', height: '80%', objectFit: "cover"}}
                                                 className=""/>
                                    </Card>
                                </Col>
                                <Col size={5} style={style.SMALL}>
                                    <Card className='no-background border-0 '>
                                        <br/>
                                        <CardText>
                                            <p className='CormorantGaramondWithoutSizeAndSpacing'
                                               style={{fontSize: '14px', color: "black"}}>I offer a range of unique
                                                coaching
                                                packages and programs, or the option of a bespoke coaching package to
                                                suit
                                                your
                                                exact needs.
                                            </p>
                                        </CardText>
                                    </Card>
                                </Col>
                                <Col style={style.XSMALL}/>


                                <Col size={12} style={style.XSMALL}>
                                    <Card className='no-background border-0 '>
                                        <CardText>
                                            <p className='CormorantGaramondWithoutSizeAndSpacing'
                                               style={{
                                                   fontSize: '14px',
                                                   color: "black",
                                                   fontWeight: 'bold',
                                                   textIndent: '1%'
                                               }}>
                                                <h2 className='CormorantGaramondWithoutSizeAndSpacing '
                                                    style={{fontSize: '14px', color: "black", fontWeight: 'bold'}}>
                                                    Head to my Services page now to explore the ways we can work
                                                    together.
                                                </h2>
                                            </p>
                                            <a className='CormorantGaramondWithoutSizeAndSpacing' style={{
                                                color: '#000000',
                                                backgroundColor: '#F9EBEA',
                                                borderRadius: '10px',
                                                border: '0',
                                                fontSize: '16px',
                                                fontWeight: 'bold'
                                            }} href="/services">I'm Ready To Find Out More
                                            </a>
                                        </CardText>
                                    </Card>
                                </Col>

                            </Grid>
                        <br/>
                    </Grid>
                </Card>
            </Parallax>
        )
    }
}

export default TypesOfCoachingHomePage;