import React from "react";
import {Card, CardText} from "reactstrap";
import {Col, Grid} from "gymnast";
import Fade from "react-reveal";

const boxStyleGrey = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...boxStyleGrey, minHeight: '20vw', align: "top"},
    TALL: {...boxStyleGrey, minHeight: '50vw'},
    XTALL: {...boxStyleGrey, minHeight: '100vw', align: "top"},
    SMALL: {...boxStyleGrey, minHeight: 100},
    MEDIUM_WHITE: {...boxStyleGrey, minHeight: 300},
    XSMALL: {...boxStyleGrey, minHeight: 30},
};

class HowIHelpOrganisations extends React.Component {
    render() {
        return (
            <Grid marginTop="S" paddingTop="0">
                <Col size={12} style={{minHeight: '20vw', align: "top", backgroundColor: '#EAF8F9'}}>
                    <Card className=' width100 border-0'
                          style={{backgroundColor: '#EAF8F9', marginLeft: '10px', marginRight: '10px', width: '100%'}}>
                        <br/>
                        <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                  style={{color: "black", textAlign: 'left'}}>
                            <Fade left >
                                <div>
                                    <h2 className='playfair_Display' style={{fontSize: '30px'}}> How I Help
                                        Organisations </h2>
                                </div>
                            </Fade>
                            <Fade right >
                                <div>
                                    <p className='playfair_Display' style={{fontSize: '16px'}}>Are you:</p>
                                </div>
                            </Fade>
                            <ul>
                                <Fade right >
                                    <div>
                                        <li style={{fontSize: '14px'}}>Fed up with <h4
                                            style={{fontWeight: 'bold', fontSize: '14px'}}>high staff turnover</h4></li>
                                        <li style={{fontSize: '14px'}}>Concerned about <h4
                                            style={{fontWeight: 'bold', fontSize: '14px'}}>mediocre performance</h4>
                                        </li>
                                        <li style={{fontSize: '14px'}}>Battling with <h4
                                            style={{fontWeight: 'bold', fontSize: '14px'}}>too many complaints</h4> to
                                            HR
                                        </li>
                                        <li style={{fontSize: '14px'}}>Desperate for your leaders to <h4
                                            style={{fontWeight: 'bold', fontSize: '14px'}}>step up and take action</h4>
                                        </li>
                                    </div>
                                </Fade>
                            </ul>
                        </CardText>
                    </Card>
                </Col>
                <Col size={12} style={{minHeight: '20vw', align: "top", backgroundColor: '#EAF8F9'}}>
                    <Card className=' width100 border-0 center'
                          style={{backgroundColor: '#EAF8F9', marginLeft: '10px', marginRight: '10px', width: '100%'}}>
                        <CardText style={{textAlign: 'center'}}>
                            <Fade left >
                                <div>
                                    <p className='CormorantGaramondWithoutSizeAndSpacing'
                                       style={{fontSize: '16px', color: "black"}}>Then click here to see how I can help
                                        you.
                                    </p>
                                    <br/>
                                    <a className='CormorantGaramondWithoutSizeAndSpacing' style={{
                                        color: '#000000',
                                        backgroundColor: '#F9EBEA',
                                        borderRadius: '10px',
                                        border: '0',
                                        fontSize: '16px'
                                    }} href="#my_services_for_organisations">My Services For Organisations
                                    </a>
                                    <br/>
                                </div>
                            </Fade>
                        </CardText>
                    </Card>
                </Col>
            </Grid>
        )
    }
}

export default HowIHelpOrganisations;