import React from "react";
import {Card, CardText} from "reactstrap";
import '../../css/Alignment.css';
import '../../css/Components.css';
import '../../css/Animations.css';
import '../../css/Elements.css'
import '../../css/Fonts.css'
import '../../css/Parallax.css'
import '../../css/Size.css'
import {Col, Grid, GymnastProvider} from "gymnast";
import MyNavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import {Responsive} from "../../components/Responsive";


const translucent = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    TALL: {...translucent, minHeight: 200, align: "top"},
    MEDIUM: {...translucent, minHeight: 400},
    SMALL: {...translucent, minHeight: 50}
};

class Testimonial1 extends React.Component {
    render() {
        function FullWidthGrid() {

            return (
                <div className="background">
                    <GymnastProvider>
                        <MyNavBar/>
                        <Grid marginTop="S" paddingTop="0">
                            <Responsive displayIn={["Laptop", "Tablet"]}>
                            <Col style={style.SMALL}>
                                <Card className='width100 border-0 '
                                      style={{fontSize: '80px', backgroundColor: '#f5f3f2'}}>
                                    <CardText className=' CormorantGaramondWithoutSize'>
                                        Testimonial
                                    </CardText>
                                </Card>
                            </Col>
                            </Responsive>

                            <Responsive displayIn={["Mobile"]}>
                                <Col style={style.SMALL}>
                                    <Card className='width100 border-0 '
                                          style={{fontSize: '50px', backgroundColor: '#f5f3f2'}}>
                                        <CardText className=' CormorantGaramondWithoutSize'>
                                            Testimonial
                                        </CardText>
                                    </Card>
                                </Col>
                                <Col style={style.SMALL}/>
                                <Col style={style.SMALL}/>
                                <Col style={style.SMALL}/>
                            </Responsive>

                            <Col style={style.SMALL}/>
                            <Col style={style.SMALL}>
                                <Card className=' no-background h-75 border-0 center'>
                                    <CardText className='CormorantGaramondWithoutSize no-background' style={{
                                        fontSize: '20px',
                                        color: "black",
                                        textAlign: 'left',
                                        padding: '20px',
                                    }}>
                                        <br/>
                                        <p>I wasn’t sure what to expect from coaching, but the results have been
                                            incredible. I started
                                            out feeling stuck and unhappy at work and with no clear goals for the
                                            future. Now I am
                                            happier and more connected in my current job and I’m actively working
                                            towards goals that I
                                            had always thought were unrealistic. </p>
                                        <br/>
                                        <p>The coaching has been helpful right from the first session. The probing
                                            questions Jo asked,
                                            and the variations she came up with when I got stuck, exposed my problems
                                            for what they
                                            really were. A set of self limiting beliefs. There are two that really stick
                                            out for me as
                                            especially helpful. The first was that I felt like approaching my manager
                                            for more work, for
                                            better work, or for feedback was inappropriate. The second was that I
                                            believed that it was
                                            unrealistic to want to have work that aligned with my values. Questioning
                                            these beliefs in
                                            the right way has destroyed them and enabled me to take positive
                                            action. </p>
                                        <br/>
                                        <p> As a result of our sessions I feel like I have a set of tools and attitudes
                                            that I can take
                                            with me in order to tackle any current or future challenges. I have been
                                            extremely impressed
                                            with Jo’s approach and would strongly recommend her to anyone who was
                                            considering coaching.</p>
                                        <br/>
                                        <p className='ibarra' style={{fontWeight: 'bold'}}> M.E, Manchester April 2020 </p>
                                    </CardText>
                                </Card>
                            </Col>
                        </Grid>
                        <Footer/>
                    </GymnastProvider>
                </div>

            );
        }

        return (
            <FullWidthGrid/>
        );
    }
}

export default Testimonial1;