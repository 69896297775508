import React from "react";
import {Card, CardText} from "reactstrap";
import '../../css/Alignment.css';
import '../../css/Components.css';
import '../../css/Animations.css';
import '../../css/Elements.css'
import '../../css/Fonts.css'
import '../../css/Parallax.css'
import '../../css/Size.css'
import {Col, Grid, GymnastProvider} from "gymnast";
import MyNavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import {Responsive} from "../../components/Responsive";


const translucent = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    TALL: {...translucent, minHeight: 200, align: "top"},
    MEDIUM: {...translucent, minHeight: 400},
    SMALL: {...translucent, minHeight: 50}
};

class Testimonial2 extends React.Component {
    render() {
        function FullWidthGrid() {

            return (
                <div className="background">
                    <GymnastProvider>
                        <MyNavBar/>
                        <Grid marginTop="S" paddingTop="0">
                            <Responsive displayIn={["Laptop", "Tablet"]}>
                                <Col style={style.SMALL}>
                                    <Card className='width100 border-0 '
                                          style={{fontSize: '80px', backgroundColor: '#f5f3f2'}}>
                                        <CardText className=' CormorantGaramondWithoutSize'>
                                            Testimonial
                                        </CardText>
                                    </Card>
                                </Col>
                            </Responsive>

                            <Responsive displayIn={["Mobile"]}>
                                <Col style={style.SMALL}>
                                    <Card className='width100 border-0 '
                                          style={{fontSize: '50px', backgroundColor: '#f5f3f2'}}>
                                        <CardText className=' CormorantGaramondWithoutSize'>
                                            Testimonial
                                        </CardText>
                                    </Card>
                                </Col>
                                <Col style={style.SMALL}/>
                                <Col style={style.SMALL}/>
                            </Responsive>
                            <Col style={style.SMALL}/>
                            <Col style={style.SMALL}>
                                <Card className=' no-background h-75 border-0 center'>
                                    <CardText className='CormorantGaramondWithoutSize no-background' style={{
                                        fontSize: '20px',
                                        color: "black",
                                        textAlign: 'left',
                                        padding: '20px',
                                    }}>
                                        <p> After being appointed to a senior leadership role I realised I needed coaching in order to
                                            develop my leadership skills and to become more strategically focussed. I have engaged with
                                            coaching in the past but was not as successful in achieving my goals as I have been with Jo.
                                            Jo helps you to identify your issues along with pushing you to find your own solutions.
                                            Although I found the sessions challenging, they really broadened my thinking and resulted in
                                            increasing my effectiveness as a senior leader in education. </p>
                                        <br/>
                                        <p> Jo has empowered me to develop my leadership skills and given me the right tools and
                                            techniques to futher improve going forward. I am increasingly gaining confidence in my new
                                            role and know, with further sessions, I can be both successful and happy. In summary, my
                                            coaching sessions with Jo have been brilliant and I would recommend Jo to anyone wanting to
                                            realise their potential! </p>
                                        <br/>
                                        <p className='ibarra' style={{fontWeight: 'bold'}}> PF, Huddersfield May 2020 </p>
                                    </CardText>
                                </Card>
                            </Col>
                        </Grid>
                        <Footer/>
                    </GymnastProvider>
                </div>

            );
        }

        return (
            <FullWidthGrid/>
        );
    }
}

export default Testimonial2;