import React from 'react';
import '../../css/Alignment.css';
import '../../css/Components.css';
import '../../css/Animations.css';
import '../../css/Elements.css'
import '../../css/Fonts.css'
import '../../css/Parallax.css'
import '../../css/Size.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Grid, GymnastProvider} from "gymnast";
import MyNavBar from "../../components/NavBar";
import {Card, CardText} from "reactstrap";
import FirstTestimonial from "../../components/mobile/Testimonial1";
import ThirdTestimonial from "../../components/mobile/Testimonial3";
import Footer from "../../components/Footer";
import Fade from "react-reveal";
import OnceHubPopUpWidget from "../../components/OnceHubPopUpWidget";
import SecondTestimonial from "../../components/mobile/Testimonial2";
import Testimonial4 from "../../components/mobile/Testimonial4";

const transparent = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const colour = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: '#F8F9F9'
};

const boxStyleWhite = {
    color: "#FFFFFF",
    backgroundColor: "#FFFFFF"
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...transparent, minHeight: 500, align: "top"},
    TALL: {...transparent, minHeight: 1600},
    XTALL: {...transparent, minHeight: 5000, align: "top"},
    SMALL: {...transparent, minHeight: 100},
    SMALL_WHITE: {...boxStyleWhite, minHeight: 100},
    SMALL_GREY: {...colour, minHeight: 100},
    MEDIUM_WHITE: {...boxStyleWhite, minHeight: 300},
    XSMALL: {...transparent, minHeight: 75}
};

class TestimonialsPageMobile extends React.Component {
    render() {
        function FullWidthGrid() {

            return (
                <div className="background">
                    <GymnastProvider>
                        <MyNavBar/>
                        <Grid marginTop="S" paddingTop="0">
                            <Col style={style.SMALL}>
                                <Card className='width100 border-0 '
                                      style={{fontSize: '50px'}}>
                                    <CardText className=' CormorantGaramondWithoutSize'>
                                        Testimonials
                                    </CardText>
                                </Card>
                                <OnceHubPopUpWidget/>
                            </Col>
                            <Col style={style.SMALL}>
                                <Testimonial4/>
                            </Col>
                            <Col style={style.SMALL}>
                                <Card className='width100 border-0 h-100 '
                                      style={{
                                          fontSize: '12px',
                                          color: "black",
                                          backgroundColor: '#f5f3f2',
                                          textAlign: 'left'
                                      }}>
                                    <CardText className='ibarra' style={{textAlign: 'left', padding: '5px'}}>
                                        <Fade right >
                                            <div>
                                                <br/>
                                                <p>"I decided to find a Career Coach to help me move with my career when feeling burnout. I
                                                    chose Joanna because her experience and skills
                                                    are relevant to my situation. I was right, since our very
                                                    first consultation I felt understood and supported. Joanna took me on a self-discovery
                                                    journey
                                                    which revealed a new set of strengths and values that I didn't consider before.</p>
                                                <br/>
                                                <p>Throughout this journey, I had doubts and moments of revelation which I could share with
                                                    Joanna in a trusted space she created for us.</p>
                                                <br/>
                                                <p>During this journey, Joanna helped me to achieve clarity on my career and personal goals
                                                    and
                                                    empowered me to take actions. I definitely feel more optimistic and relaxed about my
                                                    next steps.
                                                    I fully recommend Joanna's services and support because she cares."</p>
                                                <br/>
                                            </div>
                                        </Fade>
                                        <Fade right >
                                            <div>
                                                <p className='ibarra text-left' style={{fontWeight: 'bold'}}>Barbara Muller, March 2023</p>
                                            </div>
                                        </Fade>
                                    </CardText>
                                </Card>
                            </Col>
                            <Col style={style.SMALL}>
                                <ThirdTestimonial/>
                            </Col>
                            <Col style={style.SMALL}>
                                <Card className='width100 border-0 h-100 '
                                      style={{
                                          fontSize: '12px',
                                          color: "black",
                                          backgroundColor: '#f5f3f2',
                                          textAlign: 'left'
                                      }}>
                                    <CardText style={{paddingLeft: '10px'}}>
                                        <Fade right >
                                            <div>
                                                <p className='ibarra text-left'>
                                                    <br/>
                                                    "Thank you for a great coaching session. It was
                                                    thought provoking and
                                                    motivational. Your approach is down to earth and you make coaching
                                                    accessible for anyone,the newbie to the seasoned coachee. You made
                                                    me feel
                                                    welcome and helped me to f eel confident about my future plans.
                                                    Definitely
                                                    recommend booking a coaching session in if your looking for guidance
                                                    or to
                                                    make change."</p>
                                                <br/>
                                            </div>
                                        </Fade>
                                        <Fade right >
                                            <div>
                                                <p className='ibarra text-left' style={{fontWeight: 'bold'}}> Jasmine
                                                    Craggs,
                                                    Saltburn, May 2020</p>
                                            </div>
                                        </Fade>
                                    </CardText>
                                </Card>
                            </Col>
                            <Col style={style.SMALL}>
                                <Card className='width100 border-0 h-100 '
                                      style={{
                                          fontSize: '12px',
                                          color: "black",
                                          backgroundColor: '#F9EBEA',
                                          textAlign: 'left'
                                      }}>
                                    <CardText style={{paddingLeft: '10px'}}>
                                        <Fade left >
                                            <div>
                                                <p className='ibarra text-left'>
                                                    <br/>
                                                    "I thoroughly enjoyed my coaching session with
                                                    you. Your friendly and encouraging manner made for an incredibly
                                                    relaxed
                                                    session and I quickly found myself looking at aspects of my life
                                                    from a
                                                    different perspective. The inspiration I have taken from your
                                                    session has
                                                    allowed me to evaluate certain aspects of my personal life and
                                                    professional
                                                    career and understand where I need to make small adjustments which I
                                                    am sure
                                                    will be of benefit. Thank you for taking the time to speak me and I
                                                    look
                                                    forward to our next session!"
                                                </p>
                                                <br/>
                                            </div>
                                        </Fade>
                                        <Fade right >
                                            <div>
                                                <p className='ibarra text-left' style={{fontWeight: 'bold'}}> Stuart
                                                    Owen, Leeds
                                                    May 2020</p>
                                            </div>
                                        </Fade>
                                    </CardText>
                                </Card>
                            </Col>
                            <Col style={style.SMALL}>
                                <SecondTestimonial/>
                            </Col>
                            <Col style={style.SMALL}>
                                <Card className='width100 border-0 h-100 '
                                      style={{
                                          fontSize: '12px',
                                          color: "black",
                                          backgroundColor: '#F9EBEA',
                                          textAlign: 'left'
                                      }}>
                                    <CardText style={{paddingLeft: '10px'}}>
                                        <Fade right >
                                            <div>
                                                <p className='ibarra text-left'>
                                                    <br/>
                                                    "I have attended two group sessions with Joanna
                                                    and have found them both to be thought provoking, giving you time to
                                                    think
                                                    about what you want to focus on, what you want to achieve and how
                                                    you will
                                                    do it
                                                    but without being too long. Would highly recommend these sessions to
                                                    both
                                                    get you thinking and decide if further coaching may be of benefit to
                                                    you."
                                                </p>
                                                <br/>
                                            </div>
                                        </Fade>
                                        <Fade left >
                                            <div>
                                                <p className='ibarra text-left' style={{fontWeight: 'bold'}}> EH,
                                                    Marske, May
                                                    2020</p>
                                            </div>
                                        </Fade>
                                    </CardText>
                                </Card>
                            </Col>
                            <Col style={style.SMALL}>
                                <Card className='width100 border-0 h-100 '
                                      style={{
                                          fontSize: '12px',
                                          color: "black",
                                          backgroundColor: '#f5f3f2',
                                          textAlign: 'left'
                                      }}>
                                    <CardText style={{paddingLeft: '10px'}}>
                                        <Fade left >
                                            <div>
                                                <p className='ibarra text-left'>
                                                    <br/>
                                                    "After attending a group session with Joanna,
                                                    it was great to be given easy techniques to make me focus on my
                                                    goals and
                                                    achieve a positive outcome. Joanna is a great listener and affable.
                                                    I highly
                                                    recommend a session or two with her."
                                                </p>
                                                <br/>
                                            </div>
                                        </Fade>
                                        <Fade right >
                                            <div>
                                                <p className='ibarra text-left' style={{fontWeight: 'bold'}}> Fiona
                                                    Jennings,
                                                    Stockton May 2020</p>
                                            </div>
                                        </Fade>
                                    </CardText>
                                </Card>
                            </Col>
                            <Col style={style.SMALL}>
                                <FirstTestimonial/>
                            </Col>
                        </Grid>
                        <Footer/>
                    </GymnastProvider></div>
            );
        }

        return (
            <FullWidthGrid/>
        );
    }
}

export default TestimonialsPageMobile;
