import React from "react";
import {Card, CardText} from "reactstrap";
import Fade from "react-reveal";
import {Col, Grid} from "gymnast";
import CustomButton from "./OneceHubBookingButton";

const boxStyleGrey = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...boxStyleGrey, minHeight: '20vw', align: "top"},
    TALL: {...boxStyleGrey, minHeight: '50vw'},
    XTALL: {...boxStyleGrey, minHeight: '100vw', align: "top"},
    SMALL: {...boxStyleGrey, minHeight: 100},
    MEDIUM_WHITE: {...boxStyleGrey, minHeight: 300},
    XSMALL: {...boxStyleGrey, minHeight: 30},
};

class ServicesForIndividuals extends React.Component {
    render() {
        return (
            <Card className='width100 border-0 '
                  style={{color: "black", backgroundColor: '#f5f3f2'}}>
                <Fade bottom big >
                    <div>
                        <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                            fontSize: '28px',
                            color: "black", textAlign: "left",
                            width: "90%",
                        }}>
                            <br/>
                            I specialise in helping Further Education professionals who feel stuck in their career.
                            If you've lost <h4 className='CormorantGaramondWithoutSizeAndSpacing' style={{fontWeight: 'bold', fontSize: '32px'}}>lost
                            that passion for what you do</h4> and are desperate to get it back, I’m here to help.
                            You may want to stay in your role and learn to make changes so you can reignite that spark.
                            Or you may want to explore new career options.
                            Either way, that’s fine.
                        </CardText>
                        <br/>
                        <br/>
                        <br/>
                        <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left" style={{
                            fontSize: '1.6vw',
                            color: "black", textAlign: "center",
                            width: "90%",
                        }}>
                            <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                style={{fontWeight: 'bold', fontSize: '32px', fontStyle: 'italic'}}> But please – don’t
                                stay feeling stuck.</h4>
                        </CardText>
                        <br/>
                        <br/>
                        <br/>
                        <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                            fontSize: '28px',
                            color: "black", textAlign: "left",
                            width: "90%",
                        }}>
                            <br/>
                            Because it can become all-consuming.
                            I know how it is to feel <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                         style={{fontWeight: 'bold', fontSize: '32px'}}> demotivated,
                            overwhelmed and stressed out </h4>
                            with your career. I’ve been there – and I came out fighting.
                            I want you to feel happy, inspired and energised by your work. I want to show you how to
                            have a better work life balance.
                            Because we all deserve that.
                        </CardText>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <Grid marginTop="S" paddingTop="0">
                            <Col size={6} style={style.MEDIUM}>
                                <Card className=' width100 border-0 ' style={{
                                    backgroundColor: '#FFFFFF',
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                    width: '100%'
                                }}>
                                    <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                                        fontSize: '28px',
                                        color: "black", textAlign: "left",
                                        width: "90%",
                                    }}>
                                        <h2 className='playfair_Display' style={{fontSize: '60px'}}> I went from: </h2>
                                        <ul>
                                            <li style={{fontSize: '28px'}}>Having sleepless nights</li>
                                            <li style={{fontSize: '28px'}}>Working all hours and missing precious family
                                                time
                                            </li>
                                            <li style={{fontSize: '28px'}}>Suffering with constant major back pain</li>
                                            <li style={{fontSize: '28px'}}>Living on a fast-paced hamster wheel of
                                                stress
                                            </li>
                                            <li style={{fontSize: '28px'}}>Feeling exhausted every single day – both in
                                                body and mind
                                            </li>
                                        </ul>
                                    </CardText>
                                    <br/>
                                </Card>
                            </Col>

                            <Col size={6} style={style.MEDIUM}>
                                <Card className=' width100 border-0 '
                                      style={{
                                          backgroundColor: '#FFFFFF',
                                          marginLeft: '10px',
                                          marginRight: '10px',
                                          width: '100%'
                                      }}>
                                    <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                                        fontSize: '28px',
                                        color: "black", textAlign: "left",
                                        width: "90%",
                                    }}>
                                        <h2 className='playfair_Display' style={{fontSize: '60px'}}> To: </h2>
                                        <ul>
                                            <li style={{fontSize: '28px'}}>Sleeping well and waking up excited for the
                                                day ahead
                                            </li>
                                            <li style={{fontSize: '28px'}}>Choosing my hours and sticking to them</li>
                                            <li style={{fontSize: '28px'}}>Having time and energy to start my day with
                                                exercise – so I’m now pain-free
                                            </li>
                                            <li style={{fontSize: '28px'}}>Living a relaxed and fulfilled life</li>
                                            <li style={{fontSize: '28px'}}>And my friends and family tell me I’ve never
                                                looked happier
                                            </li>
                                        </ul>
                                    </CardText>
                                </Card>
                            </Col>
                        </Grid>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <Fade left >
                            <div>
                                <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                                    fontSize: '28px',
                                    color: "black", textAlign: "left",
                                    width: "90%",
                                }}>
                                    I did this by digging deep into what was going wrong with my career and making the
                                    right changes.
                                    And this is what I help my clients to achieve too.
                                    I’ve had business and career coaching, I’ve studied strategies and I’ve been through
                                    rigorous training.
                                    All of this forms part of my unique way of guiding and supporting you.
                                    <p className='CormorantGaramondWithoutSizeAndSpacing' style={{fontWeight: 'bold', fontSize: '32px'}}> If this sounds
                                        like the new life you’ve been dreaming of, please get in touch and let’s do this
                                        together. </p>
                                </CardText>
                                <br/>
                                <CardText className="playfair_Display " style={{
                                    fontSize: '28px',
                                    color: "black"
                                }}>
                                    <CustomButton color='#85B2EE' text="Free 60 minute chat"/>
                                </CardText>
                            </div>
                        </Fade>
                        <br/>
                        <br/>
                        <br/>
                    </div>
                </Fade>
            </Card>
        )


    }
}

export default ServicesForIndividuals;