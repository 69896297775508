import React from "react";
import {Card, CardText} from "reactstrap";
import '../../css/Alignment.css';
import '../../css/Components.css';
import '../../css/Animations.css';
import '../../css/Elements.css'
import '../../css/Fonts.css'
import '../../css/Parallax.css'
import '../../css/Size.css'
import {Col, Grid, GymnastProvider} from "gymnast";
import {Parallax} from "react-parallax";
import scales from "../../resources/images/blue-scales-fade.png";
import {CardImg} from "react-bootstrap";
import woman2 from "../../resources/images/jo_and_joe.jpeg";
import Fade from "react-reveal";


const translucent = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    TALL: {...translucent, minHeight: 200, align: "top"},
    MEDIUM: {...translucent, minHeight: 400},
    SMALL: {...translucent, minHeight: 50}
};

class BlogItem extends React.Component {
    render() {
        function FullWidthGrid() {

            return (
                <div className="background">
                    <GymnastProvider>
                        <Parallax bgImage={scales} strength={500}>
                            <Col style={style.SMALL}/>

                            <Col style={{minHeight: '20vw', align: "top", backgroundColor: 'white'}}>
                                <Card className='border-0' style={{}}>
                                    <Grid marginTop="S" paddingTop="0">
                                        <Col style={style.SMALL}/>

                                        <Col size={1} style={style.SMALL}/>
                                        <Col size={10} style={style.SMALL}>
                                            <Card className=' width100 border-0 '
                                                  style={{
                                                      backgroundColor: '#FFFFFF',
                                                      marginLeft: '10px',
                                                      marginRight: '10px',
                                                      width: '100%'
                                                  }}>
                                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                                          style={{color: "black", textAlign: 'left'}}>
                                                    <Fade top big >
                                                        <div>
                                                            <h2 className='playfair_Display' style={{fontSize: '46px'}}>An Unexpected Email</h2>
                                                            <p className='CormorantGaramondWithoutSize' style={{textAlign: 'start', fontSize: '20px'}}>June 28 2022</p>
                                                        </div>
                                                    </Fade>
                                                    <br/>
                                                    <Fade left big >
                                                        <div>
                                                            <p style={{fontWeight: 'bold', fontSize: '24px'}}>In March this year I wrote down my life goals - all 45 of them.</p>
                                                            <p style={{fontWeight: 'bold', fontSize: '24px'}}>One of these was to meet Joe Wicks.</p>
                                                            <p style={{fontWeight: 'bold', fontSize: '24px'}}>Well today I met him and it was amazing!</p>
                                                            <br/>
                                                            <p style={{fontSize: '24px'}}>I was invited to go to his quarterly board meeting and share my story with him and his team. They had seen the article I wrote,
                                                                <a style={{fontWeight: 'bold'}} className='CormorantGaramondWithoutSizeAndSpacing' target='blank' href={"https://www.lifecoach-directory.org.uk/memberarticles/how-to-be-fit-healthy-and-body-confident-at-50"}> How to be body confident, fit and healthy at 50 </a>
                                                                which describes my transformational journey over the last 18 months.  I was one of 3 body coachers invited to the event and I had no idea what to expect.</p>
                                                        </div>
                                                    </Fade>
                                                </CardText>
                                            </Card>
                                        </Col>
                                        <Col size={1} style={style.SMALL}/>
                                        <Col size={1} style={style.SMALL}/>
                                        <Col size={5} style={style.SMALL}>
                                            <Card className=' width100 border-0 '
                                                  style={{
                                                      backgroundColor: '#FFFFFF',
                                                      marginLeft: '10px',
                                                      marginRight: '10px',
                                                      width: '100%'
                                                  }}>
                                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                                          style={{color: "black", textAlign: 'left'}}>
                                                    <Fade left big >
                                                        <div>
                                                            <p style={{fontSize: '24px'}}>I entered the room nervously and was immediately greeted warmly by Gemma, Head of People.  I was put at ease straight away and introduced to Joe.  The first thing he did was hug me! I managed to talk to him normally without crying and thanked him for creating an fabulous app that had changed my life.</p>
                                                        </div>
                                                    </Fade>
                                                </CardText>
                                            </Card>
                                            <br/>
                                            <Card className=' width100 border-0 '
                                                  style={{
                                                      backgroundColor: '#FFFFFF',
                                                      marginLeft: '10px',
                                                      marginRight: '10px',
                                                      width: '100%'
                                                  }}>
                                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                                          style={{color: "black", textAlign: 'left'}}>
                                                    <Fade left big >
                                                        <div>
                                                            <p style={{fontSize: '24px'}}>The 3 of us were invited on stage to talk with Joe and share our stories.  It was incredible to be asked questions by Joe and introduced to the team that make the app work from developers to marketers and support people.  </p>
                                                            <br/>
                                                            <p style={{fontSize: '24px'}}>What struck me was how genuine everyone was.  It was obvious that the mission of this company is to help as many people as they can to become healthier, fitter and happier.  They were genuinely interested in our stories and how using the app had helped us in our individual journeys. They wanted feedback on how we found the app, what we could suggest to make it better and they shared what they were thinking too.  As an end user I really did feel listened to.</p>
                                                        </div>
                                                    </Fade>
                                                </CardText>
                                            </Card>
                                        </Col>
                                        <Col size={5} style={style.MEDIUM} className="border-0">
                                            <Card className=' border-0' style={{backgroundColor: 'rgba(0,0,0,0)'}}>
                                                <br/>
                                                <CardImg src={woman2} style={{borderRadius: '10px'}}
                                                         className=""/>
                                            </Card>
                                        </Col>
                                        <Col size={1} style={style.SMALL}/>
                                        <Col size={1} style={style.SMALL}/>
                                        <Col size={10} style={style.SMALL}>
                                            <Card className=' width100 border-0 '
                                                  style={{
                                                      backgroundColor: '#FFFFFF',
                                                      marginLeft: '10px',
                                                      marginRight: '10px',
                                                      width: '100%'
                                                  }}>
                                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                                          style={{color: "black", textAlign: 'left'}}>
                                                    <Fade left big >
                                                        <div>
                                                            <p style={{fontSize: '24px'}}>Joe was exactly how I expected him to be - genuine, friendly, warm, kind and loving.  He gave us his time and attention, taking photos with us, signing books and shooting a quick motivational video on my phone just for me.</p>
                                                            <br/>
                                                            <p style={{fontSize: '24px'}}>I will never forget todays experience and I'm looking forward to seeing where the app goes next. You never know, one of my suggestions may be taken up!</p>
                                                        </div>
                                                    </Fade>
                                                    <br/>
                                                    <Fade top right >
                                                        <div>
                                                            <h2 className='playfair_Display' style={{fontSize: '40px'}}>It just goes to show you never know what will happen when you take action.</h2>
                                                        </div>
                                                    </Fade>
                                                </CardText>
                                            </Card>
                                        </Col>
                                        <Col size={1} style={style.SMALL}/>
                                    </Grid>
                                </Card>
                            </Col>
                            <Col style={style.SMALL}/>
                        </Parallax>
                    </GymnastProvider>
                </div>


            );
        }

        return (
            <FullWidthGrid/>
        );
    }
}

export default BlogItem;