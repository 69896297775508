import React from "react";
import {MDBCol, MDBContainer, MDBFooter, MDBRow} from "mdbreact";
import {Card, CardImg} from "reactstrap";
import icfLogo from "../resources/images/icf-badge.png";
import disc from "../resources/images/disc-accreditation.png";
import tca from "../resources/images/tca_accreditedby.jpg";
import brainz from "../resources/images/brainz.png";
import firework from "../resources/images/firework-licensed-career-coach-logo-transparent.png";
import {Responsive} from "./Responsive";


class Footer extends React.Component {
    render() {
        return (
            <MDBFooter className="my-footer">
                <MDBContainer fluid className="text-center text-md-left">
                    <MDBRow>
                        <MDBCol/>
                        <MDBCol>
                            <Card className=' border-0 no-background '>
                                <br/>
                                <a href="https://www.fireworkcoaching.com/" target="_blank">
                                    <CardImg src={firework}/>
                                </a>
                                <br/>
                            </Card>
                            <Card className=' border-0 no-background '>
                                <br/>
                                <a href="https://www.brainzmagazine.com/" target="_blank">
                                <CardImg src={brainz}/>
                                </a>
                                <br/>
                            </Card>
                        </MDBCol>
                        <MDBCol middle='true'>
                            <Card className=' border-0 no-background '>
                                <br/>
                                <a href="https://www.youracclaim.com/badges/a2e0ec22-9292-433e-a43c-cc1af29c6b5d/public_url" target="_blank">
                                <CardImg src={icfLogo}/>
                                </a>
                            </Card>
                            <Card className=' border-0 no-background '>
                                <br/>
                                <a href="http://www.the-coaching-academy.com/" target="_blank">
                                <CardImg src={tca}/>
                                </a>
                                <br/>
                            </Card>
                        </MDBCol>
                        <MDBCol middle='true'>
                            <Card className=' border-0 no-background '>
                                <br/>
                                <CardImg src={disc}/>
                            </Card>
                        </MDBCol>
                        <MDBCol/>
                        <MDBCol md="6" middle='true'>
                            <br/>
                            <ul>
                                <li className="list-unstyled">
                                    <h4 style={{fontSize: '24px', color:'black',  fontWeight: 'bold'}} className='CormorantGaramondWithoutSize'>Contact Me</h4>
                                </li>
                                <li className="list-unstyled">
                                    <a className='CormorantGaramondWithoutSize ' style={{fontSize: '20px'}} target="_blank" href="https://www.facebook.com/mycoachjoanna">Facebook</a>
                                </li>
                                <li className="list-unstyled">
                                    <a className='CormorantGaramondWithoutSize ' style={{fontSize: '20px'}} target="_blank" href="https://www.linkedin.com/in/stokesjoanna/">LinkedIn</a>
                                </li>
                                <li className="list-unstyled">
                                    <br/>
                                    <h4 style={{fontSize: '18px', color:'black',  fontWeight: 'bold'}} className='CormorantGaramondWithoutSize'>
                                        Email address:</h4>
                                    <br/>
                                    <h4 style={{fontSize: '16px', color:'black'}} className='CormorantGaramondWithoutSize'>
                                        joanna@mycoachjoanna.co.uk
                                    </h4>
                                </li>
                                <br/>
                                <Responsive displayIn={["Laptop", "Tablet"]}>
                                    <li className="list-unstyled">
                                        <a className='CormorantGaramondWithoutSize ' style={{fontSize: '20px', fontWeight:'bold'}} target="_blank" href="../privacy_statement_03_23.pdf">Privacy Policy</a>
                                    </li>
                                </Responsive>

                                <Responsive displayIn={["Mobile"]}>
                                    <li className="list-unstyled">
                                        <a className='CormorantGaramondWithoutSize ' style={{fontSize: '20px', fontWeight:'bold'}} target="_blank" href="../privacy_statement_03_23.pdf">Privacy Policy</a>
                                    </li>
                                </Responsive>
                                <br/>
                            </ul>
                    </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol size={3}/>
                        <MDBCol size={7}>
                            <h4 style={{fontSize: '16px', color:'black'}} className='CormorantGaramondWithoutSize'>
                                Website designed and created by Alice Stokes
                            </h4>
                        </MDBCol>
                        <MDBCol size={3}/>
                    </MDBRow>
                </MDBContainer>
            </MDBFooter>
        );
    }
}
export default Footer;
