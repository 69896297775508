import React from "react";
import {Card, CardText} from "reactstrap";
import {Col, Grid, GymnastProvider} from "gymnast";

const boxStyleGrey = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    TALL: {...boxStyleGrey, minHeight: 200, align: "top"},
    MEDIUM: {...boxStyleGrey, minHeight: 400},
    SMALL: {...boxStyleGrey, minHeight: 50}
};

class TestimonialQuotes extends React.Component {
    render() {
        return (
            <Card className=' width100 border-0 '>
                <GymnastProvider>
                <Grid marginTop="S" paddingTop="M">
                    <Col style={style.SMALL}>
                        <Card className='width100 border-0' style={{backgroundColor: 'rgba(0,0,0,0)'}}>
                            <CardText className='amatic_SC' style={{fontSize: '34px', color: "black"}}>
                                "I wasn’t sure what to expect from coaching, but the results have been incredible."
                            </CardText>
                        </Card>
                    </Col>
                        <Col size={9} style={style.SMALL}>
                        <Card className='width100 border-0 ' style={{backgroundColor: 'rgba(0,0,0,0)'}}>
                            <CardText className='calligraffitti' style={{fontSize: '30px', color: "black"}}>
                                <br/>
                                <br/>
                                <br/>
                                "The probing questions Jo asked, and the variations she came up with when I got stuck, exposed my problems for what they really were."</CardText>
                        </Card>
                    </Col>
                    <Col size={3} style={style.SMALL}/>

                    <Col size={4} style={style.SMALL}/>
                    <Col size={8} style={style.SMALL}>
                        <Card className='width100 border-0 ' style={{backgroundColor: 'rgba(0,0,0,0)'}}>
                            <CardText className='playfair_Display' style={{fontSize: '30px', color: "black"}}>
                                <br/>
                                <br/>
                                <br/>
                                "As a result of our sessions I feel like I have a set of tools and attitudes that I can take with me in order to tackle any current or future challenges."</CardText>
                        </Card>
                    </Col>
                    <Col style={style.SMALL}>
                        <Card className='width100 border-0' style={{backgroundColor: 'rgba(0,0,0,0)'}}>
                            <CardText className='bethEllen' style={{fontSize: '40px', color: "black", fontWeight: 'bold'}}>
                                <br/>
                                <br/>
                                <br/>
                                "my coaching sessions with Jo have been brilliant and I would recommend Jo to anyone wanting to realise their potential!"
                                <br/>
                                <br/>
                                <br/>
                            </CardText>
                        </Card>
                    </Col>
                </Grid>
                </GymnastProvider>
            </Card>
    )
    }
    }

    export default TestimonialQuotes;