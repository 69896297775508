import React from "react";
import {Card, CardText} from "reactstrap";
import Fade from "react-reveal";
import {Col, Grid} from "gymnast";

class ProgramIntroNew extends React.Component {
    render() {
        return (
            <Grid marginTop="S" paddingTop="0">
                <Col style={{backgroundColor: 'white'}}>
                    <Card className='border-0  width100' style={{color: "black", backgroundColor: 'white'}}>
                        <CardText className="ibarra margin-left " style={{
                            color: "black", textAlign: "left",
                            width: "90%",
                        }}>
                            <Fade right big>
                                <div>
                                    <p style={{fontSize: '24px'}}>
                                        Are you at a crossroads in your career, wondering whether to stay in the Further Education (FE)
                                        sector or explore new horizons? The <h4 style={{fontWeight: 'bold', fontStyle: 'italic'}}>Career Crossroads</h4> program is designed to guide you
                                        through this crucial decision-making process. Whether you're seeking career progression within the
                                        sector, contemplating a complete career change, or striving for greater satisfaction in your current
                                        role, my programme is dedicated to helping you unlock your true potential and achieve professional
                                        fulfilment.
                                    </p>
                                </div>
                            </Fade>
                            <br/>
                            <div>
                                <Fade right big>
                                    <h3 className='playfair_Display '
                                        style={{fontSize: '28px', color: "black", fontWeight: 'bold'}}>
                                        Welcome to an 8-week immersive journey that will help you define
                                        your career path.
                                    </h3>
                                </Fade>
                            </div>
                            <br/>
                            <ul style={{
                                    fontSize: '24px',
                                    color: "black",
                                    listStyleType: 'initial',
                                    listStylePosition: 'inherit'
                                }}>
                                <Fade right big >
                                    <div>
                                        <li>Get confidence in your career direction</li>
                                        <li>Feel excited for the future</li>
                                        <li>Fall in love with your career again</li>
                                        <li>Manage your time and reclaim your weekends and evenings and switch off during holidays</li>
                                        <li>Feel upbeat, energised and happier at work and in your life</li>
                                    </div>
                                </Fade>
                            </ul>
                        </CardText>
                    </Card>
                </Col>
                <Col style={{backgroundColor: '#FCBFBB'}}>
                    <Card className='width100 '
                          style={{color: "black", backgroundColor: '#FCBFBB'}}>
                        <Fade left cascade big>
                            <br/>
                            <br/>
                            <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left border-0" style={{
                            }}>
                                    <p className='nixie' style={{fontSize: '34px'}}>
                                        The programme has been designed in three phases,</p>
                                    <h4 className='nixie' style={{fontWeight: 'bold', fontSize: '32px'}}> EMBARK.
                                        UNCOVER. FOCUS.</h4>
                            </CardText>
                            <br/>
                            <br/>
                        </Fade>
                    </Card>
                </Col>
            </Grid>
        )


    }
}

export default ProgramIntroNew;