import React from "react";
import {Card, CardText} from "reactstrap";
import '../css/Alignment.css';
import '../css/Components.css';
import '../css/Animations.css';
import '../css/Elements.css';
import '../css/Fonts.css';
import '../css/Parallax.css';
import '../css/Size.css';
import {Col, Grid, GymnastProvider} from "gymnast";
import MyNavBar from "../components/NavBar";
import Footer from "../components/Footer";
import image from "../resources/images/crossroads-background.png";
import {Parallax} from "react-parallax";
import Fade from "react-reveal";
import OnceHubPopUpWidget from "../components/OnceHubPopUpWidget";
import CustomButton from "../components/OneceHubBookingButton";
import ProgramIntroNewMobile from "../components/mobile/career-crossroads/ProgramIntroNew";
import ProgramInfoPart1New from "../components/mobile/career-crossroads/ProgramInfoPart1New";
import ProgramInfoPart2New from "../components/mobile/career-crossroads/ProgramInfoPart2New";
import ProgramFeesNew from "../components/mobile/career-crossroads/ProgramFeesNew";
import Faqs from "../components/mobile/career-crossroads/Faqs";
import nextSteps from "../resources/images/portrait-next-steps.png";

const translucent = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    TALL: {...translucent, minHeight: 200, align: "top"},
    MEDIUM: {...translucent, minHeight: 400},
    SMALL: {...translucent, minHeight: 50}
};

class CareerCrossroadsMobile extends React.Component {
    render() {
        function FullWidthGrid() {

            return (
                <div className="background">
                    <GymnastProvider>
                        <MyNavBar/>
                        <Grid marginTop="S" paddingTop="0">
                            <Parallax bgImage={image} strength={500}>
                                <div style={{height: 700, width: '100vw'}}>
                                    <Card className='no-background width100 border-0'>
                                        <Col style={style.MEDIUM}>

                                            <Col size={12} style={style.SMALL}>
                                                <Card className='width100 border-0 no-background'>
                                                    <CardText className='CormorantGaramondWithoutSize'>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <h1 style={{
                                                            fontSize: '48px',
                                                            color: "black",
                                                            fontWeight: "bold"
                                                        }}>Career Crossroads</h1>
                                                    </CardText>
                                                </Card>
                                            </Col>
                                        </Col>
                                    </Card>
                                </div>
                            </Parallax>
                            <Col style={style.SMALL}/>
                            <Col>
                            <Card className='width100 border-0 ' style={{color: "black", backgroundColor: 'white'}}>
                                <CardText>
                                    <h3 className='playfair_Display '
                                        style={{fontSize: '16px', color: "black", fontWeight: 'bold'}}>
                                        <Fade left>
                                            <div>
                                                I have re-invented my career 3 times over 35 years. I am on my 3rd
                                                career, and definitely my last - but I am exactly where I want to be.
                                            </div>
                                        </Fade>
                                    </h3>
                                </CardText>
                            </Card>
                            </Col>
                            <OnceHubPopUpWidget/>
                            <Col style={style.SMALL}>
                                <ProgramIntroNewMobile/>
                            </Col>
                            <Card className='width100 '
                                  style={{backgroundColor: '#FCBFBB'}}>
                                <Fade left cascade big>
                                    <br/>
                                    <CardText className="CormorantGaramondWithoutSizeAndSpacing border-0" style={{
                                    }}>
                                        <p className='nixie' style={{fontSize: '18px'}}>
                                            The programme has been designed in three phases,</p>
                                        <h4 className='nixie' style={{fontWeight: 'bold', fontSize: '20px'}}> EMBARK.
                                            UNCOVER. FOCUS.</h4>
                                    </CardText>
                                    <br/>
                                </Fade>
                            </Card>
                            <Col style={style.SMALL}>
                                <ProgramInfoPart1New/>
                            </Col>
                            <Col style={style.SMALL}>
                                <ProgramInfoPart2New/>
                            </Col>
                            <Col style={style.SMALL}>
                                <ProgramFeesNew/>
                            </Col>
                            <Card className='width100 '
                                  style={{backgroundColor: '#77E1D0'}}>
                                <Fade left cascade big>
                                    <br/>
                                    <CardText className="playfair_Display border-0" style={{
                                    }}>
                                        <h4 className='playfair_Display' style={{fontWeight: 'bold', fontSize: '20px'}}>Check out what my clients say</h4>
                                            <a className='playfair_Display' style={{fontWeight: 'bold', fontSize: '20px', color: 'black'}}
                                               target='blank' href="/testimonials"> here</a>
                                    </CardText>
                                    <br/>
                                </Fade>
                            </Card>
                            <Col/>
                            <Col style={style.SMALL}>
                                <div className=' border-0' style={{}}>
                                    <img src={nextSteps} style={{objectFit: "cover", height: "100%", width: "100%"}}/>
                                </div>
                            </Col>
                            <Col style={style.SMALL}>
                                <Faqs/>
                            </Col>

                                <Card className='width100 border-0' style={{backgroundColor: 'white'}}>
                                    <CardText className="ibarra" style={{
                                        color: "black"
                                    }}>
                                        <Fade left big>
                                            <div>
                                                <h4 className='ibarra' style={{fontWeight: 'bold', fontSize: '16px'}}>Contact me for
                                                    any queries or to book your free discovery call.
                                                </h4>
                                            </div>
                                        </Fade>
                                    </CardText>
                                    <br/>

                                    <div className="ibarra" style={{fontWeight: 'bold', fontSize: '14px'}}>
                                        <CustomButton text="I'm ready to chat, book my free call" color='#87CEE5'/>
                                    </div>
                                    <br/>
                                </Card>
                        </Grid>
                        <Footer/>
                    </GymnastProvider>
                </div>

            );
        }

        return (
            <FullWidthGrid/>
        );
    }
}

export default CareerCrossroadsMobile;