import React from "react";
import {Card, CardText} from "reactstrap";
import Fade from "react-reveal";

class ProgramIntroNew extends React.Component {
    render() {
        return (
                    <Card className='border-0  width100' style={{color: "black", backgroundColor: 'white'}}>
                        <CardText  style={{
                            color: "black", textAlign: "left"
                        }}>
                            <Fade right big>
                                <div>
                                    <p className="ibarra" style={{fontSize: '12px'}}>
                                        Are you at a crossroads in your career, wondering whether to stay in the Further Education (FE)
                                        sector or explore new horizons? The <h4 style={{fontWeight: 'bold', fontStyle: 'italic', fontSize: '14px'}}>Career Crossroads</h4> program is designed to guide you
                                        through this crucial decision-making process. Whether you're seeking career progression within the
                                        sector, contemplating a complete career change, or striving for greater satisfaction in your current
                                        role, my programme is dedicated to helping you unlock your true potential and achieve professional
                                        fulfilment.
                                    </p>
                                </div>
                            </Fade>
                            <br/>
                            <Fade right big>
                                <div>
                                    <h3 className='playfair_Display'
                                        style={{fontSize: '16px', color: "black", fontWeight: 'bold'}}>
                                        Welcome to an 8-week immersive journey that will help you define
                                        your career path.
                                    </h3>
                                </div>
                            </Fade>
                            <ul style={{
                                    fontSize: '14px',
                                    color: "black",
                                    listStyleType: 'initial',
                                    listStylePosition: 'inherit'
                                }}>
                                <Fade right big >
                                    <div className="ibarra">
                                        <br/>
                                        <li>Get confidence in your career direction</li>
                                        <li>Feel excited for the future</li>
                                        <li>Fall in love with your career again</li>
                                        <li>Manage your time and reclaim your weekends and evenings and switch off during holidays</li>
                                        <li>Feel upbeat, energised and happier at work and in your life</li>
                                    </div>
                                </Fade>
                            </ul>
                        </CardText>
                    </Card>

        )


    }
}

export default ProgramIntroNew;