import React from "react";
import {Card, CardText} from "reactstrap";
import Fade from 'react-reveal';
import {Grid} from "gymnast";

const boxStyleGrey = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...boxStyleGrey, minHeight: '20vw', align: "top"},
    TALL: {...boxStyleGrey, minHeight: '50vw'},
    XTALL: {...boxStyleGrey, minHeight: '100vw', align: "top"},
    SMALL: {...boxStyleGrey, minHeight: 100},
    MEDIUM_WHITE: {...boxStyleGrey, minHeight: 300},
    XSMALL: {...boxStyleGrey, minHeight: 30},
};


class BlockA extends React.Component {
    render() {
        return (
            <Grid marginTop="S" paddingTop="0">
                    <Card className='width100 '
                          style={{color: "black", backgroundColor: 'white'}}>
                        <Fade left cascade big>
                        <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                            fontSize: '26px',
                            color: "black", textAlign: "left",
                            width: "90%",
                        }}>
                                <div>
                                    <p className='nixie' style={{ fontSize: '52px'}}>
                                        You began your role in Further Education to
                                        <h4 className='nixie' style={{fontWeight: 'bold', fontSize: '52px'}}> make a difference. </h4> </p>

                                    <p className='CormorantGaramondWithoutSizeAndSpacing'> You want to<h4 className='CormorantGaramondWithoutSizeAndSpacing' style={{fontWeight: 'bold', fontSize: '26px'}}> lead and inspire. </h4> </p>
                                    <br/>

                                </div>
                                    <div>
                                    <p className='nixie' style={{ fontSize: '52px'}}> You’re passionate about helping others. And you work all the hours to try and be the
                                            <h4 className='nixie' style={{fontWeight: 'bold', fontSize: '52px'}}> best you can be. </h4> </p>

                                    <p className='CormorantGaramondWithoutSizeAndSpacing'> But let’s be honest;<h4 className='CormorantGaramondWithoutSizeAndSpacing' style={{fontWeight: 'bold', fontSize: '26px'}}> leadership can be tough... </h4></p>
                                <br/>
                                </div>
                        </CardText>
                <br/>
                <br/>
                <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left center" style={{
                    fontSize: '26px',
                    color: "black", textAlign: "left",
                    width: "90%",
                }}>
                        <div>
                            <p className='CormorantGaramondWithoutSizeAndSpacing'> You feel like you <h4
                                className='CormorantGaramondWithoutSizeAndSpacing'
                                style={{fontWeight: 'bold', fontSize: '26px'}}>
                                never switch off. </h4> It can be exhausting, overwhelming and your body and mind feel
                                tense.
                                Work life balance sounds like an
                                <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                    style={{fontWeight: 'bold', fontSize: '26px'}}> unrealistic dream. </h4>
                                And that’s before you bring in the specific
                                <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                    style={{fontWeight: 'bold', fontSize: '26px'}}> pressures of Further
                                    Education. </h4> Government
                                changes, cost-cutting,
                                time-consuming meetings…
                            </p>
                        </div>
                </CardText>
                        </Fade>
                        <br/>
                <br/>
                <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left center" style={{
                    fontSize: '26px',
                    color: "black", textAlign: "left",
                    width: "90%",
                }}>
                    <Fade right big >
                        <div>
                            <p className='CormorantGaramondWithoutSizeAndSpacing'> You’re responsible for
                                keeping your staff motivated, and
                                yet you often find yourself feeling
                                <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                    style={{fontWeight: 'bold', fontSize: '26px'}}> overworked and
                                    undervalued. </h4>

                                You work so hard, and yet you're
                                <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                    style={{fontWeight: 'bold', fontSize: '26px'}}> not seeing the
                                    results </h4> you deserve.
                                <br/>
                                Don’t worry, I get it. Many of my 18 years spent in Further Education roles
                                felt the
                                same.
                                <br/>
                                <br/>
                                <p> <h4 className='nixie' style={{ fontSize: '64px', fontWeight: ''}}>
                                    That's why I'm here to help. </h4> </p>
                            </p>
                            <br/>
                            <br/>
                            <br/>
                        </div>
                    </Fade>
                </CardText>
            </Card>
            </Grid>
        )
    }
}

export default BlockA;