import React from "react";
import {Card, CardText} from "reactstrap";
import Fade from "react-reveal";

class AboutMeIntro extends React.Component {
    render() {
        return (
            <Card className='width100 border-0 ' style={{color: "black", backgroundColor: 'white'}}>
                <br/>
                <Card className='width100 border-0 ' style={{color: "black", backgroundColor: '#f5f3f2'}}>
                    <CardText className="ibarra margin-left " style={{
                        fontSize: '28px',
                        color: "black", textAlign: "left",
                        width: "90%",
                    }}>
                        <br/>
                        <Fade left big >
                            <div>
                                <p style={{fontSize: '28px'}}>
                                    I’m Joanna Stokes, leadership and career coach based in glorious Marske-by-the-Sea,
                                    in North Yorkshire.
                                </p>
                            </div>
                        </Fade>
                        <br/>
                        <Fade right big >
                            <div>
                                <p style={{fontSize: '28px'}}>
                                    If you’re ready to <h4
                                    className='ibarra'
                                    style={{fontWeight: 'bold', fontSize: '28px'}}> take a deep breath and embrace the
                                    possibility of a happier working life, </h4>
                                    come and dive into my journey.
                                </p>
                            </div>
                        </Fade>
                        <br/>
                        <Fade left big >
                            <div>
                                <p style={{fontSize: '28px'}}>It might just inspire you…
                                </p>
                            </div>
                        </Fade>
                        <br/>
                    </CardText>
                </Card>
            </Card>
        )


    }
}

export default AboutMeIntro;