import React from 'react';
import '../css/Alignment.css';
import '../css/Components.css';
import '../css/Animations.css';
import '../css/Elements.css'
import '../css/Fonts.css'
import '../css/Parallax.css'
import '../css/Size.css'
import '../css/nav.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Grid, GymnastProvider} from "gymnast";
import MyNavBar from "../components/NavBar";
import {Card, CardText} from "reactstrap";
import Footer from "../components/Footer";
import {Parallax} from "react-parallax";
import blog from "../resources/images/blog-medium.jpg";
import OnceHubPopUpWidget from "../components/OnceHubPopUpWidget";
import ArticleBlurb from "../components/mobile/blog/ArticleBlurb";
import Fade from "react-reveal";
import ArticleBlurb3 from "../components/mobile/blog/ArticleBlurb3";
import ArticleBlurb2 from "../components/mobile/blog/ArticleBlurb2";
import ArticleBlurb4 from "../components/mobile/blog/ArticleBlurb4";
import ArticleBlurb5 from "../components/mobile/blog/ArticleBlurb5";
import BlogItem from "../components/mobile/blog/BlogItem";
import ArticleBlurb7 from "../components/mobile/blog/ArticleBlurb7";
import ArticleBlurb6 from "../components/mobile/blog/ArticleBlurb6";
import ArticleBlurb8 from "../components/mobile/blog/ArticleBlurb8";
import ArticleBlurb9 from "../components/mobile/blog/ArticleBlurb9";
import ArticleBlurb10 from "../components/mobile/blog/ArticleBlurb10";
import ArticleBlurb11 from "../components/mobile/blog/ArticleBlurb11";
import ArticleBlurb12 from "../components/mobile/blog/ArticleBlurb12";
import ArticleBlurb13 from "../components/mobile/blog/ArticleBlurb13";

const boxStyleGrey = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};


const boxStyleWhite = {
    color: "#FFFFFF",
    backgroundColor: "#FFFFFF"
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...boxStyleGrey, minHeight: '20vw', align: "top"},
    TALL: {...boxStyleGrey, minHeight: '50vw'},
    XTALL: {...boxStyleGrey, minHeight: '100vw', align: "top"},
    SMALL: {...boxStyleGrey, minHeight: 100, borderTop: '12px'},
    MEDIUM_WHITE: {...boxStyleWhite, minHeight: 300},
    XSMALL: {...boxStyleGrey, minHeight: 75},
};

const insideStyles = {
    background: "white",
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)"
};

class BlogPageMobile extends React.Component {
    render() {
        function FullWidthGrid() {
            return (
                <div className="background">
                    <GymnastProvider>
                        <MyNavBar/>
                        <Grid marginTop="S" paddingTop="0">
                            <Parallax bgImage={blog}  strength={300}
                                      renderLayer={(percentage) => (
                                          <div>
                                              <div
                                                  style={{
                                                      position: "absolute",
                                                      background: `rgba(255, 125, 0, ${percentage})`,
                                                      left: "50%",
                                                      top: "40%",
                                                      borderRadius: "50%",
                                                      transform: "translate(-50%,-50%)",
                                                      width: percentage * 400,
                                                      height: percentage * 400
                                                  }}
                                              > <h1
                                                  className='CormorantGaramondWithoutSize'
                                                  style={{
                                                      fontSize: percentage * 180,
                                                      color: "white",
                                                      marginTop: '20%',
                                                      paddingLeft: '10%'
                                                  }}
                                              >Blog</h1>

                                              </div>
                                          </div>
                                      )}
                            >
                                <div style={{height: 800, width: '100vw'}}>
                                    <Card className='no-background width100 border-0'>
                                        <Col style={style.MEDIUM}>
                                            <Col size={12} style={style.SMALL}>
                                                <Card style={insideStyles} className='width100 border-0 no-background'>

                                                </Card>
                                            </Col>
                                        </Col>
                                    </Card>
                                </div>
                            </Parallax>

                            <Col style={style.SMALL}>
                                <Card className='width100 border-0 h-100 no-background '
                                      style={{fontSize: '22px', color: "black"}}>
                                    <CardText>
                                        <br/>
                                        <h2 className='CormorantGaramondWithoutSize word-breaks'
                                            style={{fontSize: '32px', color: "black", fontWeight: 'bold'}}>
                                            <Fade left >
                                                <div className='word-breaks'>
                                                    Welcome to my blog.
                                                </div>
                                            </Fade>
                                        </h2>
                                        <h2 className='CormorantGaramondWithoutSize word-breaks'
                                            style={{fontSize: '18px', color: "black"}}>
                                            <Fade left >
                                                <div className='word-breaks'>
                                                    A collection of my most recent articles packed with tips, practical help and thought provoking questions to help you with your career, leadership and lifestyle.
                                                </div>
                                            </Fade>
                                        </h2>
                                    </CardText>
                                </Card>
                            </Col>
                            <Col style={style.SMALL}>
                                <ArticleBlurb13/>
                            </Col>
                            <Col style={style.SMALL}>
                                <ArticleBlurb12/>
                            </Col>
                            <Col style={style.SMALL}>
                                <ArticleBlurb11/>
                            </Col>
                            <Col style={style.SMALL}>
                                <ArticleBlurb10/>
                            </Col>
                            <Col style={style.SMALL}>
                                <ArticleBlurb9/>
                            </Col>
                            <Col style={style.SMALL}>
                                <ArticleBlurb8/>
                            </Col>
                            <Col style={style.SMALL}>
                                <ArticleBlurb7/>
                            </Col>
                            <Col style={style.SMALL}>
                                <ArticleBlurb6/>
                            </Col>
                            <Col style={style.SMALL}>
                                <BlogItem/>
                                <OnceHubPopUpWidget/>
                            </Col>
                            <Col style={style.SMALL}>
                                <ArticleBlurb5/>
                                <OnceHubPopUpWidget/>
                            </Col>
                            <Col style={style.SMALL}>
                                <ArticleBlurb4/>
                                <OnceHubPopUpWidget/>
                            </Col>
                            <Col style={style.SMALL}>
                                <ArticleBlurb3/>
                                <OnceHubPopUpWidget/>
                            </Col>
                            <Col style={style.SMALL}>
                                <ArticleBlurb2/>
                                <OnceHubPopUpWidget/>
                            </Col>
                            <Col style={style.SMALL}>
                                <ArticleBlurb/>
                                <OnceHubPopUpWidget/>
                            </Col>
                        </Grid>
                        <Footer/>
                    </GymnastProvider>
                </div>

            );
        }

        return (
            <FullWidthGrid/>
        );
    }
}

export default BlogPageMobile;
