import React from "react";
import {Card, CardImg, CardText} from "reactstrap";
import {Col, Grid, GymnastProvider} from "gymnast";
import sun from "../../resources/images/men-talking.jpg";
import Fade from "react-reveal";

const transparent = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...transparent, minHeight: 500, align: "top"},
    TALL: {...transparent, minHeight: 1600},
    XTALL: {...transparent, minHeight: 5000, align: "top"},
    SMALL: {...transparent, minHeight: 100},
    SMALL_WHITE: {...transparent, minHeight: 100},
    SMALL_GREY: {...transparent, minHeight: 100},
    MEDIUM_WHITE: {...transparent, minHeight: 300},
    XSMALL: {...transparent, minHeight: 75},
    XXSMALL: {...transparent, minHeight: 30}
};

const url = "https://www.lifecoach-directory.org.uk/memberarticles/coach-or-mentor"

class ArticleBlurb11 extends React.Component {
    render() {
        return (
            <Card onClick={() => window.open(url, '_blank')} className='width100 border-0 h-100 pointer'
                  style={{fontSize: '22px', color: "black", backgroundColor: '#F9EBEA'}}>
                <GymnastProvider>
                    <Grid marginTop="S" paddingTop="M">
                        <Col size={6} style={style.SMALL}>
                            <Card className='width100 border-0 h-100 no-background'
                                  style={{fontSize: '22px', color: "black"}}>
                                <CardText>
                                    <br/>
                                    <p className='CormorantGaramondWithoutSize' style={{textAlign: 'start', fontSize: '20px'}}>April 13 2023</p>
                                    <Fade right >
                                        <div>
                                            <p className='Oswald' style={{fontWeight: 'bold', textAlign: 'start', fontSize: '62px'}}>Coach or Mentor?</p>
                                            <p className='ibarra' style={{fontWeight: 'bold', textAlign: 'start'}}>Are you a coach or a mentor in the workplace? If you're unsure, you're not alone.</p>
                                        </div>
                                    </Fade>
                                    <br/>
                                    <Fade right >
                                        <div>
                                            <p className='ibarra' style={{fontWeight: 'bold', textAlign: 'start'}}>Written by: Joanna Stokes</p>
                                            <p className='ibarra' style={{ textAlign: 'start'}}>Life Coach Directory</p>
                                            <a className='CormorantGaramondWithoutSize' style={{fontSize: '16', float: 'left'}}
                                               target='blank'
                                               href={url}> Read More </a>
                                        </div>
                                    </Fade>
                                    <br/>
                                    <br/>
                                </CardText>
                            </Card>
                        </Col>
                        <Col size={6} style={style.XXSMALL}>
                            <Card className=' border-0  no-background '>
                                <br/>
                                <CardImg src={sun} style={{borderRadius: '10px'}} className='card-img '/>
                            </Card>
                        </Col>
                    </Grid>
                </GymnastProvider>
            </Card>
        )
    }
}

export default ArticleBlurb11;