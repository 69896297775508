import React from "react";
import {Card, CardText} from "reactstrap";
import {Col, Grid} from "gymnast";
import CustomButton from "../OneceHubBookingButton";
import {CardImg} from "react-bootstrap";
import chris from "../../resources/images/chris-corcoran.png";
import {Parallax} from "react-parallax";
import marble from "../../resources/images/marble.png";
import Fade from "react-reveal";

const boxStyleGrey = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...boxStyleGrey, minHeight: '20vw', align: "top"},
    TALL: {...boxStyleGrey, minHeight: '50vw'},
    XTALL: {...boxStyleGrey, minHeight: '100vw', align: "top"},
    SMALL: {...boxStyleGrey, minHeight: 100},
    MEDIUM_WHITE: {...boxStyleGrey, minHeight: 300},
    XSMALL: {...boxStyleGrey, minHeight: 30},
};

class CoachingPackagesForIndividuals extends React.Component {
    render() {
        return (
            <Grid marginTop="S" paddingTop="0">
                <Parallax bgImage={marble} strength={500}>
                    <Col style={style.XSMALL}/>
                    <Col style={{minHeight: '20vw', align: "top", backgroundColor: 'white'}}>
                        <Col style={style.XSMALL}>
                            <Card className=' width100 border-0 center' style={{
                                backgroundColor: '#FFFFFF',
                                width: '100%'
                            }}>
                                <br/>
                                <CardText>
                                    <Fade left>
                                        <div>
                                            <h2 className='playfair_Display'
                                                style={{fontSize: '28px', color: 'black', textAlign: "center"}}>Career
                                                Crossroads </h2>
                                            <h2 className='playfair_Display'
                                                style={{fontSize: '12px', color: 'black', textAlign: "center"}}>Career
                                                Change
                                                Coaching Program </h2>
                                        </div>
                                    </Fade>
                                </CardText>
                            </Card>
                        </Col>
                        <Col style={style.XSMALL}>
                            <Card className=' width100 border-0 center'
                                  style={{
                                      backgroundColor: '#FFFFFF',
                                      marginLeft: '10px',
                                      marginRight: '10px',
                                      width: '100%'
                                  }}>
                                <Fade right>
                                    <div>
                                        <CardText className="CormorantGaramondWithoutSizeAndSpacing "
                                                  style={{
                                                      fontSize: '14px',
                                                      color: "black", textAlign: "left",
                                                  }}>

                                            <p className='playfair_Display'
                                               style={{fontSize: '14px', fontWeight: 'bold'}}>This is perfect if
                                                you're:</p>
                                            <ul>
                                                <li style={{fontSize: '14px'}}>At a crossroads in your career and not
                                                    sure which direction to take
                                                </li>
                                                <li style={{fontSize: '14px'}}>You know you want to change your career
                                                    but have no idea what you want to do
                                                </li>
                                                <li style={{fontSize: '14px'}}>You are unhappy where you are and know
                                                    you want to change something, but you are unsure where to start
                                                </li>
                                            </ul>
                                        </CardText>
                                        <CardText className="CormorantGaramondWithoutSizeAndSpacing "
                                                  style={{
                                                      fontSize: '14px',
                                                      color: "black", textAlign: "left",
                                                  }}>
                                            <a className='CormorantGaramondWithoutSizeAndSpacing' style={{
                                                color: '#000000',
                                                backgroundColor: '#F9EBEA',
                                                borderRadius: '10px',
                                                border: '0',
                                                fontSize: '14px'
                                            }} target='blank' href="/career-crossroads">Read More</a>
                                        </CardText>
                                    </div>
                                </Fade>
                            </Card>
                        </Col>
                    </Col>
                    <Col style={style.XSMALL}/>
                    <Col style={{minHeight: '20vw', align: "top", backgroundColor: 'white'}}>
                        <Col style={style.XSMALL}>
                            <Card className=' width100 border-0 center' style={{
                                backgroundColor: '#FFFFFF',
                                width: '100%'
                            }}>
                                <br/>
                                <CardText>
                                    <Fade left>
                                        <div>
                                            <h2 className='playfair_Display'
                                                style={{fontSize: '28px', color: 'black', textAlign: "center"}}>Express Coaching Power Hour</h2>
                                            <h2 className='playfair_Display'
                                                style={{fontSize: '12px', color: 'black', textAlign: "center"}}>Your Solution for Immediate Professional Challenges</h2>
                                        </div>
                                    </Fade>
                                </CardText>
                            </Card>
                        </Col>
                        <Col style={style.XSMALL}>
                            <Card className=' width100 border-0 center'
                                  style={{
                                      backgroundColor: '#FFFFFF',
                                      marginLeft: '10px',
                                      marginRight: '10px',
                                      width: '100%'
                                  }}>
                                <Fade right>
                                    <div>
                                        <CardText className="CormorantGaramondWithoutSizeAndSpacing "
                                                  style={{
                                                      fontSize: '14px',
                                                      color: "black", textAlign: "left",
                                                  }}>

                                            <p className='playfair_Display'
                                               style={{fontSize: '14px'}}>When professional challenges can't wait, the Express Coaching Power Hour is here to deliver swift, strategic support tailored to your urgent needs.
                                            </p>
                                            <br/>
                                            <p className='playfair_Display'
                                               style={{fontSize: '14px'}}>Priced at £150, this 90-minute one-on-one session is your fast track to overcoming obstacles such as workload overwhelm, navigating difficult conversations, boss frustrations, or CV concerns that aren't getting you interviews.
                                            </p>
                                        </CardText>
                                        <CardText className="CormorantGaramondWithoutSizeAndSpacing "
                                                  style={{
                                                      fontSize: '14px',
                                                      color: "black", textAlign: "left",
                                                  }}>
                                            <a className='CormorantGaramondWithoutSizeAndSpacing' style={{
                                                color: '#000000',
                                                backgroundColor: '#F9EBEA',
                                                borderRadius: '10px',
                                                border: '0',
                                                fontSize: '14px'
                                            }} target='blank' href="/power-hour">Read More</a>
                                        </CardText>
                                    </div>
                                </Fade>
                            </Card>
                        </Col>
                    </Col>
                    <Col style={style.XSMALL}/>
                    <Col style={{minHeight: '20vw', align: "top", backgroundColor: 'white'}}>
                        <Col style={style.XSMALL}>
                            <Card className=' width100 border-0 center' style={{
                                backgroundColor: '#FFFFFF',
                                width: '100%'
                            }}>
                                <br/>
                                <CardText>
                                    <Fade left>
                                        <div>
                                            <h2 className='playfair_Display'
                                                style={{fontSize: '28px', color: 'black', textAlign: "center"}}>Transform Your CV and LinkedIn Presence</h2>
                                        </div>
                                    </Fade>
                                </CardText>
                            </Card>
                        </Col>
                        <Col style={style.XSMALL}>
                            <Card className=' width100 border-0 center'
                                  style={{
                                      backgroundColor: '#FFFFFF',
                                      marginLeft: '10px',
                                      marginRight: '10px',
                                      width: '100%'
                                  }}>
                                <Fade right>
                                    <div>
                                        <CardText className="CormorantGaramondWithoutSizeAndSpacing "
                                                  style={{
                                                      fontSize: '14px',
                                                      color: "black", textAlign: "left",
                                                  }}>

                                            <p className='playfair_Display'
                                               style={{fontSize: '14px'}}>Does your CV and LinkedIn profile need a makeover?  Whether you're eyeing that next career move or simply want to ensure your CV and LinkedIn profile are first rate, you're in the right place.
                                            </p>
                                            <br/>
                                            <p className='playfair_Display'
                                               style={{fontSize: '14px'}}>For just £250, I offer a comprehensive 90-minute 1:1 coaching session designed to transform your professional narrative.
                                            </p>
                                        </CardText>
                                        <CardText className="CormorantGaramondWithoutSizeAndSpacing "
                                                  style={{
                                                      fontSize: '14px',
                                                      color: "black", textAlign: "left",
                                                  }}>
                                            <a className='CormorantGaramondWithoutSizeAndSpacing' style={{
                                                color: '#000000',
                                                backgroundColor: '#F9EBEA',
                                                borderRadius: '10px',
                                                border: '0',
                                                fontSize: '14px'
                                            }} target='blank' href="/cv-linkedIn">Read More</a>
                                        </CardText>
                                    </div>
                                </Fade>
                            </Card>
                        </Col>
                    </Col>
                </Parallax>
                <Parallax bgImage={marble} strength={500}>
                    <Col style={style.XSMALL}/>
                    <Col style={{minHeight: '20vw', align: "top", backgroundColor: 'white'}}>
                        <Col size={12} style={style.XSMALL}>
                            <Card className='width100 border-0 center '
                                  style={{color: "black", backgroundColor: 'white'}}>
                                <br/>
                                <Fade right>
                                    <div>
                                        <CardText className="CormorantGaramondWithoutSizeAndSpacing " style={{
                                            fontSize: '18px',
                                            color: "black", textAlign: "left", paddingLeft: '6px'
                                        }}>I love helping my clients to
                                            <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                style={{fontWeight: 'bold', fontSize: '18px'}}> rediscover their
                                                passion and
                                                self-belief</h4>;
                                            and it
                                            seems they love it too.
                                            Have a look at this testimonial.
                                            It had me smiling for days…
                                        </CardText>
                                    </div>
                                </Fade>
                            </Card>
                            <Col size={12} style={style.XSMALL}/>
                            <Card className='width100 border-0 '>
                                <Col size={12} style={style.XSMALL}>
                                    <Card className='border-0' style={{backgroundColor: 'rgba(0,0,0,0)'}}>
                                        <CardImg src={chris} style={{borderRadius: '10px'}}/>
                                    </Card>
                                </Col>
                            </Card>
                        </Col>
                        <Col size={12} style={style.MEDIUM}>
                            <Card style={{
                                color: "black",
                                backgroundColor: 'white',
                                borderColor: '#EAF8F9',
                                borderWidth: '5px'
                            }}>
                                <CardText className="CormorantGaramondWithoutSizeAndSpacing " style={{
                                    fontSize: '14px',
                                    color: "black", textAlign: "left", paddingLeft: '6px',
                                    fontStyle: 'italic'
                                }}>
                                    <Fade left>
                                        <div>
                                            <br/>
                                            <p>“When I first contacted Joanna <h4
                                                className='CormorantGaramondWithoutSizeAndSpacing'
                                                style={{
                                                    fontWeight: 'bold',
                                                    fontSize: '14px'
                                                }}>I was completely lost</h4>.
                                                I had long been unhappy in my work, but had a very well paid job and
                                                a very
                                                specific
                                                knowledge base that allowed me to be good at it.
                                            </p>
                                            <p><h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                   style={{
                                                       fontWeight: 'bold',
                                                       fontSize: '14px'
                                                   }}> I wanted change but couldn’t see how. </h4></p>
                                            <p> Joanna asked me all the right questions. At the times I didn’t have
                                                an
                                                answer, but
                                                Joanna kept pushing and kept digging until I found my next
                                                steps.</p>
                                            <p> As fantastic as those conversations were, <h4
                                                className='CormorantGaramondWithoutSizeAndSpacing'
                                                style={{
                                                    fontWeight: 'bold',
                                                    fontSize: '14px'
                                                }}>the best thing Joanna did
                                                for me
                                                was to
                                                believe in me</h4>, even when I didn’t believe in myself.
                                            </p>
                                            <p><h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                   style={{
                                                       fontWeight: 'bold',
                                                       fontSize: '14px'
                                                   }}> She has been invaluable</h4> to getting me to where I am now.
                                            </p>
                                            <p> Where is that? I’m starting out on my own, in an industry I have
                                                never
                                                worked in,
                                                with
                                                no knowledge of what it takes to become as successful as I was in my
                                                last
                                                career.</p>
                                            <p><h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                   style={{
                                                       fontWeight: 'bold',
                                                       fontSize: '14px'
                                                   }}> But what I do have now is a passion and a belief.</h4></p>
                                            <p> I used to say I want to succeed.</p>
                                            <p> Now, <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                         style={{
                                                             fontWeight: 'bold',
                                                             fontSize: '14px'
                                                         }}>because of my work with Joanna</h4>, I say I will.”
                                            </p>
                                            <br/>
                                            <p><h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                   style={{
                                                       fontWeight: 'bold',
                                                       fontSize: '14px',
                                                       fontStyle: 'initial'
                                                   }}>Chris Corcoran. Redcar, June 2020</h4>
                                            </p>
                                            <br/>
                                        </div>
                                    </Fade>
                                </CardText>
                            </Card>
                        </Col>
                    </Col>
                </Parallax>

                <Parallax bgImage={marble} strength={500}>
                    <Col style={style.XSMALL}/>
                    <Col style={{minHeight: '20vw', align: "top", backgroundColor: 'white'}}>
                        <Col style={style.XSMALL}/>
                        <Col size={12} style={style.MEDIUM}>
                            <Card className=' width100 border-0 center'
                                  style={{backgroundColor: '#FFFFFF'}}>
                                <CardText className="CormorantGaramondWithoutSizeAndSpacing " style={{
                                    fontSize: '14px',
                                    color: "black", textAlign: "left", paddingLeft: '6px'
                                }}>
                                    <Fade right>
                                        <div>
                                            <h2 className='playfair_Display' style={{fontSize: '28px'}}> Bespoke
                                                Coaching
                                                Packages </h2>
                                        </div>
                                    </Fade>
                                    <br/>
                                    <Fade left>
                                        <div>
                                            I can offer you a completely bespoke coaching package, tailored to your
                                            exact
                                            needs.
                                            This starts with an introductory free call, where we chat for up to an
                                            hour.
                                            I
                                            ask
                                            you lots
                                            of questions to help me get to know you, your situation and your
                                            concerns.
                                            <br/>
                                            <br/>
                                            We then explore the ways I can help you.
                                            The call also lets you get a feel for me and how I work, so you can
                                            decide
                                            if
                                            we’re
                                            a good
                                            fit.
                                            I then provide you with a quote for exactly what you need.
                                            <br/>
                                            <br/>
                                            You have 28 days to think
                                            about it with absolutely no pressure or obligation.
                                            If you’re ready to work together, I send over a secure link for payment
                                            and
                                            then
                                            our
                                            adventure begins!
                                            <br/>
                                            <br/>
                                        </div>
                                    </Fade>
                                </CardText>
                                <Fade left>
                                    <div>
                                        <CardText className="playfair_Display " style={{
                                            fontSize: '14px',
                                            color: "black", textAlign: "left", paddingLeft: '6px'
                                        }}>
                                            <CustomButton color='#85B2EE' text="Book A Free 60 Minute Chat"/>
                                            <br/>
                                        </CardText>
                                    </div>
                                </Fade>
                            </Card>
                        </Col>
                    </Col>
                    <Col style={style.XSMALL}/>
                </Parallax>
            </Grid>
        )
    }
}

export default CoachingPackagesForIndividuals;