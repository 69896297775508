import React from "react";
import {Card, CardText} from "reactstrap";

class MakeAChange extends React.Component {
    render() {
        return (
            <Card className='width100 border-0 'style={{ fontSize: '56px', color: "black", backgroundColor: '#F9EBEA'}}>
                    <CardText className='ibarra' >
                        <br/>
                        <p className='ibarra '>  Stop holding yourself back. </p>
                        <p className='ibarra '> If you're not happy make a change. </p>
                        <br/>
                    </CardText>
            </Card>
        )
    }
}

export default MakeAChange;