import React from 'react';
import '../css/Alignment.css';
import '../css/Components.css';
import '../css/Animations.css';
import '../css/Elements.css'
import '../css/Fonts.css'
import '../css/Parallax.css'
import '../css/Size.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Grid} from "gymnast";
import {Responsive} from '../components/Responsive';
import HomePageLaptop from "./HomePageLaptop";
import HomePageMobile2 from "./HomePageMobile2";
import {Helmet} from 'react-helmet';

class HomePageSwitch extends React.Component {

    render() {
        function FullWidthGrid() {
            return (
                <Grid marginTop="S" paddingTop="0">
                    <Responsive displayIn={["Laptop"]}>
                        <HomePageLaptop/>
                    </Responsive>
                    <Responsive displayIn={["Mobile"]}>
                        <HomePageMobile2/>
                    </Responsive>
                </Grid>
            );
        }

        return (
            <div>
                <Helmet>
                    <meta name="description" content="Do you wish you felt fulfilled in your career? Explore how Joanna Stokes, Leadership and Career Coach for leaders in the North East and beyond, can transform you. My Coach Joanna"/>
                    <link rel="canonical" href="http://mycoachjoanna/" />
                </Helmet>
                <FullWidthGrid/>
            </div>
        );
    }
}

export default HomePageSwitch;
