import React from "react";
import {Card, CardText} from "reactstrap";
import Fade from "react-reveal";
import {Col, Grid} from "gymnast";
import CustomButton from "../OneceHubBookingButton";

const boxStyleGrey = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...boxStyleGrey, minHeight: '20vw', align: "top"},
    TALL: {...boxStyleGrey, minHeight: '50vw'},
    XTALL: {...boxStyleGrey, minHeight: '100vw', align: "top"},
    SMALL: {...boxStyleGrey, minHeight: 100},
    MEDIUM_WHITE: {...boxStyleGrey, minHeight: 300},
    XSMALL: {...boxStyleGrey, minHeight: 30},
};

class ServicesForIndividuals extends React.Component {
    render() {
        return (
            <Card className='width100 border-0 '
                  style={{color: "black", backgroundColor: '#f5f3f2'}}>
                <Fade right >
                    <div>
                        <CardText className="CormorantGaramondWithoutSizeAndSpacing" style={{
                            fontSize: '14px',
                            color: "black", textAlign: "left", paddingLeft: '6px'
                        }}>
                            <br/>
                            I specialise in helping Further Education professionals who feel stuck in their career.
                            If you've lost <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                               style={{fontWeight: 'bold', fontSize: '14px'}}>lost
                            that passion for what you do</h4> and are desperate to get it back, I’m here to help.
                            You may want to stay in your role and learn to make changes so you can reignite that spark.
                            Or you may want to explore new career options.
                            Either way, that’s fine.
                        </CardText>
                    </div>
                </Fade>
                <br/>
                <br/>
                <br/>
                <Fade left >
                    <div>
                        <CardText className="CormorantGaramondWithoutSizeAndSpacing" style={{
                            fontSize: '14px',
                            color: "black", textAlign: "center", paddingLeft: '6px'
                        }}>
                            <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                style={{fontWeight: 'bold', fontSize: '14px', fontStyle: 'italic'}}> But please – don’t
                                stay feeling stuck.</h4>
                        </CardText>
                    </div>
                </Fade>
                <br/>
                <br/>
                <br/>
                <Fade bottom >
                    <div>
                        <CardText className="CormorantGaramondWithoutSizeAndSpacing " style={{
                            fontSize: '14px',
                            color: "black", textAlign: "left", paddingLeft: '6px'
                        }}>
                            <br/>
                            Because it can become all-consuming.
                            I know how it is to feel <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                         style={{fontWeight: 'bold', fontSize: '14px'}}> demotivated,
                            overwhelmed and stressed out </h4>
                            with your career. I’ve been there – and I came out fighting.
                            I want you to feel happy, inspired and energised by your work. I want to show you how to
                            have a better work life balance.
                            Because we all deserve that.
                        </CardText>
                    </div>
                </Fade>
                <br/>
                <br/>
                <Grid marginTop="S" paddingTop="0">
                    <Col size={12} style={style.MEDIUM}>
                        <Card className=' width100 border-0 ' style={{
                            backgroundColor: '#FFFFFF',
                            marginLeft: '10px',
                            marginRight: '10px',
                            width: '100%'
                        }}>
                            <CardText className="CormorantGaramondWithoutSizeAndSpacing " style={{
                                fontSize: '14px',
                                color: "black", textAlign: "left", paddingLeft: '6px'
                            }}>
                                <Fade left >
                                    <div>
                                        <h2 className='playfair_Display' style={{fontSize: '26px'}}> I went from: </h2>
                                    </div>
                                </Fade>
                                <ul>
                                    <Fade left >
                                        <div>
                                            <li style={{fontSize: '14px'}}>Having sleepless nights</li>
                                            <li style={{fontSize: '14px'}}>Working all hours and missing precious family
                                                time
                                            </li>
                                            <li style={{fontSize: '14px'}}>Suffering with constant major back pain</li>
                                            <li style={{fontSize: '14px'}}>Living on a fast-paced hamster wheel of
                                                stress
                                            </li>
                                            <li style={{fontSize: '14px'}}>Feeling exhausted every single day – both in
                                                body and mind
                                            </li>
                                        </div>
                                    </Fade>
                                </ul>
                            </CardText>
                            <br/>
                        </Card>
                    </Col>

                    <Col size={12} style={style.MEDIUM}>
                        <Card className=' width100 border-0 '
                              style={{
                                  backgroundColor: '#FFFFFF',
                                  marginLeft: '10px',
                                  marginRight: '10px',
                                  width: '100%'
                              }}>
                            <CardText className="CormorantGaramondWithoutSizeAndSpacing " style={{
                                fontSize: '14px',
                                color: "black", textAlign: "left", paddingLeft: '6px'
                            }}>
                                <Fade right >
                                    <div>
                                        <h2 className='playfair_Display' style={{fontSize: '26px'}}> To: </h2>
                                    </div>
                                </Fade>
                                <ul>
                                    <Fade right >
                                        <div>
                                            <li style={{fontSize: '14px'}}>Sleeping well and waking up excited for the
                                                day ahead
                                            </li>
                                            <li style={{fontSize: '14px'}}>Choosing my hours and sticking to them</li>
                                            <li style={{fontSize: '14px'}}>Having time and energy to start my day with
                                                exercise – so I’m now pain-free
                                            </li>
                                            <li style={{fontSize: '14px'}}>Living a relaxed and fulfilled life</li>
                                            <li style={{fontSize: '14px'}}>And my friends and family tell me I’ve never
                                                looked happier
                                            </li>
                                        </div>
                                    </Fade>
                                </ul>
                            </CardText>
                        </Card>
                    </Col>
                </Grid>
                <br/>
                <CardText className="CormorantGaramondWithoutSizeAndSpacing " style={{
                    fontSize: '14px',
                    color: "black", textAlign: "left", paddingLeft: '6px'
                }}>
                    <Fade left >
                        <div>
                            I did this by digging deep into what was going wrong with my career and making the right
                            changes.
                            And this is what I help my clients to achieve too.
                            I’ve had business and career coaching, I’ve studied strategies and I’ve been through
                            rigorous training.
                            All of this forms part of my unique way of guiding and supporting you.
                            <br/>
                            <br/>
                        </div>
                    </Fade>
                    <Fade right >
                        <div>
                            <p className='CormorantGaramondWithoutSizeAndSpacing'
                               style={{fontWeight: 'bold', fontSize: '14px'}}> If this sounds like
                                the new life you’ve been dreaming of, please get in touch and let’s do this
                                together </p>
                        </div>
                    </Fade>
                </CardText>
                <br/>
                <Fade right >
                    <div>
                        <CardText className="playfair_Display " style={{
                            fontSize: '14px',
                            color: "black"
                        }}>
                            <CustomButton color='#85B2EE' text="Free 60 minute chat"/>
                        </CardText>
                    </div>
                </Fade>
                <br/>
                <br/>
                <br/>
            </Card>
        )
    }
}

export default ServicesForIndividuals;