import React from "react";
import {Card, CardText} from "reactstrap";
import Fade from "react-reveal";

class AboutMe extends React.Component {
    render() {
        return (
            <Card className='width100 border-0 '
                  style={{color: "black", backgroundColor: '#f5f3f2'}}>
                <CardText className="CormorantGaramondWithoutSizeAndSpacing " style={{
                    fontSize: '14px',
                    color: "black", textAlign: "left", paddingLeft: '6px'
                }}>
                    <br/>
                    I’m Joanna Stokes and I provide Further Education leaders with coaching, strategies and
                    support to make them <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                             style={{fontWeight: 'bold', fontSize: '14px'}}> happier and more
                    effective in the
                    workplace. </h4>
                    Because being content in your career means living a <h4
                    className='CormorantGaramondWithoutSizeAndSpacing'
                    style={{fontWeight: 'bold', fontSize: '14px'}}> more fulfilled life. </h4>
                    Building a career that is right for you, meets your values and makes you feel <h4
                    className='CormorantGaramondWithoutSizeAndSpacing'
                    style={{fontWeight: 'bold', fontSize: '14px'}}> alive,
                    purposeful and positive </h4> is not only possible, but it reaps rewards for you and those
                    closest
                    to you.
                </CardText>
                <br/>
                <br/>
                <br/>
                <CardText className='playfair_Display' style={{color: "black"}}>
                    <Fade right big>
                        <h3 style={{fontSize: '16px', fontWeight: 'bold'}}>Business And Career Coaching Will
                            Transform You</h3>
                    </Fade>
                </CardText>
                <Fade left >
                    <div>
                        <CardText className="CormorantGaramondWithoutSizeAndSpacing" style={{
                            fontSize: '14px',
                            color: "black", textAlign: "left", paddingLeft: '6px'
                        }}> I use my
                            <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                style={{fontWeight: 'bold', fontSize: '14px'}}> 25 years of
                                management experience </h4>
                            (from hotels, to education to CEO roles), together with my welcoming, down-to-earth
                            approach…
                        </CardText>
                    </div>
                </Fade>
                <br/>
                <br/>
                <CardText className="CormorantGaramondWithoutSizeAndSpacing" style={{
                    fontSize: '14px',
                    color: "black", textAlign: "center",
                    paddingLeft: '6px'
                }}>
                    <Fade right >
                        <div>
                            <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                style={{fontWeight: 'bold', fontSize: '14px', fontStyle: 'italic'}}> To help you
                                be the
                                best you can be. </h4>
                        </div>
                    </Fade>
                </CardText>
                <br/>
                <br/>
                <Fade left >
                    <div>
                        <CardText className="CormorantGaramondWithoutSizeAndSpacing " style={{
                            fontSize: '14px',
                            color: "black", textAlign: "left",
                            paddingLeft: '6px'
                        }}>
                            <br/>
                            <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                style={{fontWeight: 'bold', fontSize: '14px'}}> You may be feeling sceptical
                                about the
                                idea of coaching </h4>
                            – I understand that.

                            Maybe you’ve been through leadership and management training before, and perhaps
                            even
                            something loosely called “coaching” for your CPD points.
                            Let me guess – it didn’t change much?
                            But trust me…
                            When you find the right coach, and undergo real career or leadership coaching,
                            <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                style={{fontWeight: 'bold', fontSize: '14px'}}> it will transform you beyond
                                measure. </h4>
                        </CardText>
                    </div>
                </Fade>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <CardText className='playfair_Display' style={{color: "black"}}>
                    <Fade right big>
                        <h3 style={{fontSize: '16px', fontWeight: 'bold'}}>How Career Coaching Changed
                            Everything For Me</h3>
                    </Fade>
                </CardText>
                <Fade left >
                    <div>
                        <CardText className="CormorantGaramondWithoutSizeAndSpacing" style={{
                            fontSize: '14px',
                            color: "black", textAlign: "left",
                            paddingLeft: '6px'
                        }}> Quite honestly, I would not be where I am today if it wasn’t for some
                            <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                style={{fontWeight: 'bold', fontSize: '14px'}}> unbelievable career
                                and business coaching. </h4>
                            Coaching catapulted me from being a first-line manager to a senior manager within 18
                            months.

                            And it provided me with the
                            <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                style={{fontWeight: 'bold', fontSize: '14px'}}> confidence and
                                skills </h4>
                            to be both a single mum to two children and a senior manager – without having to
                            compromise.

                            I work with an
                            <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                style={{fontWeight: 'bold', fontSize: '14px'}}> excellent business
                                coach </h4>
                            to help me create and grow my current business too.
                        </CardText>
                    </div>
                </Fade>
                <br/>
                <br/>
                <Fade right >
                    <div>
                        <CardText className="CormorantGaramondWithoutSizeAndSpacing" style={{
                            fontSize: '14px',
                            color: "black", textAlign: "center",
                            paddingLeft: '6px'
                        }}>
                            <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                style={{fontWeight: 'bold', fontSize: '14px', fontStyle: 'italic'}}> Because
                                when you
                                truly invest in yourself,
                                you make incredible progress.
                            </h4>
                        </CardText>
                    </div>
                </Fade>
                <br/>
                <br/>
                <Fade left >
                    <div>
                        <CardText className="CormorantGaramondWithoutSizeAndSpacing " style={{
                            fontSize: '14px',
                            color: "black", textAlign: "left",
                            paddingLeft: '6px'
                        }}>
                            <br/>
                            Now I work for myself in my lovely home in Marske-by-the-Sea.
                            I choose my own hours. I’m pain-free, stress free, and I’ve never slept better in my
                            life.
                            And I actually take time to breathe in that sea air, take walks, and spend precious
                            time
                            with my family and friends.
                        </CardText>
                    </div>
                </Fade>
                <br/>
                <br/>
                <Fade bottom >
                    <div>
                        <CardText className="CormorantGaramondWithoutSizeAndSpacing " style={{
                            fontSize: '14px',
                            color: "black", textAlign: "left",
                            paddingLeft: '6px'
                        }}>
                            <br/>
                            <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                style={{fontSize: '16px'}}> If you want to read more about my
                                journey and how I can help you achieve your perfect work life balance too,
                                discover more
                                on my
                                <a className='CormorantGaramondWithoutSizeAndSpacing'
                                   style={{
                                       fontWeight: 'bold',
                                       fontSize: '14px',
                                       color: 'black',
                                       fontStyle: 'italic'
                                   }}
                                   target='blank'
                                   href="/about-me"> About page</a>. </h4>
                        </CardText>
                    </div>
                </Fade>
                <br/>
            </Card>
        )


    }
}

export default AboutMe;