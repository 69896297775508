import React from 'react';
import '../css/Alignment.css';
import '../css/Components.css';
import '../css/Animations.css';
import '../css/Elements.css'
import '../css/Fonts.css'
import '../css/Parallax.css'
import '../css/Size.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Grid, GymnastProvider} from "gymnast";
import MyNavBar from "../components/NavBar";
import {Card, CardText} from "reactstrap";
import Footer from "../components/Footer";
import Fade from "react-reveal";
import {Parallax} from "react-parallax";
import saltburn from "../resources/images/three-boats.jpg";
import AboutMeStory from "../components/AboutMeStory";
import AboutMeIntro from "../components/AboutMeIntro";
import HowNotToLead from "../components/HowNotToLead";
import AboutMeStoryPart2 from "../components/AboutMeStoryPart2";
import TwoLessons from "../components/TwoLessons";
import TwoLessonsPart2 from "../components/TwoLessonsPart2";
import CustomButton from "../components/OneceHubBookingButton";
import OnceHubPopUpWidget from "../components/OnceHubPopUpWidget";

const boxStyleGrey = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};


const boxStyleWhite = {
    color: "#FFFFFF",
    backgroundColor: "#FFFFFF"
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...boxStyleGrey, minHeight: '20vw', align: "top"},
    TALL: {...boxStyleGrey, minHeight: '50vw'},
    XTALL: {...boxStyleGrey, minHeight: '100vw', align: "top"},
    SMALL: {...boxStyleGrey, minHeight: 100, borderTop: '12px'},
    MEDIUM_WHITE: {...boxStyleWhite, minHeight: 300},
    XSMALL: {...boxStyleGrey, minHeight: 75},
};

class AboutMePage extends React.Component {
    render() {
        function FullWidthGrid() {
            return (
                <div className="background">
                    <GymnastProvider>
                        <MyNavBar/>
                        <Grid marginTop="S" paddingTop="0">
                            <Parallax bgImage={saltburn}  strength={500}>
                                <div style={{height: 800, width: '100vw'}}>
                                    <Card className='no-background width100 border-0'>
                                        <Col style={style.MEDIUM}>

                                            <Col size={12} style={style.SMALL}>
                                                <Card className='width100 border-0 no-background'>
                                                    <CardText className='CormorantGaramondWithoutSize'>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <h1 style={{fontSize: '48px', color: "white"}}>Do You Want To Know How To Be Happier At Work?
                                                        </h1>
                                                        <br/>
                                                        <h2 className='ibarra' style={{fontSize: '22px', color: "white", fontWeight: 'bold'}} >Let Me Share My Story</h2>
                                                        <CardText className="playfair_Display " style={{
                                                            fontSize: '22px',
                                                            color: "black"
                                                        }}>
                                                        <CustomButton text="I'm ready to chat, book my free call" color='#85B2EE'/>
                                                        </CardText>
                                                    </CardText>
                                                </Card>
                                            </Col>
                                        </Col>
                                    </Card>
                                </div>
                            </Parallax>
                            <OnceHubPopUpWidget/>

                            <Col style={style.SMALL}>
                                <AboutMeIntro/>
                            </Col>
                            <Col style={style.SMALL}>
                                <AboutMeStory/>
                            </Col>
                            <Col style={style.SMALL}>
                                <HowNotToLead/>
                            </Col>
                            <Col style={style.SMALL}>
                                <AboutMeStoryPart2/>
                            </Col>
                            <Col style={style.SMALL}>
                                <Card className='width100 border-0'>
                                    <CardText style={{ color: "black"}}>
                                        <Fade left big>
                                            <h2 className='playfair_Display' style={{fontSize: '50px'}}>The Two Lessons I Would Love To Share With You</h2>
                                        </Fade>
                                        <Fade right big>
                                            <h2 className='playfair_Display' style={{fontSize: '28px'}}>I want you to learn how to be happier at work too, so let me share these thoughts with you:</h2>
                                        </Fade>
                                    </CardText>
                                </Card>
                            </Col>
                            <TwoLessons/>
                            <TwoLessonsPart2/>
                        </Grid>
                        <Footer/>
                    </GymnastProvider>
                </div>

            );
        }

        return (
            <FullWidthGrid/>
        );
    }
}

export default AboutMePage;
