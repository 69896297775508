import React from "react";
import {Card, CardText} from "reactstrap";
import {Col, Grid} from "gymnast";
import Fade from "react-reveal";

const boxStyleGrey = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...boxStyleGrey, minHeight: '20vw', align: "top"},
    TALL: {...boxStyleGrey, minHeight: '50vw'},
    XTALL: {...boxStyleGrey, minHeight: '100vw', align: "top"},
    SMALL: {...boxStyleGrey, minHeight: 100},
    MEDIUM_WHITE: {...boxStyleGrey, minHeight: 300},
    XSMALL: {...boxStyleGrey, minHeight: 30},
};


class HowNotToLead extends React.Component {
    render() {
        return (
            <Grid marginTop="S" paddingTop="0">
                <Col size={6} style={style.MEDIUM}>
                    <Card className=' width100 border-0 center'
                          style={{backgroundColor: '#FFFFFF', marginLeft: '10px', marginRight: '10px', width: '100%'}}>
                        <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                  style={{color: "black", textAlign: 'left'}}>
                            <Fade left big >
                                <div>
                                    <h2 className='playfair_Display' style={{fontSize: '65px'}}> How NOT To Lead</h2>
                                </div>
                            </Fade>
                            <Fade left big >
                                <div>
                                    <p className='playfair_Display' style={{fontSize: '30px'}}>Then came the worst of
                                        times.
                                    </p>
                                    <p className='' style={{fontSize: '28px'}}>I went from skipping into work at an
                                        early hour because I had so many ideas…
                                    </p>
                                    <p className='' style={{fontSize: '28px'}}>To dragging myself in at 8.28am
                                        <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                            style={{fontWeight: 'bold', fontSize: '28px'}}> with a knot in my
                                            stomach, </h4>dreading what the day would bring.
                                    </p>
                                    <p className='' style={{fontSize: '28px'}}>
                                        <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                            style={{fontWeight: 'bold', fontSize: '28px'}}> But do you know what?</h4>
                                    </p>
                                    <p className='' style={{fontSize: '28px'}}>All of this taught me everything I needed
                                        to know about how NOT to be a good leader.
                                    </p>
                                </div>
                            </Fade>
                        </CardText>
                        <br/>
                    </Card>
                </Col>

                <Col size={6} style={style.MEDIUM}>
                    <Card className=' width100 border-0'
                          style={{backgroundColor: '#FFFFFF', marginLeft: '10px', marginRight: '10px', width: '100%'}}>
                        <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                  style={{color: "black", textAlign: 'left'}}>
                            <br/>
                            <br/>
                            <Fade right big >
                                <div>
                                    <p className='playfair_Display' style={{fontSize: '30px'}}>Times when, quite
                                        honestly, I witnessed some terrible leadership which brought misery to people;
                                        myself included. I saw:
                                    </p>
                                </div>
                            </Fade>
                            <Fade right big >
                                <div>
                                    <ul>
                                        <li style={{fontSize: '28px'}}>Micro-managing</li>
                                        <li style={{fontSize: '28px'}}>Poisonous cultures based on blame and fear</li>
                                        <li style={{fontSize: '28px'}}>Outdated thinking and closed mindsets</li>
                                        <li style={{fontSize: '28px'}}>Divisiveness and secrecy</li>
                                        <li style={{fontSize: '28px'}}>Employees too afraid to speak up in meetings</li>
                                        <li style={{fontSize: '28px'}}>Staff who did dare to disagree suddenly losing
                                            their jobs
                                        </li>
                                        <li style={{fontSize: '28px'}}>Leaders who behaved how they wanted to and
                                            thought themselves untouchable
                                        </li>
                                    </ul>
                                </div>
                            </Fade>
                        </CardText>
                    </Card>
                </Col>
            </Grid>
        )
    }
}

export default HowNotToLead;