import React from "react";
import {Card, CardText} from "reactstrap";
import '../css/Alignment.css';
import '../css/Components.css';
import '../css/Animations.css';
import '../css/Elements.css';
import '../css/Fonts.css';
import '../css/Parallax.css';
import '../css/Size.css';
import {Col, Grid, GymnastProvider} from "gymnast";
import MyNavBar from "../components/NavBar";
import Footer from "../components/Footer";
import image from "../resources/images/pages.jpg";
import {Parallax} from "react-parallax";
import Fade from "react-reveal";
import OnceHubPopUpWidget from "../components/OnceHubPopUpWidget";
import {Button} from "react-bootstrap";

const translucent = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    TALL: {...translucent, minHeight: 200, align: "top"},
    MEDIUM: {...translucent, minHeight: 400},
    SMALL: {...translucent, minHeight: 50}
};

class CvAndLinkedInPackageMobile extends React.Component {
    render() {
        function FullWidthGrid() {

            return (
                <div className="background">
                    <GymnastProvider>
                        <MyNavBar/>
                        <Grid marginTop="S" paddingTop="0">
                            <Parallax bgImage={image} strength={500}>
                                <div style={{height: 700, width: '100vw'}}>
                                    <Card className='no-background width100 border-0'>
                                        <Col style={style.MEDIUM}>

                                            <Col size={12} style={style.SMALL}>
                                                <Card className='width100 border-0 no-background'>
                                                    <CardText className='CormorantGaramondWithoutSize'>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <h1 style={{
                                                            fontSize: '48px',
                                                            color: "white",
                                                            fontWeight: "bold"
                                                        }}>Transform Your CV and LinkedIn Presence</h1>
                                                    </CardText>
                                                </Card>
                                            </Col>
                                        </Col>
                                    </Card>
                                </div>
                            </Parallax>
                            <Col style={style.SMALL}/>
                            <Col>
                            <Card className='width100 border-0 ' style={{color: "black", backgroundColor: 'white'}}>
                                <CardText>
                                    <h3 className='playfair_Display '
                                        style={{fontSize: '16px', color: "black", fontWeight: 'bold'}}>
                                        <Fade left>
                                            <div>
                                                Does your CV and LinkedIn profile need a makeover?  Whether you're eyeing that next career move or simply want to ensure your CV and LinkedIn profile are first rate, you're in the right place.
                                            </div>
                                        </Fade>
                                    </h3>
                                </CardText>
                            </Card>
                            </Col>
                            <OnceHubPopUpWidget/>
                            <Col>
                                <Card className=' width100 border-0' style={{
                                    backgroundColor: '#FFFFFF', width: '100%'
                                }}>
                                    <CardText className="CormorantGaramondWithoutSizeAndSpacing " style={{
                                        color: "black", textAlign: "left",
                                        width: "100%",
                                    }}>
                                        <h2 className='playfair_Display'
                                            style={{fontSize: '22px', fontStyle: 'italic'}}>How it works:</h2>
                                        <CardText className='playfair_Display' style={{fontSize: '14px'}}><h4
                                            className='playfair_Display'
                                            style={{fontSize: '14px', fontWeight: 'bold'}}>Pre-Session Audit & Reflection: </h4>
                                            Before we meet, I'll conduct a detailed audit of your LinkedIn profile. Plus, I'll send you a thought-provoking questionnaire designed to get you reflecting on your achievements, values, experience, and skills. This prep work ensures our session is laser-focused on maximising your profile's impact and aligning your professional story with your career aspirations.
                                        </CardText>
                                        <CardText className='playfair_Display' style={{fontSize: '14px'}}><h4
                                            className='playfair_Display'
                                            style={{fontSize: '14px', fontWeight: 'bold'}}>Tailored 1:1 Coaching: </h4> In our 90-minute session, we'll dive deep into your career aspirations, skills, and experiences. I'll guide you on how to craft a compelling CV and LinkedIn profile that not only showcases your professional journey but also resonates with your dream employers or clients.
                                        </CardText>
                                        <CardText className='playfair_Display' style={{fontSize: '14px'}}><h4
                                            className='playfair_Display'
                                            style={{fontSize: '14px', fontWeight: 'bold'}}>Follow-Up Feedback: </h4>
                                            After our session, you'll have the opportunity to send me your updated CV or LinkedIn profile for one final round of feedback. This ensures your documents are polished, professional, and ready to make an outstanding first impression.
                                        </CardText>
                                        <br/>
                                        <p className='playfair_Display' style={{fontSize: '14px'}}><h2
                                            className='playfair_Display'
                                            style={{fontSize: '14px', fontWeight: 'bold'}}>Your Investment: £250</h2>
                                            This one-off investment in your professional development is an investment in your future success. Ready to stand out from the crowd and make your mark?
                                        </p>
                                        <br/>
                                        <CardText className='playfair_Display' style={{fontSize: '14px'}}><h2
                                            className='playfair_Display'
                                            style={{fontSize: '14px', fontWeight: 'bold'}}>Let's Get Started</h2>
                                            Spaces are limited, and my diary fills up fast. So secure your spot,
                                            and let's embark on this journey to raise your professional presence.
                                        </CardText>
                                        <br/>
                                        <CardText>
                                            <Button className='playfair_Display'
                                                    href="https://go.oncehub.com/JoannaStokesCoaching"
                                                    style={{
                                                        backgroundColor: '#85B2EE',
                                                        borderColor: '#85B2EE',
                                                        color: 'black',
                                                        fontSize: '14px',
                                                        fontWeight: 'bold'
                                                    }} target='blank'
                                            >Book Now</Button>
                                        </CardText>
                                    </CardText>
                                </Card>
                            </Col>
                        </Grid>
                        <Footer/>
                    </GymnastProvider>
                </div>

            );
        }

        return (
            <FullWidthGrid/>
        );
    }
}

export default CvAndLinkedInPackageMobile;