import React from "react";
import {Card, CardText} from "reactstrap";
import '../../css/Alignment.css';
import '../../css/Components.css';
import '../../css/Animations.css';
import '../../css/Elements.css'
import '../../css/Fonts.css'
import '../../css/Parallax.css'
import '../../css/Size.css'
import {Col, Grid, GymnastProvider} from "gymnast";
import MyNavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import {Responsive} from "../../components/Responsive";


const translucent = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    TALL: {...translucent, minHeight: 200, align: "top"},
    MEDIUM: {...translucent, minHeight: 400},
    SMALL: {...translucent, minHeight: 50}
};

class Testimonial3 extends React.Component {
    render() {
        function FullWidthGrid() {

            return (
                <div className="background">
                    <GymnastProvider>
                        <MyNavBar/>
                        <Grid marginTop="S" paddingTop="0">
                            <Responsive displayIn={["Laptop", "Tablet"]}>
                                <Col style={style.SMALL}>
                                    <Card className='width100 border-0 '
                                          style={{fontSize: '80px', backgroundColor: '#f5f3f2'}}>
                                        <CardText className=' CormorantGaramondWithoutSize'>
                                            Testimonial
                                        </CardText>
                                    </Card>
                                </Col>
                            </Responsive>

                            <Responsive displayIn={["Mobile"]}>
                                <Col style={style.SMALL}>
                                    <Card className='width100 border-0 '
                                          style={{fontSize: '50px', backgroundColor: '#f5f3f2'}}>
                                        <CardText className=' CormorantGaramondWithoutSize'>
                                            Testimonial
                                        </CardText>
                                    </Card>
                                </Col>
                                <Col style={style.SMALL}/>
                                <Col style={style.SMALL}/>
                            </Responsive>
                            <Col style={style.SMALL}/>
                            <Col style={style.SMALL}>
                                <Card className=' no-background h-75 border-0 center'>
                                    <CardText className='CormorantGaramondWithoutSize no-background' style={{
                                        fontSize: '20px',
                                        color: "black",
                                        textAlign: 'left',
                                        padding: '20px',
                                    }}>
                                        <p> The sessions have helped me consider my career goals and how I can achieve them. Jo helped
                                            me to clarify and set and achieve small goals in order to assist me in achieving my ultimate
                                            professional goal. Through doing this, I felt that I was able to expand on my comfort zone,
                                            assess my strengths and abilities, and ultimately change my thinking around the decisions
                                            that I make. </p>
                                        <br/>
                                        <p> Coaching has helped me reflect on my work achievements more and keep me focused on my
                                            desired career goal and how to achieve it. Throughout the course of the sessions, Jo’s
                                            coaching resulted in me gaining confidence in my abilities and motivated me to take steps to
                                            make career changes. I was also able to analyse any barriers that were preventing me from
                                            reaching my goals and how to overcome them. </p>
                                        <br/>
                                        <p className='ibarra' style={{fontWeight: 'bold'}}>KL, Redcar, May 2020</p>
                                    </CardText>
                                </Card>
                            </Col>
                        </Grid>
                        <Footer/>
                    </GymnastProvider>
                </div>

            );
        }

        return (
            <FullWidthGrid/>
        );
    }
}

export default Testimonial3;