import React from "react";
import {Card, CardText} from "reactstrap";
import {Col, Grid} from "gymnast";
import CustomButton from "../OneceHubBookingButton";
import honeycomb from "../../resources/images/yellow-stars.png";
import LeadershipAndManagementTestimonialsOne from "./LeadershipAndManagementTestimonialsOne";
import marble from "../../resources/images/marble.png";
import {Parallax} from "react-parallax";
import LeadershipAndManagementTestimonialsTwo from "./LeadershipAndManagementTestimonialsTwo";
import Fade from "react-reveal";

const boxStyleGrey = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...boxStyleGrey, minHeight: '20vw', align: "top"},
    TALL: {...boxStyleGrey, minHeight: '50vw'},
    XTALL: {...boxStyleGrey, minHeight: '100vw', align: "top"},
    SMALL: {...boxStyleGrey, minHeight: 100},
    MEDIUM_WHITE: {...boxStyleGrey, minHeight: 300},
    XSMALL: {...boxStyleGrey, minHeight: 30},
};

class CoachingPackagesForOrganisations extends React.Component {
    render() {
        return (
            <Card className='no-background width100 border-0 '>
                <Grid marginTop="S" paddingTop="0">
                    <Parallax bgImage={marble} strength={500}>
                        <Col style={style.XSMALL}/>
                        <Col style={{minHeight: '20vw', align: "top", backgroundColor: 'white'}}>
                            <Col size={12} style={style.MEDIUM}>
                                <Card className=' width100 border-0' style={{
                                    backgroundColor: '#FFFFFF'
                                }}>
                                    <CardText className="CormorantGaramondWithoutSizeAndSpacing  " style={{
                                        fontSize: '14px',
                                        color: "black", textAlign: "left", paddingLeft: '6px'
                                    }}>
                                        <br/>
                                        <Fade right >
                                            <div>
                                                <h4 className='playfair_Display' style={{fontSize: '26px'}}>One-To-One
                                                    Coaching
                                                    For Your Leaders</h4>
                                            </div>
                                        </Fade>
                                    </CardText>
                                </Card>
                            </Col>
                            <Col size={12} style={style.MEDIUM}>
                                <Card className=' width100 border-0 center'
                                      style={{
                                          backgroundColor: '#FFFFFF'
                                      }}>
                                    <CardText className="CormorantGaramondWithoutSizeAndSpacing  " style={{
                                        fontSize: '14px',
                                        color: "black", textAlign: "left", paddingLeft: '6px'
                                    }}>
                                        <ul>
                                            <Fade left >
                                                <div>
                                                    <li style={{fontSize: '14px'}}>To inspire them to <h4
                                                        className='CormorantGaramondWithoutSizeAndSpacing'
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '14px'
                                                        }}>perform to their
                                                        best</h4></li>
                                                    <li style={{fontSize: '14px'}}>To achieve <h4
                                                        className='CormorantGaramondWithoutSizeAndSpacing'
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '14px'
                                                        }}>high-quality results
                                                    </h4></li>
                                                    <li style={{fontSize: '14px'}}>To empower them to <h4
                                                        className='CormorantGaramondWithoutSizeAndSpacing'
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '14px'
                                                        }}>lead with
                                                        confidence</h4>, clarity, and wisdom
                                                    </li>
                                                </div>
                                            </Fade>
                                        </ul>
                                    </CardText>
                                    <br/>
                                </Card>
                                <Fade bottom >
                                    <div>
                                        <Card className=' width100 border-0' style={{
                                            backgroundColor: '#FFFFFF'
                                        }}>
                                            <CardText className="playfair_Display  " style={{
                                                fontSize: '14px',
                                                color: "black", textAlign: "left", paddingLeft: '6px'
                                            }}>
                                                <CustomButton color='#85B2EE' text="I Want To Discuss This Option"/>
                                                <br/>
                                            </CardText>
                                        </Card>
                                    </div>
                                </Fade>
                            </Col>
                        </Col>
                        <Col style={style.XSMALL}/>
                    </Parallax>

                    <Col size={12} style={style.XSMALL}>
                        <Card className=' width100 border-0 center' style={{
                            backgroundColor: '#FFFFFF',
                            marginLeft: '10px',
                            marginRight: '10px',
                            width: '100%'
                        }}>
                            <CardText className="CormorantGaramondWithoutSizeAndSpacing  " style={{
                                fontSize: '14px',
                                color: "black", textAlign: "left",
                                width: "90%",
                            }}>
                                <br/>
                                <Fade left >
                                    <div>
                                        <h4 className='playfair_Display' style={{fontSize: '26px'}}> Team Training In
                                            Leadership</h4>
                                    </div>
                                </Fade>
                            </CardText>
                        </Card>
                    </Col>
                    <Col style={{minHeight: '20vw', align: "top", backgroundColor: 'white'}}>
                        <Col size={12} style={style.MEDIUM}>
                            <Card className=' width100 border-0 center'
                                  style={{
                                      backgroundColor: '#FFFFFF'
                                  }}>
                                <CardText className="CormorantGaramondWithoutSizeAndSpacing  " style={{
                                    fontSize: '14px',
                                    color: "black", textAlign: "left", paddingLeft: '6px'
                                }}>
                                    <ul>
                                        <Fade right >
                                            <div>
                                                <li style={{fontSize: '14px'}}>To develop the soft skills that are now
                                                    essential
                                                    for running a <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                                      style={{
                                                                          fontWeight: 'bold',
                                                                          fontSize: '14px'
                                                                      }}>motivated and efficient team</h4> (including
                                                    communication skills, time management, listening, empathy…)
                                                </li>
                                                <li style={{fontSize: '14px'}}>To create leaders with high emotional
                                                    intelligence and self-awareness – to <h4
                                                        className='CormorantGaramondWithoutSizeAndSpacing'
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '14px'
                                                        }}>bring out the best in their
                                                        people
                                                    </h4></li>
                                                <li style={{fontSize: '14px'}}>To establish inspirational leaders who
                                                    are <h4
                                                        className='CormorantGaramondWithoutSizeAndSpacing'
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '14px'
                                                        }}>skilled and equipped to coach the future leaders</h4> of your
                                                    organisation
                                                </li>
                                            </div>
                                        </Fade>
                                    </ul>
                                </CardText>
                            </Card>
                            <Fade bottom >
                                <div>
                                    <Card className=' width100 border-0 center' style={{
                                        backgroundColor: '#FFFFFF'
                                    }}>
                                        <CardText className="playfair_Display  " style={{
                                            fontSize: '14px',
                                            color: "black", textAlign: "left",
                                            width: "90%",
                                        }}>
                                            <CustomButton color='#85B2EE' text="I Want To Discuss This Option"/>
                                            <br/>
                                        </CardText>
                                    </Card>
                                </div>
                            </Fade>
                        </Col>
                    </Col>

                    <Card className=' width100 border-0 center' style={{
                        backgroundColor: '#FFFFFF'
                    }}>
                        <Parallax bgImage={honeycomb} strength={500}>
                            <LeadershipAndManagementTestimonialsOne/>
                        </Parallax>
                    </Card>

                    <Col style={{minHeight: '20vw', align: "top", backgroundColor: 'white'}}>
                        <Col size={12} style={style.XSMALL}>
                            <Card className=' width100 border-0 center' style={{
                                backgroundColor: '#FFFFFF'
                            }}>
                                <CardText className="CormorantGaramondWithoutSizeAndSpacing  " style={{
                                    fontSize: '14px',
                                    color: "black", textAlign: "left", paddingLeft: '6px'
                                }}>
                                    <br/>
                                    <Fade right >
                                        <div>
                                            <h4 className='playfair_Display'
                                                style={{fontSize: '30px'}}>Workshops/Training/
                                                Speaking: </h4>
                                        </div>
                                    </Fade>
                                    <br/>
                                    <br/>
                                    <ul>
                                        <Fade left >
                                            <div>
                                                <li style={{fontSize: '14px'}}>To explore a
                                                    <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                        style={{fontWeight: 'bold', fontSize: '14px'}}> leadership,
                                                        coaching or
                                                        team building</h4> topic of your choice
                                                </li>
                                            </div>
                                        </Fade>
                                    </ul>
                                </CardText>
                            </Card>
                        </Col>
                        <Col size={12} style={style.MEDIUM}>
                            <Card className=' width100 border-0 center'
                                  style={{
                                      backgroundColor: '#FFFFFF'
                                  }}>
                                <Fade right >
                                    <div>
                                        <CardText className="CormorantGaramondWithoutSizeAndSpacing  " style={{
                                            fontSize: '14px',
                                            color: "black", textAlign: "left", paddingLeft: '6px'
                                        }}>
                                            These are powerful, energetic days to inspire real change in your people.
                                            They will leave your workforce feeling more motivated, energised and
                                            positive about
                                            their role in your organisation.
                                            <br/>
                                            <br/>
                                            <p style={{fontSize: '14px'}}>And a happy team <h4
                                                className='CormorantGaramondWithoutSizeAndSpacing'
                                                style={{
                                                    fontWeight: 'bold',
                                                    fontSize: '14px'
                                                }}>works harder, smarter, and is
                                                dedicated</h4> to staying onboard.
                                            </p>
                                        </CardText>
                                    </div>
                                </Fade>
                                <Fade bottom >
                                    <div>
                                        <br/>
                                        <CardText className="playfair_Display  " style={{
                                            fontSize: '14px',
                                            color: "black", textAlign: "left", paddingLeft: '6px'
                                        }}>
                                            <CustomButton color='#85B2EE' text="I Want To Discuss This Option"/>
                                        </CardText>
                                    </div>
                                </Fade>
                            </Card>
                        </Col>
                    </Col>
                    <Card className=' width100 border-0 center' style={{
                        backgroundColor: '#FFFFFF'
                    }}>
                        <Parallax bgImage={honeycomb} strength={500}>
                            <LeadershipAndManagementTestimonialsTwo/>
                        </Parallax>
                    </Card>
                </Grid>
            </Card>
        )
    }
}

export default CoachingPackagesForOrganisations;