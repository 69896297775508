import React, {useState} from 'react';
import {Accordion, AccordionBody, AccordionHeader, AccordionItem, Card,} from 'reactstrap';
import {Col, Grid} from "gymnast";
import '../../css/Accordion.css';

function Faqs(props) {
    const [open, setOpen] = useState('');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };

    return (
        <Grid marginTop="S" paddingTop="0">
            <Col>
                <Card className='width100 border-0' style={{backgroundColor: '#EAF8F9'}}>
                    <br/>
                    <h4 className='playfair_Display' style={{fontWeight: 'bold', fontSize: '28px'}}>Frequently asked
                        questions
                    </h4>
                    <br/>
                </Card>
            </Col>
            <Col size={1}/>
            <Col size={10}>
                <Card style={{fontSize: '18px', width: "fit-content"}} className='border-0 playfair_Display d-flex justify-content-center'>
                    <Accordion flush open={open} toggle={toggle}>
                        <AccordionItem>
                            <AccordionHeader targetId="1">
                                <p style={{fontSize: '20px', textAlign: 'left'}}>What is the duration of the Career Crossroads
                                programme?</p></AccordionHeader>
                            <AccordionBody accordionId="1">
                                The programme spans 8 weeks, with 1:1 coaching sessions every fortnight.
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="2"> <p style={{fontSize: '20px', textAlign: 'left'}}>How is the programme delivered, and can I choose between
                                in-person and virtual sessions?</p> </AccordionHeader>
                            <AccordionBody accordionId="2">
                                You have the flexibility to participate either in person or via virtual sessions,
                                depending on your
                                location and preferences.
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="3"> <p style={{fontSize: '20px', textAlign: 'left'}}>What can I expect from the 1:1 coaching sessions with
                                you?</p> </AccordionHeader>
                            <AccordionBody accordionId="3">
                                During the personalised coaching sessions, we will address your specific needs,
                                challenges, and
                                goals, providing guidance, support, and actionable strategies to help you achieve career
                                fulfilment.
                                You can expect me to challenge your beliefs, fears and thinking allowing you to look at
                                things from a
                                different perspective and try new ways of working.
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="4"> <p style={{fontSize: '20px', textAlign: 'left'}}>What if I need to reschedule a coaching
                                session?</p> </AccordionHeader>
                            <AccordionBody accordionId="4">
                                I understand that life can be unpredictable. If you need to reschedule a session, simply
                                let me know
                                in advance, and we will work to find a suitable alternative time.
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="5"> <p style={{fontSize: '20px', textAlign: 'left'}}>How will I know if this is right for me?</p> </AccordionHeader>
                            <AccordionBody accordionId="5">
                                The free Discovery Call is an opportunity for me to find out information about your
                                goals and for you
                                to ask any questions you may have. It is also a good way for us to find out if we will
                                work well
                                together. If, after this call you decide this is not for you, then you are under no
                                obligation to do so
                                and you can walk away.
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="6"> <p style={{fontSize: '20px', textAlign: 'left'}}>What resources and materials will be provided to support my
                                journey during the programme?</p> </AccordionHeader>
                            <AccordionBody accordionId="6">
                                Throughout the programme, you&#39;ll have access to a variety of creative exercises,
                                checklists, tools, and
                                resources that will help you progress between coaching sessions. I will also introduce
                                you to tools
                                and techniques during the coaching sessions to help you develop your confidence,
                                flexibility and
                                resilience.
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="7"> <p style={{fontSize: '20px', textAlign: 'left'}}>Is this programme suitable for those looking to make a major
                                career change or for those who want
                                to excel in their current roles?</p> </AccordionHeader>
                            <AccordionBody accordionId="7">
                                The Career Crossroads programme is designed for individuals at various career stages.
                                Whether
                                you&#39;re considering career progression, career change or wanting to excel in your
                                current role my
                                programme is tailored to your needs. If you are wanting to make a major career transition
                                then the Career Crossroads programme will get you unstuck and get you moving in the right
                                direction.
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>
                </Card>
            </Col>
            <Col size={1}/>
        </Grid>
    );
}

export default Faqs;