import React from "react";
import {Card, CardText} from "reactstrap";
import '../../css/Alignment.css';
import '../../css/Components.css';
import '../../css/Animations.css';
import '../../css/Elements.css'
import '../../css/Fonts.css'
import '../../css/Parallax.css'
import '../../css/Size.css'
import {Col, Grid, GymnastProvider} from "gymnast";
import MyNavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import {Responsive} from "../../components/Responsive";


const translucent = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    TALL: {...translucent, minHeight: 200, align: "top"},
    MEDIUM: {...translucent, minHeight: 400},
    SMALL: {...translucent, minHeight: 50}
};

class Testimonial4 extends React.Component {
    render() {
        function FullWidthGrid() {

            return (
                <div className="background">
                    <GymnastProvider>
                        <MyNavBar/>
                        <Grid marginTop="S" paddingTop="0">
                            <Responsive displayIn={["Laptop", "Tablet"]}>
                                <Col style={style.SMALL}>
                                    <Card className='width100 border-0 '
                                          style={{fontSize: '80px', backgroundColor: '#f5f3f2'}}>
                                        <CardText className=' CormorantGaramondWithoutSize'>
                                            Testimonial
                                        </CardText>
                                    </Card>
                                </Col>
                            </Responsive>

                            <Responsive displayIn={["Mobile"]}>
                                <Col style={style.SMALL}>
                                    <Card className='width100 border-0 '
                                          style={{fontSize: '50px', backgroundColor: '#f5f3f2'}}>
                                        <CardText className=' CormorantGaramondWithoutSize'>
                                            Testimonial
                                        </CardText>
                                    </Card>
                                </Col>
                                <Col style={style.SMALL}/>
                                <Col style={style.SMALL}/>
                            </Responsive>
                            <Col style={style.SMALL}/>
                            <Col style={style.SMALL}>
                                <Card className=' no-background h-75 border-0 center'>
                                    <CardText className='CormorantGaramondWithoutSize no-background' style={{
                                        fontSize: '20px',
                                        color: "black",
                                        textAlign: 'left',
                                        padding: '20px',
                                    }}>
                                        <br/>
                                        <br/>
                                        <p>"Last summer I decided it was time for a change of direction in my career but was
                                            determined not just to jump into a slightly different version of what I had done for
                                            many years. I wanted to find a role which excited me, which was aligned with my
                                            values and which was a good fit for my strengths, skills and interests. I contacted
                                            Joanna.</p>
                                        <br/>
                                        <p>Over the course of six weeks we explored what makes me tick and what I was really
                                            looking for from my next role. Among lots of other things, we explored the types of
                                            activities that I was good at and enjoyed as well as the way I needed to manage my
                                            time in order to stay energised and focused.</p>
                                        <br/>
                                        <p>During my time working with Joanna, I secured my role with Amnesty International
                                            as Human Rights Education Manager. My conversations with Joanna prepared me
                                            really well for interview, not just because I got used to articulating my skills and
                                            experience but also because I knew what I was looking for in a role and why.</p>
                                        <br/>
                                        <p>I am now three months into my new role and I love it. I am very grateful to Joanna for
                                            her support during a time of change in my career."</p>
                                        <br/>
                                        <p className='ibarra' style={{fontWeight: 'bold'}}>Dan Shercliffe, July 2023</p>
                                    </CardText>
                                </Card>
                            </Col>
                        </Grid>
                        <Footer/>
                    </GymnastProvider>
                </div>

            );
        }

        return (
            <FullWidthGrid/>
        );
    }
}

export default Testimonial4;