import React from "react";
import {Card, CardText} from "reactstrap";
import '../css/Alignment.css';
import '../css/Components.css';
import '../css/Animations.css';
import '../css/Elements.css';
import '../css/Fonts.css';
import '../css/Parallax.css';
import '../css/Size.css';
import {Col, Grid, GymnastProvider} from "gymnast";
import MyNavBar from "../components/NavBar";
import Footer from "../components/Footer";
import image from "../resources/images/crossroads-background.png";
import {Parallax} from "react-parallax";
import Fade from "react-reveal";
import OnceHubPopUpWidget from "../components/OnceHubPopUpWidget";
import ProgramIntroNew from "../components/career-crossroads/ProgramIntroNew";
import ProgramInfoPart1New from "../components/career-crossroads/ProgramInfoPart1New";
import ProgramInfoPart2New from "../components/career-crossroads/ProgramInfoPart2New";
import ProgramFeesNew from "../components/career-crossroads/ProgramFeesNew";
import Faqs from "../components/career-crossroads/Faqs";
import CustomButton from "../components/OneceHubBookingButton";
import Testimonials from "../components/career-crossroads/Testimonials";

const translucent = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    TALL: {...translucent, minHeight: 200, align: "top"},
    MEDIUM: {...translucent, minHeight: 400},
    SMALL: {...translucent, minHeight: 50}
};

class CareerCrossroads extends React.Component {
    render() {
        function FullWidthGrid() {

            return (
                <div className="background">
                    <GymnastProvider>
                        <MyNavBar/>
                        <Grid marginTop="S" paddingTop="0">
                            <Parallax bgImage={image} strength={500}>
                                <div style={{height: 800, width: '100vw'}}>
                                    <Card className='no-background width100 border-0'>
                                        <Col style={style.MEDIUM}>

                                            <Col size={12} style={style.SMALL}>
                                                <Card className='width100 border-0 no-background'>
                                                    <CardText className='CormorantGaramondWithoutSize'>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <h1 style={{
                                                            fontSize: '70px',
                                                            color: "black",
                                                            fontWeight: "bold"
                                                        }}>Career Crossroads</h1>
                                                    </CardText>
                                                </Card>
                                            </Col>
                                        </Col>
                                    </Card>
                                </div>
                            </Parallax>
                            <Col style={style.SMALL}/>
                            <Card className='width100 border-0 ' style={{color: "black", backgroundColor: 'white'}}>
                                <CardText className="ibarra margin-left " style={{
                                    fontSize: '28px',
                                    color: "black",
                                    width: "90%",
                                }}>
                                    <h3 className='playfair_Display '
                                        style={{fontSize: '36px', color: "black", fontWeight: 'bold'}}>
                                        <Fade left>
                                            <div>
                                                I have re-invented my career 3 times over 35 years. I am on my 3rd
                                                career, and definitely my last - but I am exactly where I want to be.
                                            </div>
                                        </Fade>
                                    </h3>
                                </CardText>
                            </Card>
                            <OnceHubPopUpWidget/>
                            <Col style={style.SMALL}/>

                            <Col style={style.SMALL}>
                                <ProgramIntroNew/>
                            </Col>
                            <Col style={style.SMALL}>
                                <ProgramInfoPart1New/>
                            </Col>
                            <Col style={style.SMALL}>
                                <ProgramInfoPart2New/>
                            </Col>
                            <Col style={style.SMALL}>
                                <ProgramFeesNew/>
                            </Col>
                            <Col style={style.SMALL}>
                                <Testimonials/>
                            </Col>
                            <Col style={style.SMALL}>
                                <Faqs/>
                            </Col>

                            <Col style={{backgroundColor: 'white'}}>
                                <Card className='width100 border-0' style={{backgroundColor: 'white'}}>
                                    <CardText className="ibarra margin-left " style={{
                                        color: "black"
                                    }}>
                                        <Fade left big>
                                            <div>
                                                <br/>
                                                <h4 className='ibarra' style={{fontWeight: 'bold', fontSize: '32px'}}>Contact me for
                                                    any queries or to book your free discovery call.
                                                </h4>
                                                <br/>
                                                <br/>
                                            </div>
                                        </Fade>
                                    </CardText>
                                    <div className="ibarra" style={{fontWeight: 'bold', fontSize: '30px'}}>
                                        <CustomButton text="I'm ready to chat, book my free call" color='#87CEE5'/>
                                    </div>
                                </Card>
                            </Col>
                        </Grid>
                        <Footer/>
                    </GymnastProvider>
                </div>

            );
        }

        return (
            <FullWidthGrid/>
        );
    }
}

export default CareerCrossroads;