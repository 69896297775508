import React from "react";
import {Card, CardText} from "reactstrap";
import Fade from 'react-reveal';
import {Col, Grid} from "gymnast";

const boxStyleGrey = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...boxStyleGrey, minHeight: '20vw', align: "top"},
    TALL: {...boxStyleGrey, minHeight: '50vw'},
    XTALL: {...boxStyleGrey, minHeight: '100vw', align: "top"},
    SMALL: {...boxStyleGrey, minHeight: 100},
    MEDIUM_WHITE: {...boxStyleGrey, minHeight: 300},
    XSMALL: {...boxStyleGrey, minHeight: 30},
};


class BlockA extends React.Component {
    render() {
        return (
            <Grid marginTop="S" paddingTop="0">
                <Col className='border-0' style={style.MEDIUM}>
                    <Card className='width100 '
                          style={{color: "black", backgroundColor: 'white'}}>
                        <CardText className="ibarra " style={{
                            fontSize: '18px',
                            color: "black", textAlign: "left", paddingLeft: '6px'
                        }}>
                            <Fade right >
                                <div>
                                    <p className='nixie' style={{fontSize: '26px'}}>
                                        You began your role in Further Education to
                                        <h4 className='nixie' style={{fontWeight: 'bold', fontSize: '26px'}}> make a
                                            difference. </h4></p>
                                </div>
                            </Fade>
                            <Fade left >
                                <div>
                                    <p className='ibarra'> You want to<h4 className='ibarra' style={{
                                        fontWeight: 'bold',
                                        fontSize: '14px'
                                    }}> lead and inspire. </h4></p>
                                    <br/>
                                </div>
                            </Fade>
                        </CardText>
                    </Card>
                </Col>

                <Col className='border-0' style={style.MEDIUM}>
                    <Card className='width100 ' style={{color: "black", backgroundColor: '#f5f3f2'}}>
                        <br/>
                        <CardText className="ibarra " style={{
                            fontSize: '14px',
                            color: "black", textAlign: "left", paddingLeft: '6px'
                        }}>
                            <Fade right >
                                <div>
                                    <p className='ibarra'> You feel like you <h4
                                        className='ibarra'
                                        style={{fontWeight: 'bold', fontSize: '14px'}}>
                                        never switch off. </h4> It can be exhausting, overwhelming and your body and
                                        mind feel
                                        tense.
                                        Work life balance sounds like an
                                        <h4 className='ibarra'
                                            style={{fontWeight: 'bold', fontSize: '14px'}}> unrealistic dream. </h4>
                                        And that’s before you bring in the specific
                                        <h4 className='ibarra'
                                            style={{fontWeight: 'bold', fontSize: '14px'}}> pressures of Further
                                            Education. </h4> Government
                                        changes, cost-cutting,
                                        time-consuming meetings…
                                    </p>
                                    <br/>
                                </div>
                            </Fade>
                        </CardText>
                    </Card>
                </Col>
                <Col className='border-0' style={style.MEDIUM}>
                    <Card className='width100 '
                          style={{color: "black", backgroundColor: 'white'}}>
                        <CardText className="ibarra " style={{
                            fontSize: '18px',
                            color: "black", textAlign: "left", paddingLeft: '6px'
                        }}>
                            <Fade left >
                                <div>
                                    <br/>
                                    <p className='nixie' style={{fontSize: '26px'}}> You’re passionate about helping
                                        others. And you work all the hours to try and be the
                                        <h4 className='nixie' style={{fontWeight: 'bold', fontSize: '26px'}}> best
                                            you can be. </h4></p>
                                </div>
                            </Fade>
                            <Fade right >
                                <div>
                                    <p className='ibarra'> But let’s be honest;<h4 className='ibarra' style={{
                                        fontWeight: 'bold',
                                        fontSize: '14px'
                                    }}> leadership can be tough... </h4></p>
                                    <br/>
                                </div>
                            </Fade>
                        </CardText>
                    </Card>
                </Col>

                <Col className='border-0' style={style.MEDIUM}>
                    <Card className='width100 ' style={{color: "black", backgroundColor: '#f5f3f2'}}>
                        <CardText className="ibarra " style={{
                            fontSize: '14px',
                            color: "black", textAlign: "left", paddingLeft: '6px'
                        }}>
                            <Fade left >
                                <div>
                                    <br/>
                                    <p className='ibarra'> You’re responsible for
                                        keeping your staff motivated, and
                                        yet you often find yourself feeling
                                        <h4 className='ibarra'
                                            style={{fontWeight: 'bold', fontSize: '14px'}}> overworked and
                                            undervalued. </h4>

                                        You work so hard, and yet you're
                                        <h4 className='ibarra'
                                            style={{fontWeight: 'bold', fontSize: '14px'}}> seeing the
                                            results </h4> you deserve.
                                        <br/>
                                        Don’t worry, I get it. Many of my 18 years spent in Further Education roles
                                        felt the
                                        same.
                                        <br/>
                                    </p>
                                    <br/>
                                </div>
                            </Fade>
                        </CardText>
                    </Card>
                </Col>
                <Col className='border-0' style={style.MEDIUM}>
                    <Card className='width100 '
                          style={{color: "black", backgroundColor: 'white'}}>
                        <CardText className="ibarra " style={{
                            fontSize: '18px',
                            color: "black", textAlign: "left", paddingLeft: '6px'
                        }}>
                            <Fade bottom >
                                <div>
                                    <br/>
                                    <p><h4 className='nixie' style={{fontSize: '28px', fontWeight: 'bold'}}>
                                        That's why I'm here to help. </h4></p>
                                </div>
                            </Fade>
                        </CardText>
                    </Card>
                </Col>
            </Grid>
        )
    }
}

export default BlockA;