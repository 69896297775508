import React from "react";
import {Card, CardText} from "reactstrap";
import Fade from "react-reveal";
import {Col, Grid} from "gymnast";

const boxStyleGrey = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...boxStyleGrey, minHeight: '20vw', align: "top"},
    TALL: {...boxStyleGrey, minHeight: '50vw'},
    XTALL: {...boxStyleGrey, minHeight: '100vw', align: "top"},
    SMALL: {...boxStyleGrey, minHeight: 100},
    MEDIUM_WHITE: {...boxStyleGrey, minHeight: 300},
    XSMALL: {...boxStyleGrey, minHeight: 30},
};

class ServicesForOrganisations extends React.Component {
    render() {
        return (
            <Card className='width100 border-0 '
                  style={{color: "black", backgroundColor: '#f5f3f2'}}>
                <Grid marginTop="S" paddingTop="0">
                    <Col size={12} style={style.MEDIUM}>
                        <Card className='width100 border-0 no-background ' style={{color: "black"}}>
                            <CardText className="CormorantGaramondWithoutSizeAndSpacing" style={{
                                fontSize: '14px',
                                color: "black", textAlign: "left", paddingLeft: '6px'
                            }}>
                                <br/>
                                <Fade right >
                                    <div>
                                        <h3 className='playfair_Display' style={{
                                            fontSize: '24px',
                                            fontFamily: 'Ibarra',
                                            textAlign: 'center'
                                        }}>Teaching Your People How To Be Inspirational Leaders</h3>
                                        <br/>
                                        <h2 className='playfair_Display'
                                            style={{fontSize: '14px'}}> From my 25 years in management roles (18 of
                                            those in Further Education), I
                                            understand the pressures you face.</h2>
                                    </div>
                                </Fade>
                                <br/>
                            </CardText>
                        </Card>
                    </Col>
                    <Col size={12} style={style.MEDIUM}>
                        <Card className=' width100 border-0 no-background' style={{
                            backgroundColor: '#FFFFFF'
                        }}>
                            <CardText className="CormorantGaramondWithoutSizeAndSpacing" style={{
                                fontSize: '14px',
                                color: "black", textAlign: "left", paddingLeft: '6px'
                            }}>
                                <Fade left >
                                    <div>
                                        <h2 className='playfair_Display'
                                            style={{fontSize: '14px', fontWeight: 'bold'}}>You know it’s vital for your
                                            business to have:</h2>
                                    </div>
                                </Fade>
                                <ul>
                                    <Fade left >
                                        <div>
                                            <li style={{fontSize: '14px'}}>Leaders who <h4
                                                className='CormorantGaramondWithoutSizeAndSpacing'
                                                style={{fontWeight: 'bold', fontSize: '14px'}}>inspire their
                                                teams</h4> to perform
                                            </li>
                                            <br/>
                                            <li style={{fontSize: '14px'}}>A <h4
                                                className='CormorantGaramondWithoutSizeAndSpacing'
                                                style={{
                                                    fontWeight: 'bold',
                                                    fontSize: '14px'
                                                }}>stable and
                                                happy workforce</h4> who are dedicated to staying with you
                                            </li>
                                            <br/>
                                            <li style={{fontSize: '14px'}}>Staff who are <h4
                                                className='CormorantGaramondWithoutSizeAndSpacing'
                                                style={{
                                                    fontWeight: 'bold',
                                                    fontSize: '14px'
                                                }}>hardworking,
                                                organised and efficient</h4></li>
                                            <br/>
                                        </div>
                                    </Fade>
                                </ul>
                            </CardText>
                        </Card>
                    </Col>
                    <Col size={12} style={style.MEDIUM}>
                        <Card className=' width100 border-0 no-background center'
                              style={{
                                  backgroundColor: '#FFFFFF'
                              }}>
                            <Fade bottom >
                                <div>
                                    <CardText className="CormorantGaramondWithoutSizeAndSpacing" style={{
                                        fontSize: '14px',
                                        color: "black", textAlign: "left", paddingLeft: '6px'
                                    }}>
                                        Because without this, your organisation will start to lose money at a terrifying
                                        rate.
                                        No business can afford that – especially right now.
                                        That’s why I’m here to help.
                                    </CardText>
                                </div>
                            </Fade>
                        </Card>
                    </Col>
                </Grid>
            </Card>
        )
    }
}

export default ServicesForOrganisations;