import React from 'react';
import '../css/Alignment.css';
import '../css/Components.css';
import '../css/Animations.css';
import '../css/Elements.css'
import '../css/Fonts.css'
import '../css/Parallax.css'
import '../css/Size.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Grid} from "gymnast";
import {Responsive} from '../components/Responsive';
import {Helmet} from "react-helmet";
import CareerCrossroads from "./CareerCrossroads";
import CareerCrossroadsMobile from "./CareerCrossroadsMobile";

class CareerCrossroadsSwitch extends React.Component {
    render() {
        function FullWidthGrid() {
            return (
                <Grid marginTop="S" paddingTop="0">
                    <Responsive displayIn={["Laptop"]}>
                        <CareerCrossroads/>
                    </Responsive>
                    <Responsive displayIn={["Mobile"]}>
                        <CareerCrossroadsMobile/>
                    </Responsive>
                    <Responsive displayIn={["Tablet"]}>
                        <CareerCrossroads/>
                    </Responsive>
                </Grid>
            );
        }

        return (
            <div>
                <Helmet>
                    <meta name="description" content="Do you want to know how to be happier at work? Leadership Coach Joanna Stokes specialises in helping FE professionals find career fulfilment. Read her story here." />
                    <link rel="canonical" href="http://mycoachjoanna/career-crossroads" />
                </Helmet>
                <FullWidthGrid/>
            </div>
        );
    }
}

export default CareerCrossroadsSwitch;
