import React from "react";
import {Card, CardText} from "reactstrap";
import Fade from "react-reveal";
import {Col, Grid} from "gymnast";


class ProgramFeesNew extends React.Component {
    render() {
        return (
            <Grid marginTop="S" paddingTop="0">
                <Col style={{backgroundColor: '#EAF8F9'}}>
                    <Card className='width100 border-0' style={{backgroundColor: '#EAF8F9'}}>
                        <CardText className="ibarra " style={{
                            fontSize: '14px',
                            color: "black", textAlign: "left",
                        }}>
                            <br/>
                            <Fade left big>
                                <div>
                                    <h4 className='ibarra' style={{fontWeight: 'bold', fontSize: '18px'}}>What's included
                                    </h4>
                                </div>
                            </Fade>
                            <br/>
                            <Fade left big>
                                <div>
                                    <ul style={{
                                        fontSize: '12px',
                                        color: "black",
                                        listStyleType: 'initial',
                                        listStylePosition: 'inherit'
                                    }}>
                                        <Fade right big >
                                            <div>
                                                <li>Personalised 1:1 coaching sessions every fortnight with me.</li>
                                                <li>A comprehensive array of creative exercises, checklists, tools, and resources to work on
                                                    between each 1:1 session.</li>
                                                <li>The DISC personality profile assessment and a detailed report.</li>
                                                <li>Unlimited support from me via Whatsapp/email between sessions.</li>
                                                <li>Valuable introductions to my extensive network of connections.</li>
                                                <li>Benefit from a highly experienced, trained, accredited, and supervised coach who has an
                                                    extensive background in career change and leadership and management. You&#39;ll receive a
                                                    blend of coaching and mentoring from an expert who will challenge you and hold you
                                                    accountable.</li>
                                            </div>
                                        </Fade>
                                    </ul>
                                </div>
                            </Fade>
                            <br/>
                            <Fade>
                                <div>
                                    <h4 style={{fontSize: '14px'}}>To embark on this transformative 8 week journey, the investment is £600.00. For your
                                        convenience, I also offer the option of 3 instalments of £200 each.</h4>
                                </div>
                            </Fade>
                            <br/>
                        </CardText>
                    </Card>
                </Col>
            </Grid>
        )
    }
}

export default ProgramFeesNew;