import React from "react";
import {Card, CardText} from "reactstrap";

const TEXT_COLLAPSE_OPTIONS = {
    collapse: false, // default state when component rendered
    collapseText: '... show more', // text to show when collapsed
    expandText: 'show less', // text to show when expanded
    minHeight: 120, // component height when closed
    maxHeight: 400, // expanded to
    textStyle: { // pass the css for the collapseText and expandText here
        color: "grey",
        fontSize: "20px"
    }
};

class LeadershipAndManagementTestimonialsTwo extends React.Component {
    render() {
        return (
            <Card className='width100 border-0 no-background'>
                <CardText className='CormorantGaramondWithoutSizeAndSpacing text-padding'
                          style={{fontSize: '1.8vw', color: "black"}}>
                        <p className='CormorantGaramondWithoutSizeAndSpacing'>
                            <br/>
                            "At Kirklees College we recognise <h4 className='CormorantGaramondWithoutSizeAndSpacing' style={{fontWeight: 'bold', fontSize: '1.8vw'}}>good leaders are critical</h4> to the success of our
                            organisation.
                            When we have had management recruitment gaps, <h4 className='CormorantGaramondWithoutSizeAndSpacing' style={{fontWeight: 'bold', fontSize: '1.8vw'}}>Joanna is the first person I think of as she
                            has excellent leadership skills</h4> and can hit the ground running.
                            She has <h4 className='CormorantGaramondWithoutSizeAndSpacing' style={{fontWeight: 'bold', fontSize: '1.8vw'}}>empowered our middle managers</h4> to recognise and <h4 className='CormorantGaramondWithoutSizeAndSpacing' style={{fontWeight: 'bold', fontSize: '1.8vw'}}>develop their emotional intelligence.</h4>
                            As a result they have had <h4 className='CormorantGaramondWithoutSizeAndSpacing' style={{fontWeight: 'bold', fontSize: '1.8vw'}}>greater empathy and demonstrated improved self-awareness</h4>, which
                            has been fundamental in leading their own teams.
                            One of the key things Joanna worked on with leaders and managers was <h4 className='CormorantGaramondWithoutSizeAndSpacing' style={{fontWeight: 'bold', fontSize: '1.8vw'}}>developing personal
                            growth.</h4>
                            She was able to <h4 className='CormorantGaramondWithoutSizeAndSpacing' style={{fontWeight: 'bold', fontSize: '1.8vw'}}>help managers become more self-aware</h4> and reflective, working with
                            individuals so they could identify the leadership skills they needed to improve and then
                            <h4 className='CormorantGaramondWithoutSizeAndSpacing' style={{fontWeight: 'bold', fontSize: '1.8vw'}}>guided them to set their own goals.</h4>
                            This has resulted in clear ownership of professional development and <h4 className='CormorantGaramondWithoutSizeAndSpacing' style={{fontWeight: 'bold', fontSize: '1.8vw'}}>improvement in
                            performance</h4> over extended time.
                            Joanna also coached leaders in preparation for our last Ofsted Inspection which strengthened
                            our approach and helped us to <h4 className='CormorantGaramondWithoutSizeAndSpacing' style={{fontWeight: 'bold', fontSize: '1.8vw'}}>achieve a positive outcome.</h4>"
                            <br/>
                        </p>
                    <p className='CormorantGaramondWithoutSizeAndSpacing'>
                        <br/>
                        <h4 className='CormorantGaramondWithoutSizeAndSpacing' style={{fontWeight: 'bold', fontSize: '1.8vw'}}> Philippa
                            Firth, Assistant
                            Principal – Adults and HE
                            Kirklees College, Huddersfield
                            June 2020 </h4>
                    </p>
                    <br/>
                </CardText>
            </Card>
        )
    }
}

export default LeadershipAndManagementTestimonialsTwo;