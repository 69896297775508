import {Spinner} from "react-bootstrap";
import React from "react";

const PageSpinner = () => {
    return (
        <div>
            <Spinner animation="border"/>
        </div>
    )
};
export default PageSpinner;