import React from "react";
import {Card, CardText} from "reactstrap";
import {Parallax} from "react-parallax";
import waves from "../resources/images/waves.png";

class TheProblemWithToday extends React.Component {
    render() {
        return (
            <Parallax bgImage={waves} strength={500}>
                    <Card className='width100 border-0 no-background '>
                        <CardText className='ibarraSize22 quote ' style={{fontSize: '5vw', color: "black"}}>
                            <br/>
                            The problem with waiting until
                            <h4 style={{fontWeight: 'bold', fontSize: '5vw', fontFamily: 'Ibarra'}}> tomorrow </h4>
                            is that when it finally arrives
                            it's called
                            <h4 style={{fontStyle: 'italic', fontSize: '5vw', fontFamily: 'Ibarra'}}> today.</h4>
                            <br/>
                            <br/>
                        </CardText>
                    </Card>
            </Parallax>
        )
    }
}

export default TheProblemWithToday;