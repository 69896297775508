import React from "react";
import {Card, CardText} from "reactstrap";
import {Col, Grid} from "gymnast";
import scales from "../../resources/images/blue-scales-fade.png";
import {Parallax} from "react-parallax";
import Fade from "react-reveal";

const boxStyleGrey = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...boxStyleGrey, minHeight: '20vw', align: "top"},
    TALL: {...boxStyleGrey, minHeight: '50vw'},
    XTALL: {...boxStyleGrey, minHeight: '100vw', align: "top"},
    SMALL: {...boxStyleGrey, minHeight: 100},
    MEDIUM_WHITE: {...boxStyleGrey, minHeight: 300},
    XSMALL: {...boxStyleGrey, minHeight: 30},
};

class TwoLessons extends React.Component {
    render() {
        return (
            <Parallax bgImage={scales} strength={500}>
                <Card className='width100 border-0 no-background ' style={{color: "black"}}>
                    <Grid marginTop="S" paddingTop="0">
                        <Col style={style.SMALL}/>
                        <Col size={12} style={style.SMALL}>
                            <Card className=' width100 border-0  no-background'
                                  style={{
                                      backgroundColor: '#FFFFFF',
                                      marginLeft: '10px',
                                      marginRight: '10px',
                                      width: '100%'
                                  }}>
                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                          style={{color: "black", textAlign: 'center'}}>
                                    <Fade left >
                                        <div>
                                            <p style={{fontSize: '18px'}}><h4
                                                className='playfair_Display' style={{
                                                fontWeight: 'bold',
                                                fontSize: '30px',
                                                fontStyle: 'italic'
                                            }}>Never stay in a job that makes you unhappy.
                                            </h4>
                                            </p>
                                            <p className='ibarra' style={{fontSize: '18px'}}>It isn’t worth it.
                                            </p>
                                        </div>
                                    </Fade>
                                </CardText>
                                <br/>
                            </Card>
                        </Col>
                        <Col style={style.XSMALL}/>
                        <Col size={12} style={style.SMALL}>
                            <Card className=' width100 border-0  no-background'
                                  style={{
                                      backgroundColor: '#FFFFFF',
                                      marginLeft: '10px',
                                      marginRight: '10px',
                                      width: '100%'
                                  }}>
                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                          style={{color: "black", textAlign: 'left'}}>
                                    <Fade right >
                                        <div>
                                            <h2 className='playfair_Display' style={{fontSize: '26px'}}>Don’t leave
                                                things until you’re feeling:</h2>
                                        </div>
                                    </Fade>
                                    <ul>
                                        <Fade left >
                                            <div>
                                                <li style={{fontSize: '18px'}}>Unproductive</li>
                                                <li style={{fontSize: '18px'}}>Demotivated</li>
                                                <li style={{fontSize: '18px'}}>Stressed and frustrated</li>
                                                <li style={{fontSize: '18px'}}>Sleepless</li>
                                                <li style={{fontSize: '18px'}}>Exhausted</li>
                                                <li style={{fontSize: '18px'}}>Physically and mentally ill</li>
                                            </div>
                                        </Fade>
                                    </ul>

                                </CardText>
                                <br/>
                            </Card>
                        </Col>
                        <Col size={12} style={style.SMALL}>
                            <Card className=' width100 border-0 no-background'
                                  style={{
                                      backgroundColor: '#FFFFFF',
                                      marginLeft: '10px',
                                      marginRight: '10px',
                                      width: '100%'
                                  }}>
                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                          style={{color: "black", textAlign: 'left'}}>
                                    <Fade right >
                                        <div>
                                            <p style={{fontSize: '18px'}}>The impact on the rest of your life can be
                                                all-consuming.
                                            </p>
                                            <p style={{fontSize: '18px'}}>Instead, find out what you love doing best and
                                                get
                                                someone to pay you for it.

                                            </p>
                                            <p style={{fontSize: '18px'}}>Because
                                                <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                    style={{fontWeight: 'bold', fontSize: '18px'}}> it is possible to
                                                    have your
                                                    ultimate career happiness</h4> and to excel in your role. To get
                                                back that
                                                sense of purpose, joy and self-belief.
                                            </p>
                                            <p style={{fontSize: '18px'}}>And my job as a coach is to
                                                <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                    style={{fontWeight: 'bold', fontSize: '18px'}}> show you exactly how
                                                    to be
                                                    happier at work</h4>
                                                – whether that’s through making small changes or creating a whole new
                                                path.
                                            </p>
                                        </div>
                                    </Fade>
                                </CardText>
                            </Card>
                        </Col>
                        <Col size={12} style={style.XSMALL}>
                            <Card className=' width100 border-0 center no-background'
                                  style={{
                                      backgroundColor: '#FFFFFF',
                                      marginLeft: '10px',
                                      marginRight: '10px',
                                      width: '100%',
                                  }}>
                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                          style={{color: "black", fontSize: '18px'}}>
                                    <br/>
                                    <Fade bottom >
                                        <div>
                                            <p className='nixie' style={{fontSize: '70px'}}>
                                                Together we can do this.</p>
                                        </div>
                                    </Fade>

                                </CardText>
                            </Card>
                        </Col>
                    </Grid>
                    <Col style={style.SMALL}/>
                </Card>
            </Parallax>
        )


    }
}

export default TwoLessons;