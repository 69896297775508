import React from "react";
import {Card, CardText} from "reactstrap";
import Fade from "react-reveal";

class LeadershipAndManagementTestimonialsOne extends React.Component {
    render() {
        return (
            <Card className='width100 border-0 no-background'>
                <CardText className='CormorantGaramondWithoutSizeAndSpacing text-padding'
                          style={{fontSize: '14px', color: "black", textAlign: 'left'}}>
                    <Fade left >
                        <div>
                            <p className='CormorantGaramondWithoutSizeAndSpacing'>
                                <br/>
                                "Joanna took over the Director’s reigns at Swarthmore in April 2018 at a time of great
                                change for the organisation.

                                She <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                        style={{fontWeight: 'bold', fontSize: '14px'}}>gently supported all staff to
                                work together</h4> for the betterment of Swarthmore resulting
                                in a cultural shift to team spirit.
                                <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                    style={{fontWeight: 'bold', fontSize: '14px'}}>Many positive changes</h4> were made,
                                including a systematic overhaul of HR, improved facilities,
                                a new discipline to review and cost all courses, and a refreshing approach to
                                Governance.
                                Her skills included upward management and her influencing of the Board as well as an
                                authentic way of <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                     style={{fontWeight: 'bold', fontSize: '14px'}}>cutting to the root
                                of the problem.</h4>
                                We continue to grow through COVID-19 and develop some of the <h4
                                className='CormorantGaramondWithoutSizeAndSpacing'
                                style={{fontWeight: 'bold', fontSize: '14px'}}>positive building blocks </h4>
                                established during Joanna’s tenure."
                                <br/>
                            </p>
                        </div>
                    </Fade>
                    <Fade right >
                        <div>
                            <p className='CormorantGaramondWithoutSizeAndSpacing'>
                                <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                    style={{fontWeight: 'bold', fontSize: '14px'}}> Julie
                                    Badon, Chair
                                    Swarthmore Adult Education Centre
                                    June 2020 </h4>
                            </p>
                            <br/>
                        </div>
                    </Fade>
                </CardText>
            </Card>
        )
    }
}

export default LeadershipAndManagementTestimonialsOne;