import React from "react";
import {Card, CardText} from "reactstrap";
import {Col, Grid} from "gymnast";
import scales from "../resources/images/blue-scales-fade.png";
import {Parallax} from "react-parallax";
import Fade from "react-reveal";

const boxStyleGrey = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...boxStyleGrey, minHeight: '20vw', align: "top"},
    TALL: {...boxStyleGrey, minHeight: '50vw'},
    XTALL: {...boxStyleGrey, minHeight: '100vw', align: "top"},
    SMALL: {...boxStyleGrey, minHeight: 100},
    MEDIUM_WHITE: {...boxStyleGrey, minHeight: 300},
    XSMALL: {...boxStyleGrey, minHeight: 30},
};

class TwoLessons extends React.Component {
    render() {
        return (
            <Parallax bgImage={scales} strength={500}>
                <Card className='width100 border-0 no-background ' style={{color: "black"}}>
                    <Grid marginTop="S" paddingTop="0">
                        <Col style={style.SMALL}/>

                        <Col size={1} style={style.XSMALL}/>
                        <Col size={10} style={style.XSMALL}>
                            <Card className='width100 border-0 no-background ' style={{color: "black"}}>
                                <CardText>
                                    <Fade left big >
                                        <div>
                                            <p style={{fontSize: '32px'}}><h4
                                                className='ibarra'
                                                style={{
                                                    fontWeight: 'bold',
                                                    fontSize: '36px',
                                                    fontStyle: 'italic'
                                                }}>Never stay
                                                in a job that makes you unhappy.
                                            </h4>
                                            </p>
                                        </div>
                                    </Fade>
                                    <Fade right big >
                                        <div>
                                            <p className='ibarra' style={{fontSize: '32px'}}>It isn’t worth it.
                                            </p>
                                        </div>
                                    </Fade>
                                </CardText>

                            </Card>
                        </Col>
                        <Col size={1} style={style.XSMALL}/>
                        <Col style={style.SMALL}/>

                        <Col size={2} style={style.SMALL}/>
                        <Col size={4} style={style.SMALL}>
                            <Card className=' width100 border-0  no-background'
                                  style={{
                                      backgroundColor: '#FFFFFF',
                                      marginLeft: '10px',
                                      marginRight: '10px',
                                      width: '100%'
                                  }}>
                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                          style={{color: "black", textAlign: 'left'}}>
                                    <Fade left big >
                                        <div>
                                            <h2 className='playfair_Display' style={{fontSize: '40px'}}>Don’t leave
                                                things until you’re feeling:</h2>
                                        </div>
                                    </Fade>
                                    <Fade left big >
                                        <div>
                                            <ul>
                                                <li style={{fontSize: '32px'}}>Unproductive</li>
                                                <li style={{fontSize: '32px'}}>Demotivated</li>
                                                <li style={{fontSize: '32px'}}>Stressed and frustrated</li>
                                                <li style={{fontSize: '32px'}}>Sleepless</li>
                                                <li style={{fontSize: '32px'}}>Exhausted</li>
                                                <li style={{fontSize: '32px'}}>Physically and mentally ill</li>
                                            </ul>
                                        </div>
                                    </Fade>

                                </CardText>
                                <br/>
                            </Card>
                        </Col>

                        <Col size={5} style={style.SMALL}>
                            <Card className=' width100 border-0 no-background'
                                  style={{
                                      backgroundColor: '#FFFFFF',
                                      marginLeft: '10px',
                                      marginRight: '10px',
                                      width: '100%'
                                  }}>
                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                          style={{color: "black", textAlign: 'left'}}>
                                    <Fade right big >
                                        <div>
                                            <p style={{fontSize: '24px'}}>The impact on the rest of your life can be
                                                all-consuming.
                                            </p>
                                            <p style={{fontSize: '24px'}}>Instead, find out what you love doing best and
                                                get someone to pay you for it.

                                            </p>
                                            <p style={{fontSize: '24px'}}>Because
                                                <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                    style={{fontWeight: 'bold', fontSize: '24px'}}> it is possible to
                                                    have your ultimate career happiness</h4> and to excel in your role.
                                                To get back that sense of purpose, joy and self-belief.
                                            </p>
                                            <p style={{fontSize: '24px'}}>And my job as a coach is to
                                                <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                    style={{fontWeight: 'bold', fontSize: '24px'}}> show you exactly how
                                                    to be happier at work</h4>
                                                – whether that’s through making small changes or creating a whole new
                                                path.
                                            </p>
                                        </div>
                                    </Fade>
                                </CardText>
                            </Card>
                        </Col>

                        <Col size={1} style={style.SMALL}/>

                        <Col size={2} style={style.XSMALL}/>
                        <Col size={8} style={style.XSMALL}>
                            <Card className=' width100 border-0 center no-background'
                                  style={{
                                      backgroundColor: '#FFFFFF',
                                      marginLeft: '10px',
                                      marginRight: '10px',
                                      width: '100%',
                                  }}>
                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                          style={{color: "black", fontSize: '24px'}}>
                                    <br/>
                                    <p className='nixie' style={{fontSize: '70px'}}>
                                        Together we can do this.</p>

                                </CardText>
                            </Card>
                        </Col>
                        <Col size={2} style={style.XSMALL}/>
                    </Grid>

                    <Col style={style.SMALL}/>

                </Card>
            </Parallax>
        )


    }
}

export default TwoLessons;