import React from "react";
import {Card, CardText} from "reactstrap";
import {Col, Grid, GymnastProvider} from "gymnast";
import {Parallax} from "react-parallax";
import yellow from "../../resources/images/yellow-stars.png";
import {CardImg} from "react-bootstrap";
import lightBulb from "../../resources/images/lightbulb.png"
import jo from "../../resources/images/cross_legged.jpg"
import Fade from "react-reveal";


const translucent = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    TALL: {...translucent, minHeight: 200, align: "top"},
    MEDIUM: {...translucent, minHeight: 400},
    SMALL: {...translucent, minHeight: 50}
};

class AboutMeStoryPart2 extends React.Component {
    render() {
        function FullWidthGrid() {

            return (
                <div className="background">
                    <GymnastProvider>
                        <Parallax bgImage={yellow} strength={500}>
                            <Col style={style.SMALL}/>

                            <Col style={{minHeight: '20vw', align: "top", backgroundColor: 'white'}}>
                                <Card className='border-0' style={{}}>
                                    <Grid marginTop="S" paddingTop="0">
                                        <Col style={style.SMALL}/>
                                        <Col size={12} style={style.SMALL}>
                                            <Card className=' width100 border-0 '
                                                  style={{
                                                      backgroundColor: '#FFFFFF',
                                                      marginLeft: '10px',
                                                      marginRight: '10px',
                                                      width: '100%'
                                                  }}>
                                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                                          style={{color: "black", textAlign: 'left'}}>
                                                    <Fade right >
                                                        <div>
                                                            <h2 className='playfair_Display'
                                                                style={{fontSize: '40px'}}>My
                                                                Desire To Be The Best Possible Leader
                                                            </h2>
                                                        </div>
                                                    </Fade>
                                                    <Fade left >
                                                        <div>
                                                            <p style={{fontSize: '14px'}}>It ignited my desire to be the
                                                                greatest leader I could be and help others become the
                                                                best
                                                                leaders too.
                                                            </p>
                                                            <p style={{fontSize: '14px'}}>In the long run it led me to
                                                                the
                                                                decision that I would never work for someone else again.

                                                            </p>
                                                            <p style={{fontSize: '14px'}}>So I left the world of
                                                                employment –
                                                                feeling bruised and exhausted – but elated that I could
                                                                at last
                                                                be self-employed and work on my own terms.</p>
                                                            <p style={{fontSize: '14px'}}>Leading from the heart.
                                                            </p>
                                                            <p style={{fontSize: '14px'}}>I took up various interim
                                                                manager
                                                                positions in different colleges.
                                                            </p>
                                                            <p style={{fontSize: '14px'}}>At last, I was on my way to
                                                                discovering how to be happier at work.
                                                            </p>
                                                        </div>
                                                    </Fade>
                                                </CardText>
                                            </Card>
                                        </Col>
                                        <Col size={12} style={style.SMALL}>

                                            <br/>
                                            <Card className=' width100 border-0 '
                                                  style={{
                                                      backgroundColor: '#FFFFFF',
                                                      marginLeft: '10px',
                                                      marginRight: '10px',
                                                      width: '100%'
                                                  }}>
                                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                                          style={{color: "black", textAlign: 'left'}}>
                                                    <Fade left >
                                                        <div>
                                                            <h2 className='playfair_Display'
                                                                style={{fontSize: '40px'}}>The
                                                                Lightbulb Moment </h2>
                                                        </div>
                                                    </Fade>
                                                    <Fade right >
                                                        <div>
                                                            <p style={{fontSize: '14px'}}>And then someone working for
                                                                me said
                                                                this:
                                                            </p>
                                                            <br/>
                                                            <p style={{
                                                                fontSize: '14px',
                                                                fontWeight: 'bold',
                                                                fontStyle: 'italic'
                                                            }}>“You should become a coach. You’d be brilliant.”</p>
                                                        </div>
                                                    </Fade>
                                                    <br/>
                                                    <Fade left >
                                                        <div>
                                                            <p style={{fontSize: '14px'}}>Yes! That one small comment
                                                                firmly
                                                                rooted an idea that had been planted a few years before,
                                                                when
                                                                I’d experienced professional coaching which transformed
                                                                my own
                                                                working life.
                                                            </p>
                                                            <p style={{fontSize: '14px'}}>It was a pivotal moment.
                                                            </p>
                                                            <p style={{fontSize: '14px'}}> Because I knew then that
                                                                coaching was
                                                                my future.</p>
                                                        </div>
                                                    </Fade>

                                                </CardText>
                                                <br/>
                                            </Card>
                                        </Col>

                                        <Col size={12} style={style.SMALL}>
                                            <Card className=' border-0' style={{backgroundColor: 'rgba(0,0,0,0)'}}>
                                                <CardImg src={lightBulb} style={{borderRadius: '10px'}}/>
                                            </Card>

                                        </Col>
                                        <Col style={style.SMALL}/>

                                    </Grid>
                                </Card>
                            </Col>
                            <Col style={style.SMALL}/>
                        </Parallax>

                        <Parallax bgImage={yellow} strength={500}>
                            <Col style={{minHeight: '20vw', align: "top", backgroundColor: 'white'}}>
                                <Card className='border-0' style={{}}>
                                    <Grid marginTop="S" paddingTop="0">
                                        <Col style={style.SMALL}/>
                                        <Col size={12} style={style.SMALL}>
                                            <Card className=' border-0' style={{backgroundColor: 'rgba(0,0,0,0)'}}>
                                                <CardImg src={jo} style={{
                                                    marginLeft: '10px',
                                                    marginRight: '10px',
                                                }}/>
                                            </Card>

                                        </Col>

                                        <Col size={12} style={style.SMALL}>
                                            <Card className=' width100 border-0 center'
                                                  style={{
                                                      backgroundColor: '#FFFFFF',
                                                      marginLeft: '10px',
                                                      marginRight: '10px',
                                                      width: '100%'
                                                  }}>
                                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                                          style={{color: "black", textAlign: 'left'}}>
                                                    <Fade right >
                                                        <div>
                                                            <h2 className='playfair_Display'
                                                                style={{fontSize: '40px'}}>My
                                                                Ultimate Career
                                                                Happiness </h2>
                                                        </div>
                                                    </Fade>
                                                    <Fade bottom >
                                                        <div>
                                                            <p style={{fontSize: '14px'}}>My new life as a leadership
                                                                and career
                                                                coach for
                                                                leaders in Further Education is much simpler – but the
                                                                perfect
                                                                kind of
                                                                challenge.

                                                            </p>
                                                            <p style={{fontSize: '14px'}}>Can you imagine changing your
                                                                life, so
                                                                you’re
                                                                happier at work too?


                                                            </p>
                                                            <p style={{fontSize: '14px'}}>Because now I no longer travel
                                                                everywhere; most of
                                                                my work is done by phone or Zoom.
                                                            </p>
                                                            <p style={{fontSize: '14px'}}>I start my day with Yoga or a
                                                                Joe
                                                                Wicks workout
                                                                and a leisurely breakfast. I walk every day.</p>
                                                            <p style={{fontSize: '14px'}}>I sleep well, I no longer
                                                                suffer with
                                                                back pain
                                                                and my friends say I’ve never looked happier or
                                                                healthier.
                                                            </p>
                                                            <p style={{fontSize: '14px'}}>I’m doing
                                                                <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                                    style={{fontWeight: 'bold', fontSize: '16px'}}> work
                                                                    I feel
                                                                    passionate
                                                                    about, </h4>
                                                                on my own terms.
                                                            </p>
                                                        </div>
                                                    </Fade>
                                                </CardText>
                                                <br/>
                                            </Card>
                                        </Col>
                                        <Col style={style.SMALL}/>
                                    </Grid>
                                </Card>
                            </Col>
                            <Col style={style.SMALL}/>
                        </Parallax>
                    </GymnastProvider>
                </div>
            );
        }

        return (
            <FullWidthGrid/>
        );
    }
}

export default AboutMeStoryPart2;