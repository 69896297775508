import React from "react";
import {Card, CardText} from "reactstrap";
import {Parallax} from "react-parallax";
import waves from "../../resources/images/ray_lighthouse.jpg";

class TodayIsNotJustAnotherDay extends React.Component {
    render() {
        return (
            <Parallax bgImage={waves} strength={500}>
                    <Card className='width100 border-0 no-background '>
                        <CardText className='CormorantGaramondWithoutSize ' style={{fontSize: '26px', color: "white", fontWeight:'bold'}}>
                            <br/>
                            Today is not just another day.
                            It's a new opportunity.
                            <br/>
                            <br/>
                        </CardText>
                    </Card>
            </Parallax>
        )
    }
}

export default TodayIsNotJustAnotherDay;