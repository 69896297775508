import React from "react";
import {Card, CardText} from "reactstrap";
import '../css/Alignment.css';
import '../css/Components.css';
import '../css/Animations.css';
import '../css/Elements.css';
import '../css/Fonts.css';
import '../css/Parallax.css';
import '../css/Size.css';
import {Col, Grid, GymnastProvider} from "gymnast";
import MyNavBar from "../components/NavBar";
import Footer from "../components/Footer";
import image from "../resources/images/clock.jpg";
import {Parallax} from "react-parallax";
import Fade from "react-reveal";
import OnceHubPopUpWidget from "../components/OnceHubPopUpWidget";
import {Button} from "react-bootstrap";

const translucent = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    TALL: {...translucent, minHeight: 200, align: "top"},
    MEDIUM: {...translucent, minHeight: 400},
    SMALL: {...translucent, minHeight: 50}
};

class ExpressCoachingPowerHourMobile extends React.Component {
    render() {
        function FullWidthGrid() {

            return (
                <div className="background">
                    <GymnastProvider>
                        <MyNavBar/>
                        <Grid marginTop="S" paddingTop="0">
                            <Parallax bgImage={image} strength={500}>
                                <div style={{height: 700, width: '100vw'}}>
                                    <Card className='no-background width100 border-0'>
                                        <Col style={style.MEDIUM}>

                                            <Col size={12} style={style.SMALL}>
                                                <Card className='width100 border-0 no-background'>
                                                    <CardText className='CormorantGaramondWithoutSize'>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <br/>
                                                        <h1 style={{
                                                            fontSize: '48px',
                                                            color: "black",
                                                            fontWeight: "bold"
                                                        }}>Express Coaching Power Hour</h1>
                                                    </CardText>
                                                </Card>
                                            </Col>
                                        </Col>
                                    </Card>
                                </div>
                            </Parallax>
                            <Col style={style.SMALL}/>
                            <Col>
                            <Card className='width100 border-0 ' style={{color: "black", backgroundColor: 'white'}}>
                                <CardText>
                                    <h3 className='playfair_Display '
                                        style={{fontSize: '16px', color: "black", fontWeight: 'bold'}}>
                                        <Fade left>
                                            <div>
                                                When professional challenges can't wait, the Express Coaching Power Hour is here to deliver swift, strategic support tailored to your urgent needs.
                                            </div>
                                        </Fade>
                                    </h3>
                                </CardText>
                            </Card>
                            </Col>
                            <OnceHubPopUpWidget/>
                            <Col>
                                <Card className=' width100 border-0' style={{
                                    backgroundColor: '#FFFFFF', width: '100%'
                                }}>
                                    <CardText className="CormorantGaramondWithoutSizeAndSpacing " style={{
                                        color: "black", textAlign: "left",
                                        width: "100%",
                                    }}>
                                        <h2 className='playfair_Display'
                                            style={{fontSize: '22px', fontStyle: 'italic'}}>What's included?</h2>
                                        <CardText className='playfair_Display' style={{fontSize: '14px'}}><h4
                                            className='playfair_Display'
                                            style={{fontSize: '14px', fontWeight: 'bold'}}>Rapid Response: </h4>
                                            Fast-track booking to get you support within 48 hours, based on availability.
                                            This coaching session is specifically designed for quick turnaround when you
                                            need help asap
                                        </CardText>
                                        <CardText className='playfair_Display' style={{fontSize: '14px'}}><h4
                                            className='playfair_Display'
                                            style={{fontSize: '14px', fontWeight: 'bold'}}>Focused Coaching: </h4> Directly tackle your most pressing professional issue with expert coaching, tailored to target exactly what you need, when you need it most.
                                        </CardText>
                                        <CardText className='playfair_Display' style={{fontSize: '14px'}}><h4
                                            className='playfair_Display'
                                            style={{fontSize: '14px', fontWeight: 'bold'}}>Actionable Outcomes: </h4>
                                            Walk away with clear, actionable steps to immediately address your challenge but also equip you for future challenges.
                                        </CardText>
                                        <br/>
                                        <p className='playfair_Display' style={{fontSize: '14px'}}><h2
                                            className='playfair_Display'
                                            style={{fontSize: '14px', fontWeight: 'bold'}}>Your Investment: £150</h2>
                                            In times of professional urgency, the Express Coaching Power Hour is your key to swift and effective resolution. Don't let challenges derail your progress—tackle them head-on with expert coaching.
                                        </p>
                                        <br/>
                                        <p className='playfair_Display' style={{fontSize: '14px'}}><h2
                                            className='playfair_Display'
                                            style={{fontSize: '14px', fontWeight: 'bold'}}>Ready to get help?</h2>
                                            Spots for the Express Coaching Power Hour are highly sought after and fill up fast. Don't miss your chance for immediate, impactful coaching support.
                                        </p>
                                    </CardText>
                                    <br/>
                                    <CardText className='playfair_Display'
                                              style={{color: "black", textAlign: 'left', fontSize: '14px'}}>
                                        <Button className='playfair_Display'
                                                href="https://go.oncehub.com/JoannaStokesCoaching"
                                                style={{
                                                    backgroundColor: '#85B2EE',
                                                    borderColor: '#85B2EE',
                                                    color: 'black',
                                                    fontSize: '14px',
                                                    fontWeight: 'bold'
                                                }} target='blank'
                                        >Book Your Express Session Now</Button>
                                    </CardText>
                                </Card>
                            </Col>
                        </Grid>
                        <Footer/>
                    </GymnastProvider>
                </div>

            );
        }

        return (
            <FullWidthGrid/>
        );
    }
}

export default ExpressCoachingPowerHourMobile;