import React from "react";
import {Card, CardText} from "reactstrap";
import Fade from "react-reveal";

class LeadershipAndManagementTestimonialsTwo extends React.Component {
    render() {
        return (
            <Card className='width100 border-0 no-background'>
                <CardText className='CormorantGaramondWithoutSizeAndSpacing text-padding'
                          style={{fontSize: '14px', color: "black", textAlign: 'left'}}>
                    <p className='CormorantGaramondWithoutSizeAndSpacing'>
                        <Fade right >
                            <div>
                                <br/>
                                "At Kirklees College we recognise <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                                      style={{
                                                                          fontWeight: 'bold',
                                                                          fontSize: '14px'
                                                                      }}>good leaders are critical</h4> to the success
                                of our
                                organisation.
                                When we have had management recruitment gaps, <h4
                                className='CormorantGaramondWithoutSizeAndSpacing'
                                style={{fontWeight: 'bold', fontSize: '14px'}}>Joanna is the first person I think of as
                                she
                                has excellent leadership skills</h4> and can hit the ground running.
                                She has <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                            style={{fontWeight: 'bold', fontSize: '14px'}}>empowered our middle
                                managers</h4> to recognise and <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                                   style={{
                                                                       fontWeight: 'bold',
                                                                       fontSize: '14px'
                                                                   }}>develop their emotional intelligence.</h4>
                                As a result they have had <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                              style={{fontWeight: 'bold', fontSize: '14px'}}>greater
                                empathy and demonstrated improved self-awareness</h4>, which
                                has been fundamental in leading their own teams.
                                One of the key things Joanna worked on with leaders and managers was <h4
                                className='CormorantGaramondWithoutSizeAndSpacing'
                                style={{fontWeight: 'bold', fontSize: '14px'}}>developing personal
                                growth.</h4>
                                She was able to <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                    style={{fontWeight: 'bold', fontSize: '14px'}}>help managers become
                                more self-aware</h4> and reflective, working with
                                individuals so they could identify the leadership skills they needed to improve and then
                                <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                    style={{fontWeight: 'bold', fontSize: '14px'}}>guided them to set their own
                                    goals.</h4>
                                This has resulted in clear ownership of professional development and <h4
                                className='CormorantGaramondWithoutSizeAndSpacing'
                                style={{fontWeight: 'bold', fontSize: '14px'}}>improvement in
                                performance</h4> over extended time.
                                Joanna also coached leaders in preparation for our last Ofsted Inspection which
                                strengthened
                                our approach and helped us to <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                                  style={{fontWeight: 'bold', fontSize: '14px'}}>achieve
                                a positive outcome.</h4>"
                                <br/>
                            </div>
                        </Fade>
                    </p>
                    <Fade left >
                        <div>
                            <p className='CormorantGaramondWithoutSizeAndSpacing'>
                                <br/>
                                <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                    style={{fontWeight: 'bold', fontSize: '14px'}}> Philippa
                                    Firth, Assistant
                                    Principal – Adults and HE
                                    Kirklees College, Huddersfield
                                    June 2020 </h4>
                            </p>
                            <br/>
                        </div>
                    </Fade>
                </CardText>
            </Card>
        )
    }
}

export default LeadershipAndManagementTestimonialsTwo;