import React from "react";
import {Button, Card, CardText} from "reactstrap";
import {Parallax} from "react-parallax";
import {Col, Grid} from "gymnast";
import {CardImg} from "react-bootstrap";
import woman2 from "../resources/images/steps.jpg";
import scales from "../resources/images/blue-scales-fade.png";
import Fade from "react-reveal";

const boxStyleGrey = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...boxStyleGrey, minHeight: '20vw', align: "top"},
    TALL: {...boxStyleGrey, minHeight: '50vw'},
    XTALL: {...boxStyleGrey, minHeight: '100vw', align: "top"},
    SMALL: {...boxStyleGrey, minHeight: 100},
    XSMALL: {...boxStyleGrey, minHeight: 75},
    XXSMALL: {...boxStyleGrey, minHeight: 30, maxHeight: 50},
};

class TypesOfCoachingHomePage extends React.Component {
    render() {
        return (
            <Parallax bgImage={scales} strength={500}>
                <Card className='border-0 no-background '>
                    <Grid marginTop="S" paddingTop="0">
                        <Col  style={style.SMALL}/>

                        <Col size={1} style={style.XSMALL}/>
                        <Col size={10} style={style.XSMALL}>
                        <Card className=' border-0 no-background'>
                            <br/>
                            <h3 className='playfair_Display '
                                style={{fontSize: '36px', color: "black", fontWeight: 'bold'}}>
                                <Fade left>
                                    <div>
                                Coaching For Individuals
                                    </div>
                                </Fade>
                            </h3>
                            <Fade right >
                                <div>
                            <CardText>
                                <p className='CormorantGaramondWithoutSizeAndSpacing'
                                   style={{fontSize: '24px', color: "black"}}>Whether you want to stay in your role and
                                    learn how to excel, or whether you are ready to explore alternative paths, I use my
                                    25 years of management experience to guide you every step of the way.
                                    I understand the challenges you face because I’ve been there.
                                </p>
                                <br/>
                            </CardText>
                                </div>
                            </Fade>
                        </Card>
                        </Col>
                            <Col size={1} style={style.XSMALL}/>

                            <Col size={2} style={style.SMALL}/>
                            <Col size={8} style={style.SMALL}>
                            <Card className=' border-0 no-background'>
                            <CardText style={{textAlign: 'left'}}>
                                <p className='CormorantGaramondWithoutSizeAndSpacing'
                                   style={{fontSize: '24px', color: "black", fontWeight: 'bold', textIndent: '1%'}}>
                                    <h4 style={{
                                        fontWeight: 'bold',
                                        fontSize: '24px',
                                        fontFamily: 'CormorantGaramondWithoutSizeAndSpacing'
                                    }}>
                                        <Fade left>
                                            <div>
                                        I coach leaders like you to:
                                            </div>
                                        </Fade>
                                    </h4>
                                </p>
                                <Fade right >
                                    <div>
                                <ul className='CormorantGaramondWithoutSizeAndSpacing'
                                    style={{
                                        fontSize: '24px',
                                        color: "black",
                                        listStyleType: 'initial',
                                        listStylePosition: 'inside'
                                    }}>
                                    <li>Feel <h4 style={{
                                        fontWeight: 'bold',
                                        fontSize: '24px',
                                        fontFamily: 'CormorantGaramondWithoutSizeAndSpacing'
                                    }}> motivated, energised </h4> and ready to lead again
                                    </li>
                                    <li>Find your <h4 style={{
                                        fontWeight: 'bold',
                                        fontSize: '24px',
                                        fontFamily: 'CormorantGaramondWithoutSizeAndSpacing'
                                    }}> perfect work life balance </h4></li>
                                    <li>Manage your time , so you can <h4 style={{
                                        fontWeight: 'bold',
                                        fontSize: '24px',
                                        fontFamily: 'CormorantGaramondWithoutSizeAndSpacing'
                                    }}> reclaim your weekends </h4> and switch off during
                                        holidays
                                    </li>
                                    <li>Organise your workload to become more productive, <h4 style={{
                                        fontWeight: 'bold',
                                        fontSize: '24px',
                                        fontFamily: 'CormorantGaramondWithoutSizeAndSpacing'
                                    }}> so you feel in control </h4> rather
                                        than perpetually sinking
                                    </li>
                                    <li>Build the resilience and mental strength you need to manage your team and finish
                                        your day <h4 style={{
                                            fontWeight: 'bold',
                                            fontSize: '24px',
                                            fontFamily: 'CormorantGaramondWithoutSizeAndSpacing'
                                        }}> feeling fulfilled </h4>
                                    </li>
                                    <li>Say goodbye to the <h4 style={{
                                        fontWeight: 'bold',
                                        fontSize: '24px',
                                        fontFamily: 'CormorantGaramondWithoutSizeAndSpacing'
                                    }}> threat of burnout </h4></li>
                                    <li>And finally <h4 style={{
                                        fontWeight: 'bold',
                                        fontSize: '24px',
                                        fontFamily: 'CormorantGaramondWithoutSizeAndSpacing'
                                    }}> fall in love with your career </h4> again – whether that’s through staying
                                        in your role or moving on
                                    </li>
                                </ul>
                                    </div>
                                </Fade>
                                <br/>
                            </CardText>
                        </Card>
                            </Col>
                            <Col size={2} style={style.SMALL}/>
                            <Col style={style.SMALL}/>
                            <Col style={style.SMALL}/>


                        <Col size={1} style={style.XSMALL}/>
                        <Col size={10} style={style.XSMALL}>
                        <Card className='width100 border-0 no-background '>
                            <br/>
                            <h3 className='playfair_Display '
                                style={{fontSize: '34px', color: "black", fontWeight: 'bold'}}>
                                <Fade left >
                                    <div>
                                Leadership Coaching And Training For Organisations
                                    </div>
                                </Fade>
                            </h3>
                        </Card>
                        </Col>
                        <Col size={1} style={style.XSMALL}/>

                        <Col size={2} style={style.XSMALL}/>
                        <Col size={8} style={style.XSMALL}>
                        <Card className='width100 border-0 no-background' >
                            <CardText style={{textAlign: 'left'}}>
                                <p className='CormorantGaramondWithoutSizeAndSpacing'
                                   style={{fontSize: '24px', color: "black", fontWeight: 'bold', textIndent: '1%'}}>
                                    <h4 style={{
                                        fontWeight: 'bold',
                                        fontSize: '24px',
                                        fontFamily: 'CormorantGaramondWithoutSizeAndSpacing'
                                    }}>
                                        <Fade right >
                                            <div>
                                        I also provide leadership coaching and training for organisations, to help your
                                        leaders:
                                            </div>
                                        </Fade>
                                    </h4>
                                </p>
                                <Fade left >
                                    <div>
                                <ul className='CormorantGaramondWithoutSizeAndSpacing'
                                    style={{
                                        fontSize: '24px',
                                        color: "black",
                                        listStyleType: 'initial',
                                        listStylePosition: 'inside'
                                    }}>
                                    <li>Stand out and lead with confidence</li>
                                    <li>Become excellent communicators – to keep your workplace thriving</li>
                                    <li>Lead with empathy and emotional intelligence</li>
                                    <li>Motivate their teams to work more efficiently</li>
                                    <li>Deliver outstanding results</li>
                                </ul>
                                    </div>
                                </Fade>
                            </CardText>
                        </Card>
                        </Col>
                            <Col size={2} style={style.XSMALL}/>

                        <Card className=' border-0 no-background'>
                            <Grid marginTop="S" paddingTop="0">
                                <Col size={2} style={style.XSMALL}/>
                                <Col size={4} style={style.XXSMALL}>
                                    <Card className='no-background border-0 margin-left'>
                                        <CardImg src={woman2} style={{width: '90%', height: '80%', objectFit: "cover"}}/>
                                    </Card>
                                </Col>

                                <Col size={4} style={style.XSMALL}>
                                    <Card className='no-background border-0 '>
                                        <br/>
                                        <CardText >
                                            <Fade right >
                                                <div>
                                            <p className='CormorantGaramondWithoutSizeAndSpacing'
                                               style={{fontSize: '28px', color: "black"}}>I offer a range of unique
                                                coaching
                                                packages and programs, or the option of a bespoke coaching package to
                                                suit
                                                your
                                                exact needs.
                                            </p>
                                                </div>
                                            </Fade>
                                            <br/>
                                            <Fade bottom >
                                                <div>
                                            <p className='CormorantGaramondWithoutSizeAndSpacing'
                                               style={{
                                                   fontSize: '24px',
                                                   color: "black",
                                                   fontWeight: 'bold',
                                                   textIndent: '1%'
                                               }}>
                                                <h2 className='CormorantGaramondWithoutSizeAndSpacing '
                                                    style={{fontSize: '24px', color: "black", fontWeight: 'bold'}}>
                                                    Head to my Services page now to explore the ways we can work
                                                    together.
                                                </h2>
                                            </p>
                                                </div>
                                            </Fade>
                                            <Button to="/">
                                            <a className='CormorantGaramondWithoutSizeAndSpacing btn'
                                                    href='/services'
                                                    style={{
                                                color: '#000000',
                                                backgroundColor: '#F9EBEA',
                                                borderRadius: '10px',
                                                border: '0',
                                                fontSize: '26px',
                                                fontWeight: 'bold'
                                            }} >I'm Ready To Find Out More</a>
                                            </Button>
                                        </CardText>
                                    </Card>
                                </Col>
                                <Col size={1} style={style.XSMALL}/>
                                <Col size={12} style={style.XSMALL}/>

                            </Grid>
                        </Card>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </Grid>
                </Card>
                </Parallax>
        )
    }
}

export default TypesOfCoachingHomePage;