import React from "react";
import {Card, CardText} from "reactstrap";
import {Col, Grid} from "gymnast";
import CustomButton from "./OneceHubBookingButton";
import honeycomb from "../resources/images/yellow-stars.png";
import LeadershipAndManagementTestimonialsOne from "./LeadershipAndManagementTestimonialsOne";
import marble from "../resources/images/marble.png";
import {Parallax} from "react-parallax";
import LeadershipAndManagementTestimonialsTwo from "./LeadershipAndManagementTestimonialsTwo";
import Fade from "react-reveal";

const boxStyleGrey = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...boxStyleGrey, minHeight: '20vw', align: "top"},
    TALL: {...boxStyleGrey, minHeight: '50vw'},
    XTALL: {...boxStyleGrey, minHeight: '100vw', align: "top"},
    SMALL: {...boxStyleGrey, minHeight: 100},
    MEDIUM_WHITE: {...boxStyleGrey, minHeight: 300},
    XSMALL: {...boxStyleGrey, minHeight: 30},
};

class CoachingPackagesForOrganisations extends React.Component {
    render() {
        return (
            <Card className='no-background width100 border-0 '>
                <Grid marginTop="S" paddingTop="0">
                    <Parallax bgImage={marble} strength={500}>
                        <Col style={style.SMALL}/>
                        <Col style={{minHeight: '20vw', align: "top", backgroundColor: 'white'}}>
                            <Col size={6} style={style.MEDIUM}>
                                <Card className=' width100 border-0' style={{
                                    backgroundColor: '#FFFFFF',
                                    marginLeft: '10px',
                                    marginRight: '10px',
                                    width: '100%'
                                }}>
                                    <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                                        fontSize: '28px',
                                        color: "black", textAlign: "left",
                                        width: "90%",
                                    }}>
                                        <br/>
                                        <h4 className='playfair_Display' style={{fontSize: '50px'}}>One-To-One Coaching
                                            For Your Leaders</h4>
                                    </CardText>
                                    <CardText className="playfair_Display margin-left " style={{
                                        fontSize: '28px',
                                        color: "black", textAlign: "left",
                                        width: "90%",
                                    }}>
                                        <CustomButton color='#85B2EE' text="I Want To Discuss This Option"/>
                                        <br/>
                                    </CardText>
                                </Card>
                            </Col>
                            <Col size={6} style={style.MEDIUM}>
                                <Card className=' width100 border-0 center'
                                      style={{
                                          backgroundColor: '#FFFFFF',
                                          marginLeft: '10px',
                                          marginRight: '10px',
                                          width: '100%'
                                      }}>
                                    <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                                        fontSize: '24px',
                                        color: "black", textAlign: "left",
                                        width: "90%",
                                    }}>
                                        <br/>
                                        <Fade right >
                                            <div>
                                                <ul>
                                                    <li style={{fontSize: '30px'}}>To inspire them to <h4
                                                        className='CormorantGaramondWithoutSizeAndSpacing'
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '28px'
                                                        }}>perform to their best</h4></li>
                                                    <li style={{fontSize: '30px'}}>To achieve <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                                                                  style={{
                                                                                                      fontWeight: 'bold',
                                                                                                      fontSize: '28px'
                                                                                                  }}>high-quality
                                                        results
                                                    </h4></li>
                                                    <li style={{fontSize: '30px'}}>To empower them to <h4
                                                        className='CormorantGaramondWithoutSizeAndSpacing'
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '28px'
                                                        }}>lead with confidence</h4>, clarity, and wisdom
                                                    </li>
                                                </ul>
                                            </div>
                                        </Fade>
                                    </CardText>
                                    <br/>
                                </Card>
                            </Col>
                        </Col>
                        <Col style={style.SMALL}/>
                    </Parallax>


                    <Col style={{minHeight: '20vw', align: "top", backgroundColor: 'white'}}>
                        <Col size={6} style={style.MEDIUM}>
                            <Card className=' width100 border-0 center'
                                  style={{
                                      backgroundColor: '#FFFFFF',
                                      marginLeft: '10px',
                                      marginRight: '10px',
                                      width: '100%'
                                  }}>
                                <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                                    fontSize: '24px',
                                    color: "black", textAlign: "left",
                                    width: "90%",
                                }}>
                                    <br/>
                                    <Fade left >
                                        <div>
                                            <ul>
                                                <li style={{fontSize: '30px'}}>To develop the soft skills that are now
                                                    essential for running a <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                                                style={{
                                                                                    fontWeight: 'bold',
                                                                                    fontSize: '28px'
                                                                                }}>motivated and efficient
                                                        team</h4> (including communication skills, time management,
                                                    listening, empathy…)
                                                </li>
                                                <br/>
                                                <li style={{fontSize: '30px'}}>To create leaders with high emotional
                                                    intelligence and self-awareness – to <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                                                             style={{
                                                                                                 fontWeight: 'bold',
                                                                                                 fontSize: '28px'
                                                                                             }}>bring out the best in
                                                        their people
                                                    </h4></li>
                                                <br/>
                                                <li style={{fontSize: '30px'}}>To establish inspirational leaders who
                                                    are <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                            style={{
                                                                fontWeight: 'bold',
                                                                fontSize: '28px'
                                                            }}>skilled and equipped to coach the future leaders</h4> of
                                                    your organisation
                                                </li>
                                            </ul>
                                        </div>
                                    </Fade>
                                </CardText>
                            </Card>
                        </Col>
                        <Col size={6} style={style.MEDIUM}>
                            <Card className=' width100 border-0 center' style={{
                                backgroundColor: '#FFFFFF',
                                marginLeft: '10px',
                                marginRight: '10px',
                                width: '100%'
                            }}>
                                <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                                    fontSize: '28px',
                                    color: "black", textAlign: "left",
                                    width: "90%",
                                }}>
                                    <br/>
                                    <h4 className='playfair_Display' style={{fontSize: '60px'}}> Team Training In
                                        Leadership</h4>
                                </CardText>
                                <CardText className="playfair_Display margin-left " style={{
                                    fontSize: '28px',
                                    color: "black", textAlign: "left",
                                    width: "90%",
                                }}>
                                    <CustomButton color='#85B2EE' text="I Want To Discuss This Option"/>
                                    <br/>
                                </CardText>
                            </Card>
                        </Col>
                    </Col>

                    <Card className=' width100 border-0 center' style={{
                        backgroundColor: '#FFFFFF',
                        marginLeft: '10px',
                        marginRight: '10px',
                        width: '100%'
                    }}>
                        <Parallax bgImage={honeycomb} strength={500}>
                            <LeadershipAndManagementTestimonialsOne/>
                        </Parallax>
                    </Card>
                    <Col style={{minHeight: '20vw', align: "top", backgroundColor: 'white'}}>
                        <Col size={6} style={style.MEDIUM}>
                            <Card className=' width100 border-0 center' style={{
                                backgroundColor: '#FFFFFF',
                                marginLeft: '10px',
                                marginRight: '10px',
                                width: '100%'
                            }}>
                                <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                                    fontSize: '28px',
                                    color: "black", textAlign: "left",
                                    width: "90%",
                                }}>
                                    <br/>
                                    <h4 className='playfair_Display' style={{fontSize: '50px'}}>Workshops/Training/
                                        Speaking: </h4>
                                    <ul>
                                        <li style={{fontSize: '30px'}}>To explore a <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                                                        style={{
                                                                                            fontWeight: 'bold',
                                                                                            fontSize: '28px'
                                                                                        }}>leadership, coaching or team
                                            building</h4> topic of your choice
                                        </li>
                                    </ul>
                                </CardText>
                            </Card>
                        </Col>
                        <Col size={6} style={style.MEDIUM}>
                            <Card className=' width100 border-0 center'
                                  style={{
                                      backgroundColor: '#FFFFFF',
                                      marginLeft: '10px',
                                      marginRight: '10px',
                                      width: '100%'
                                  }}>
                                <Fade right >
                                    <div>
                                        <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                                            fontSize: '30px',
                                            color: "black", textAlign: "left",
                                            width: "90%",
                                        }}>
                                            <br/>
                                            These are powerful, energetic days to inspire real change in your people.
                                            They will leave your workforce feeling more motivated, energised and
                                            positive about
                                            their role in your organisation.
                                            <br/>
                                            <br/>
                                            <p style={{fontSize: '30px'}}>And a happy team <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                                                               style={{
                                                                                                   fontWeight: 'bold',
                                                                                                   fontSize: '28px'
                                                                                               }}>works harder, smarter,
                                                and is
                                                dedicated</h4> to staying onboard.
                                            </p>
                                        </CardText>
                                        <CardText className="playfair_Display margin-left " style={{
                                            fontSize: '28px',
                                            color: "black", textAlign: "left",
                                            width: "90%",
                                        }}>
                                            <CustomButton color='#85B2EE' text="I Want To Discuss This Option"/>
                                            <br/>
                                        </CardText>
                                    </div>
                                </Fade>
                                <br/>
                            </Card>
                        </Col>
                    </Col>

                    <Card className=' width100 border-0 center' style={{
                        backgroundColor: '#FFFFFF',
                        marginLeft: '10px',
                        marginRight: '10px',
                        width: '100%'
                    }}>
                        <Parallax bgImage={honeycomb} strength={500}>
                            <LeadershipAndManagementTestimonialsTwo/>
                        </Parallax>
                    </Card>
                </Grid>
            </Card>
        )


    }
}

export default CoachingPackagesForOrganisations;