import React from "react";
import {Card, CardText} from "reactstrap";
import Fade from "react-reveal";

class AboutMeIntro extends React.Component {
    render() {
        return (
            <Card className='width100 border-0 ' style={{color: "black", backgroundColor: 'white'}}>
                <br/>
                <Card className='width100 border-0 ' style={{color: "black", backgroundColor: '#f5f3f2'}}>
                    <Fade right big >
                        <div>
                            <CardText className="playfair_Display " style={{
                                fontSize: '14px',
                                color: "black", textAlign: "left", paddingLeft: '6px'
                            }}>
                                <br/>

                                <p style={{fontSize: '14px'}}>
                                    I’m Joanna Stokes, leadership and career coach based in glorious Marske-by-the-Sea,
                                    in North Yorkshire.
                                </p>
                                <br/>
                                <p style={{fontSize: '14px'}}>
                                    If you’re ready to <h4
                                    className='playfair_Display'
                                    style={{fontWeight: 'bold', fontSize: '14px'}}> take a deep breath and embrace the
                                    possibility of a happier working life, </h4>
                                    come and dive into my journey.
                                </p>
                                <br/>
                                <p style={{fontSize: '14px'}}>It might just inspire you…
                                </p>

                                <br/>
                            </CardText>
                        </div>
                    </Fade>
                </Card>
            </Card>
        )


    }
}

export default AboutMeIntro;