import React from "react";
import {Card, CardImg, CardText} from "reactstrap";
import {Col, Grid, GymnastProvider} from "gymnast";
import sun from "../../resources/images/light_sun.jpeg";
import Fade from "react-reveal";

const transparent = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...transparent, minHeight: 500, align: "top"},
    TALL: {...transparent, minHeight: 1600},
    XTALL: {...transparent, minHeight: 5000, align: "top"},
    SMALL: {...transparent, minHeight: 100},
    SMALL_WHITE: {...transparent, minHeight: 100},
    SMALL_GREY: {...transparent, minHeight: 100},
    MEDIUM_WHITE: {...transparent, minHeight: 300},
    XSMALL: {...transparent, minHeight: 75}
};

class Testimonial1 extends React.Component {
    render() {
        return (
            <Card className='width100 border-0 h-100 '
                  style={{fontSize: '12px', color: "black", backgroundColor: '#F9EBEA'}}>
                <GymnastProvider>
                    <Grid marginTop="S" paddingTop="M">
                        <Col size={6} style={style.SMALL}>
                            <Card className='width100 border-0 h-100 no-background '
                                  style={{fontSize: '11px', color: "black"}}>
                                <CardText>
                                    <br/>
                                    <Fade left >
                                        <div>
                                            <p className='ibarra'> "Now I am
                                                happier and more connected in my current job..." </p>
                                            <br/>
                                            <p className='ibarra' style={{fontWeight: 'bold', fontSize: '9px'}}> M.E,
                                                Manchester April 2020 </p>
                                            <a className='CormorantGaramondWithoutSize' style={{fontSize: '9px'}}
                                               target='blank'
                                               href="/testimonialOne"> Read More </a>
                                            <br/>
                                        </div>
                                    </Fade>
                                </CardText>
                            </Card>
                        </Col>
                        <Col size={6} style={style.SMALL}>
                            <Card className='h-150 border-0 width100 no-background '>
                                <CardImg src={sun}
                                         style={{borderRadius: '10px', alignSelf: 'center', marginTop: '20px'}}
                                         className='card-img '/>
                            </Card>
                        </Col>
                    </Grid>
                </GymnastProvider>
            </Card>
        )
    }
}

export default Testimonial1;