import React from "react";
import {Card, CardText} from "reactstrap";
import {Col, Grid, GymnastProvider} from "gymnast";
import image from "../../../resources/images/presentation.jpg";
import Fade from "react-reveal";


const translucent = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    TALL: {...translucent, minHeight: 200, align: "top"},
    MEDIUM: {...translucent, minHeight: 400},
    SMALL: {...translucent, minHeight: 50}
};

class ProgramInfoPart2New extends React.Component {
    render() {
        function FullWidthGrid() {

            return (
                <div className="background">
                    <GymnastProvider>
                        <Col style={{backgroundColor: '#FCBFBB'}}>
                            <Card className='width100 border-0' style={{backgroundColor: '#FCBFBB'}}></Card>
                        </Col>
                            <Card style={{fontSize: '14px'}} className='border-0'>
                                <Grid marginTop="S" paddingTop="0">
                                    <Col style={style.SMALL}>
                                        <Card className=' width100 border-0 d-flex justify-content-center '
                                              style={{
                                                  backgroundColor: '#FFFFFF',
                                                  marginLeft: '5px',
                                                  marginRight: '5px',
                                                  width: '100%'
                                              }}>
                                            <CardText className='ibarra'
                                                      style={{color: "black", textAlign: 'left', verticalAlign: 'center'}}>
                                                <br/>
                                                <Fade left big>
                                                    <div>
                                                        <p>Join me for this transformative
                                                            8-week journey, as you transition from career
                                                            dissatisfaction
                                                            to career fulfilment.
                                                        </p>
                                                        <br/>
                                                        <p>It's time to rekindle your joy and take meaningful steps
                                                            toward a career
                                                            that authentically aligns with who you are.</p>
                                                        <br/>
                                                        <p style={{fontWeight: 'bold', fontSize: '18px'}}> Make the decision that’s right for you – whether
                                                            to stay or go.
                                                        </p>
                                                    </div>
                                                </Fade>
                                            </CardText>
                                        </Card>
                                    </Col>
                                    <Col style={{}} className="border-0">
                                        <div className=' border-0' style={{}}>
                                            <br/>
                                            <img src={image} style={{objectFit: "cover", height: "80%", width: "100%"}}
                                                 className=""/>
                                        </div>
                                    </Col>
                                    <Col style={{backgroundColor: '#FCBFBB'}}>
                                        <Card className='width100 border-0' style={{backgroundColor: '#FCBFBB'}}></Card>
                                    </Col>
                                </Grid>
                            </Card>
                    </GymnastProvider>
                </div>


            );
        }

        return (
            <FullWidthGrid/>
        );
    }
}

export default ProgramInfoPart2New;