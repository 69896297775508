import React from "react";
import {Card, CardText} from "reactstrap";
import {Parallax} from "react-parallax";
import {Col, Grid} from "gymnast";
import {CardImg} from "react-bootstrap";
import woman2 from "../../resources/images/Philippa-Firth.jpeg";
import marble from "../../resources/images/marble.png";
import Fade from "react-reveal";


const boxStyleGrey = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...boxStyleGrey, minHeight: '20vw', align: "top"},
    TALL: {...boxStyleGrey, minHeight: '50vw'},
    XTALL: {...boxStyleGrey, minHeight: '100vw', align: "top"},
    SMALL: {...boxStyleGrey, minHeight: 100},
    XSMALL: {...boxStyleGrey, minHeight: 50},
};

class HomePageTestimonial extends React.Component {
    render() {
        return (
            <Parallax bgImage={marble} strength={500} style={{backgroundColor: '#f2f4f5'}}>
                <Card className='border-0 no-background '>
                    <CardText>
                        <br/>
                        <Card className='no-background border-0 '>
                            <Fade right >
                                <div>
                                    <CardText className=' CormorantGaramondWithoutSize'
                                              style={{fontSize: '16px'}}>
                                        A Testimonial For Joanna Stokes - North East and Yorkshire
                                    </CardText>
                                    <CardText className=' CormorantGaramondWithoutSize'
                                              style={{fontSize: '12px', fontWeight: 'bold'}}>
                                        If you want to hear what my clients are saying about me, I was over the
                                        moon with
                                        this glowing recommendation:
                                    </CardText>
                                </div>
                            </Fade>
                        </Card>
                        <br/>
                        <Card className=' border-0 center'
                              style={{backgroundColor: 'white', marginLeft: '10px', marginRight: '10px'}}>
                            <Grid marginTop="S" paddingTop="0">
                                <Col size={12} style={style.SMALL}>
                                    <CardText className='ibarra no-background' style={{
                                        fontSize: '12px',
                                        color: "black",
                                        textAlign: 'left',
                                        padding: '20px',
                                    }}>
                                        <Fade left >
                                            <div>
                                                <p>
                                                    “After being appointed to a senior leadership role I realised I
                                                    needed coaching
                                                    in order to
                                                    <h4 className='ibarra'
                                                        style={{fontWeight: 'bold', fontSize: '12px'}}> develop my
                                                        leadership skills </h4>
                                                    and to become more strategically focused.
                                                </p>
                                                <br/>
                                                <p> I have engaged with coaching in the past but was not as successful
                                                    in achieving my goals as I have been with Jo. </p>
                                                <br/>
                                                <p>
                                                    <h4 className='ibarra'
                                                        style={{fontWeight: 'bold', fontSize: '12px'}}> Jo helps you to
                                                        identify your issues </h4>
                                                    along with pushing you to find your own solutions.
                                                </p>

                                                <p> Although I found the sessions challenging, they really broadened my
                                                    thinking and resulted in
                                                    <h4 className='ibarra'
                                                        style={{fontWeight: 'bold', fontSize: '12px'}}> increasing my
                                                        effectiveness </h4>
                                                    as a senior leader in education.
                                                </p>
                                                <br/>
                                                <p> Jo has
                                                    <h4 className='ibarra'
                                                        style={{fontWeight: 'bold', fontSize: '12px'}}> empowered me to
                                                        develop my leadership skills </h4>
                                                    and given me the right tools and techniques to further improve going
                                                    forward.
                                                </p>
                                                <br/>
                                                <p> I am increasingly
                                                    <h4 className='ibarra'
                                                        style={{fontWeight: 'bold', fontSize: '12px'}}> gaining
                                                        confidence in my new role </h4>
                                                    and know, with further sessions, I can be both successful and happy.
                                                </p>
                                                <br/>
                                                <p> In summary, my coaching sessions with Jo have been brilliant and
                                                    <h4 className='ibarra'
                                                        style={{fontWeight: 'bold', fontSize: '12px'}}> I would
                                                        recommend Jo to anyone </h4>
                                                    wanting to realise their potential!”
                                                </p>
                                            </div>
                                        </Fade>
                                    </CardText>
                                </Col>

                                <Col size={6} style={style.SMALL}>
                                    <Card className='no-background border-0 h-150'
                                          style={{width: '100%'}}>
                                        <CardImg src={woman2} style={{ height: '100%', width:"100%"}}/>
                                    </Card>
                                </Col>
                                <Col size={6} style={style.SMALL} className='border-0'>
                                    <Card className='no-background border-0'>
                                        <CardText className='ibarra no-background' style={{
                                            fontSize: '12px',
                                            color: "black",
                                            textAlign: 'left',
                                            padding: '20px',
                                        }}>
                                            <p className='ibarra'
                                               style={{fontWeight: 'bold', wordBreak: 'break-word'}}> Philippa Firth,
                                                Huddersfield May
                                                2020 </p>
                                        </CardText>
                                    </Card>
                                </Col>
                            </Grid>
                        </Card>
                        <br/>
                        <br/>
                        <Card className='no-background border-0 '>
                            <Fade left >
                                <div>
                                    <CardText className=' CormorantGaramondWithoutSize'
                                              style={{fontSize: '14px'}}>
                                        I loved working with this coaching client from Huddersfield – but now my
                                        business is
                                        online, I work with clients everywhere. From Newcastle, to Middlesbrough, to
                                        York…
                                    </CardText>
                                </div>
                            </Fade>
                            <Fade right >
                                <div>
                                    <CardText className=' CormorantGaramondWithoutSize'
                                              style={{fontSize: '14px'}}>
                                        I’m a leadership and career coach for the North East of England and beyond.
                                    </CardText>
                                    <br/>
                                    <br/>
                                    <CardText className=' CormorantGaramondWithoutSize'
                                              style={{fontSize: '14px', fontWeight: 'bold'}}>
                                        If you want more examples of what my clients and colleagues say about working
                                        with me,
                                        you’re welcome to delve into my
                                        <a className='CormorantGaramondWithoutSize'
                                           style={{fontSize: '14px', fontWeight: 'bold'}} target='blank'
                                           href="/testimonials"> Testimonials page. </a>
                                    </CardText>
                                </div>
                            </Fade>
                        </Card>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </CardText>
                </Card>
            </Parallax>
        )
    }
}

export default HomePageTestimonial;