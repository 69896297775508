import React from 'react';
import {Nav, Navbar, NavDropdown, Tooltip} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/nav.css'
import logo from '../resources/images/logo.png';

class MyNavBar extends React.Component {
    constructor(props) {
        super(props);
        this.renderTooltip = this.renderTooltip.bind(this);
    }

    renderTooltip(props) {
        return <Tooltip {...props}>Take the quiz today</Tooltip>;
    }

    render() {
        return (
            <Navbar className="nav-width" sticky="top" bg="white" expand="lg">
                <Navbar.Brand href="/">
                    <img
                        src={logo}
                        className="logo align-top"
                        alt="Joanna Stokes | Coach | Mentor | Consultant | My Coach Joanna"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav" style={{paddingLeft: '10px'}}>
                    <Nav className='m-auto'>
                        <Nav.Link activeClassName='active' className="nav" href="/">HOME</Nav.Link>
                        <Nav.Link className="nav" href="/about-me">ABOUT</Nav.Link>
                        <Nav.Link className="nav" href="/services">SERVICES</Nav.Link>
                        <Nav.Link className="nav" href="/blog">BLOG</Nav.Link>
                        <Nav.Link className="nav" href="/testimonials">TESTIMONIALS</Nav.Link>
                        <NavDropdown className="nav" title="CONTACT" id="basic-nav-dropdown">
                            <NavDropdown.Item  className="ibarra" target='blank' href="https://dashboard.mailerlite.com/forms/372256/83155569631823638/share">Join Mailing List</NavDropdown.Item>
                            <NavDropdown.Item className="ibarra" href="">Tel: 07948 611082</NavDropdown.Item>
                            <NavDropdown.Item className="ibarra" href="mailto:joanna@mycoachjoanna.co.uk">joanna@mycoachjoanna.co.uk</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
    );
    }
    }


    export default MyNavBar;
