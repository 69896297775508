import React from 'react';
import {Card, CardText,} from 'reactstrap';
import {Col, Grid} from "gymnast";
import '../../css/Accordion.css';
import {Fade} from "react-reveal";

function Testimonials() {
    return (
        <Grid marginTop="S" paddingTop="0">
            <Col>
                <Card className='width100 border-0' style={{backgroundColor: '#77E1D0'}}>
                    <br/>
                    <h4 className='playfair_Display' style={{fontWeight: 'bold', fontSize: '28px'}}>What my clients say
                    </h4>
                    <br/>
                </Card>
            </Col>
            <Col>
            <Card className='width100'
                  style={{
                      fontSize: '22px',
                      color: "black",
                      backgroundColor: '#f5f3f2',
                  }}>
                <CardText className='ibarra' style={{textAlign: 'left', padding: '10px'}}>
                    <Fade right >
                        <div>
                            <p>"I decided to find a Career Coach to help me move with my career when feeling burnout. I chose Joanna because her experience and skills
                                are relevant to my situation. I was right, since our very
                                first consultation I felt understood and supported. Joanna took me on a self-discovery journey
                                which revealed a new set of strengths and values that I didn't consider before.</p>
                            <br/>
                            <p>Throughout this journey, I had doubts and moments of revelation which I could share with
                                Joanna in a trusted space she created for us.</p>
                            <br/>
                            <p>During this journey, Joanna helped me to achieve clarity on my career and personal goals and
                                empowered me to take actions. I definitely feel more optimistic and relaxed about my next steps.
                                I fully recommend Joanna&#39;s services and support because she cares."</p>
                            <br/>
                            <p style={{fontWeight: 'bold'}}>Barbara Muller, March 2023</p>
                        </div>
                    </Fade>
                </CardText>
            </Card>
        </Col>
            <Col >
                <Card className='width100'
                      style={{
                          fontSize: '22px',
                          color: "black",
                          backgroundColor: '#F9EBEA'
                      }}>
                    <CardText className='ibarra' style={{textAlign: 'left', padding: '10px'}}>
                        <Fade left >
                            <div>
                                <p>"Last summer I decided it was time for a change of direction in my career but was
                                    determined not just to jump into a slightly different version of what I had done for
                                    many years. I wanted to find a role which excited me, which was aligned with my
                                    values and which was a good fit for my strengths, skills and interests. I contacted
                                    Joanna.</p>
                                <br/>
                                <p>Over the course of six weeks we explored what makes me tick and what I was really
                                    looking for from my next role. Among lots of other things, we explored the types of
                                    activities that I was good at and enjoyed as well as the way I needed to manage my
                                    time in order to stay energised and focused.</p>
                                <br/>
                                <p>During my time working with Joanna, I secured my role with Amnesty International
                                    as Human Rights Education Manager. My conversations with Joanna prepared me
                                    really well for interview, not just because I got used to articulating my skills and
                                    experience but also because I knew what I was looking for in a role and why.</p>
                                <br/>
                                <p>I am now three months into my new role and I love it. I am very grateful to Joanna for
                                    her support during a time of change in my career."</p>
                                <br/>
                                <p style={{fontWeight: 'bold'}}>Dan Shercliffe, July 2023</p>
                            </div>
                        </Fade>
                    </CardText>
                </Card>
            </Col>
        </Grid>
    );
}

export default Testimonials;