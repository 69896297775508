import React from "react";
import {Card, CardText} from "reactstrap";
import CustomButton from "../OneceHubBookingButton";

class StopThinkingJustDoIt extends React.Component {
    render() {
        return (
            <Card className='width100 border-0'>
                    <CardText className='ibarraSize22 quote' style={{ fontSize: '34px', color: "black" }}>
                        Stop
                        <h4 style={{fontStyle: 'italic', fontSize: '45px', fontFamily: 'Ibarra'}}>Thinking </h4>
                       and just do it!
                    </CardText>
                <CardText className='ibarraSize22 quote' style={{ fontSize: '22px', color: "black" }}>
                    <CustomButton color='#85B2EE' text="Let's Start A Conversation"/>
                </CardText>
            </Card>
        )
    }
}

export default StopThinkingJustDoIt;