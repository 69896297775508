import React from "react";
import {Card, CardImg, CardText} from "reactstrap";
import {Col, Grid, GymnastProvider} from "gymnast";
import sun from "../../../resources/images/man_mountain.jpg";
import Fade from "react-reveal";

const transparent = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...transparent, minHeight: 500, align: "top"},
    TALL: {...transparent, minHeight: 1600},
    XTALL: {...transparent, minHeight: 5000, align: "top"},
    SMALL: {...transparent, minHeight: 100},
    SMALL_WHITE: {...transparent, minHeight: 100},
    SMALL_GREY: {...transparent, minHeight: 100},
    MEDIUM_WHITE: {...transparent, minHeight: 300},
    XSMALL: {...transparent, minHeight: 75}
};

const url = "https://www.lifecoach-directory.org.uk/memberarticles/my-top-3-steps-to-gaining-freedom-in-your-life"

class ArticleBlurb5 extends React.Component {
    render() {
        return (
            <Card onClick={() => window.open(url, '_blank')} className='width100 border-0 h-100 pointer'
                  style={{fontSize: '22px', color: "black", backgroundColor: '#F9EBEA'}}>
                <GymnastProvider>
                    <Grid marginTop="S" paddingTop="M">
                        <Col size={12} style={style.SMALL}>
                            <Card className='width100 border-0 h-100 no-background '
                                  style={{fontSize: '16px', color: "black"}}>
                                <CardText>
                                    <p className='CormorantGaramondWithoutSize' style={{textAlign: 'start', fontSize: '14px'}}>June 23 2022</p>
                                    <Fade right >
                                        <div>
                                            <p className='Oswald' style={{fontWeight: 'bold', textAlign: 'start', fontSize: '30px'}}>My top 3 steps to gaining freedom in your life</p>
                                        </div>
                                    </Fade>
                                    <br/>
                                    <Fade right >
                                        <div>
                                            <p className='ibarra' style={{ fontSize: '14px',textAlign: 'start'}}>Written by: Joanna Stokes</p>
                                            <p className='ibarra' style={{fontSize: '14px', fontWeight: 'bold', textAlign: 'start'}}>Life Coach Directory</p>
                                            <a className='CormorantGaramondWithoutSize' style={{fontSize: '12', float: 'left'}}
                                               target='blank'
                                               href={url}> Read More </a>
                                        </div>
                                    </Fade>
                                </CardText>
                            </Card>
                        </Col>
                        <Col size={12} style={style.SMALL}>
                            <Card className='border-0  no-background '>
                                <CardImg src={sun} style={{borderRadius: '10px'}} className='card-img '/>
                            </Card>
                        </Col>
                    </Grid>
                </GymnastProvider>
            </Card>
        )
    }
}

export default ArticleBlurb5;