import React from "react";
import {Card, CardImg, CardText} from "reactstrap";
import {Col, Grid} from "gymnast";
import newJob from "../../resources/images/new_job.jpeg";

const transparent = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...transparent, minHeight: 500, align: "top"},
    TALL: {...transparent, minHeight: 1600},
    XTALL: {...transparent, minHeight: 5000, align: "top"},
    SMALL: {...transparent, minHeight: 100},
    SMALL_WHITE: {...transparent, minHeight: 100},
    SMALL_GREY: {...transparent, minHeight: 100},
    MEDIUM_WHITE: {...transparent, minHeight: 300},
    XSMALL: {...transparent, minHeight: 75}
};

class Testimonial2 extends React.Component {
    render() {
        return (
            <Card className='width100 border-0 h-100 '
                  style={{fontSize: '12px', color: "black", backgroundColor: '#f5f3f2'}}>
            <Grid marginTop="S" paddingTop="M">
                <Col size={6} style={style.SMALL}>
                    <Card className='h-150 border-0 width100 no-background'>
                        <CardImg src={newJob} style={{borderRadius: '10px',  alignSelf: 'center', marginTop:'20px'}} className='card-img '/>
                    </Card>
                </Col>
                <Col size={6} style={style.SMALL}>
                    <Card className='width100 border-0 h-100 no-background' style={{fontSize: '11px', color: "black"}}>
                        <CardText>
                            <br/>
                            <p className='ibarra' > "I am increasingly gaining confidence in my new role..." </p>
                            <br/>
                            <p className='ibarra' style={{fontWeight: 'bold', fontSize:'9px'}}> P.F, Huddersfield May 2020 </p>
                            <a className='CormorantGaramondWithoutSize' style={{fontSize: '9'}} target='blank' href="/testimonialTwo"> Read More </a>
                            <br/>
                        </CardText>
                    </Card>
                </Col>
            </Grid>
            </Card>
        )
    }
}

export default Testimonial2;