import React from "react";
import {Card, CardText} from "reactstrap";
import Fade from "react-reveal";
import {Col, Grid} from "gymnast";


class ProgramInfoPart1New extends React.Component {
    render() {
        return (
            <Grid marginTop="S" paddingTop="0">
                <Col style={{ backgroundColor: '#EAF8F9'}}>
                        <Card className='width100 border-0' style={{backgroundColor: '#EAF8F9'}}>
                            <CardText className="ibarra " style={{
                                fontSize: '14px',
                                color: "black", textAlign: "left",
                            }}>
                                <br/>
                                <Fade left big>
                                    <div>
                                        <h4 className='ibarra'
                                            style={{fontWeight: 'bold', fontSize: '20px'}}>Discover your path
                                        </h4>
                                    </div>
                                </Fade>
                                <br/>
                                <Fade right big>
                                    <div>
                                        <p style={{fontSize: '14px'}}>
                                            Embark on an immersive 8-week journey that helps you define your career direction. My
                                            programme provides the clarity, confidence, and control you need to make an informed decision
                                            about your future. With expert guidance, you'll embark on a transformational experience that goes
                                            beyond mere success—it's about discovering your true purpose and aligning it with your career.
                                        </p>
                                    </div>
                                </Fade>
                                <br/>
                            </CardText>
                        </Card>
                </Col>

                <Col style={{ backgroundColor: '#f5f3f2'}}>
                    <Card className='width100 border-0' style={{backgroundColor: '#f5f3f2'}}>
                        <CardText className="ibarra " style={{
                            fontSize: '14px',
                            color: "black", textAlign: "left",
                        }}>
                            <br/>
                            <Fade left big>
                                <div>
                                    <h4 className='ibarra'
                                        style={{fontWeight: 'bold', fontSize: '20px'}}>EMBARK
                                    </h4>
                                </div>
                            </Fade>
                            <br/>
                            <Fade right>
                                <div>
                                    <p>
                                        Begin a profound self-discovery journey that uncovers the core of who you are. With personalised
                                        coaching and creative exercises, you'll explore your values, strengths, and behavioural styles. We'll
                                        also harness the insights from the DISC personality profile assessment tool to reveal your unique
                                        attributes. Reflect on your past achievements and current successes, extracting lessons from both.
                                    </p>
                                </div>
                            </Fade>
                            <br/>
                        <Fade right>
                                <div>
                                    <p>
                                        <h4 style={{fontWeight: 'bold', fontSize: '16px'}}>The result:</h4> <h4 style={{fontStyle: 'italic', fontSize: '18px'}}>Rediscover your authentic self</h4>, your values, strengths, interests, as well as the
                                        fears and beliefs that shape you. This newfound self-awareness will be your compass on
                                        your career path.
                                    </p>
                                </div>
                            </Fade>
                        </CardText>
                    </Card>
                </Col>

                <Col style={{ backgroundColor: '#EAF8F9'}}>
                    <Card className='width100 border-0' style={{backgroundColor: '#EAF8F9'}}>
                        <CardText className="ibarra " style={{
                            fontSize: '14px',
                            color: "black", textAlign: "left",
                        }}>
                            <br/>
                            <Fade left big>
                                <div>
                                    <h4 className='ibarra'
                                        style={{fontWeight: 'bold', fontSize: '20px'}}>UNCOVER
                                    </h4>
                                </div>
                            </Fade>
                            <br/>
                            <Fade right>
                                <div>
                                    <p>
                                        Dive deep into identifying the skills that ignite your passion. Pinpoint your key work
                                        motivators and professional accomplishments. Craft a vision of your ideal working
                                        environment, encompassing the 'who,' 'what,' and 'where.'
                                    </p>
                                </div>
                            </Fade>
                            <br/>
                            <Fade right>
                                <div>
                                    <p>
                                        <h4 style={{fontWeight: 'bold', fontSize: '16px'}}>The result:</h4> Gain unparalleled clarity on what <h4 style={{fontStyle: 'italic', fontSize: '18px'}}>your career needs</h4> to satisfy and motivate you.
                                    </p>
                                </div>
                            </Fade>
                        </CardText>
                    </Card>
                </Col>
                <Col style={{ backgroundColor: '#f5f3f2'}}>
                    <Card className='width100 border-0' style={{backgroundColor: '#f5f3f2'}}>
                        <CardText className="ibarra " style={{
                            fontSize: '14px',
                            color: "black", textAlign: "left",
                        }}>
                            <br/>
                            <Fade left big>
                                <div>
                                    <h4 className='ibarra'
                                        style={{fontWeight: 'bold', fontSize: '20px'}}>FOCUS
                                    </h4>
                                </div>
                            </Fade>
                            <br/>
                            <Fade right>
                                <div>
                                    <p>
                                        In the Focus phase, we'll blend the discoveries from the Embark and Uncover stages to distill
                                        your true career desires. Clarify your career direction and create a concrete action plan to
                                        guide you forward.
                                    </p>
                                </div>
                            </Fade>
                            <br/>
                            <Fade right>
                                <div>
                                    <p>
                                        <h4 style={{fontWeight: 'bold', fontSize: '16px'}}>The result:</h4> Attain a <h4 style={{fontStyle: 'italic', fontSize: '18px'}}>crystal-clear career direction</h4> and a focused action plan, paving the way
                                        to your career goals.
                                    </p>
                                </div>
                            </Fade>
                        </CardText>
                    </Card>
                </Col>
            </Grid>
        )
    }
}

export default ProgramInfoPart1New;