import React from "react";
import {Card, CardText} from "reactstrap";
import {Col, Grid} from "gymnast";
import scales from "../../resources/images/blue-scales-fade.png";
import {Parallax} from "react-parallax";
import CustomButton from "../OneceHubBookingButton";
import Fade from "react-reveal";

const boxStyleGrey = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...boxStyleGrey, minHeight: '20vw', align: "top"},
    TALL: {...boxStyleGrey, minHeight: '50vw'},
    XTALL: {...boxStyleGrey, minHeight: '100vw', align: "top"},
    SMALL: {...boxStyleGrey, minHeight: 100},
    MEDIUM_WHITE: {...boxStyleGrey, minHeight: 300},
    XSMALL: {...boxStyleGrey, minHeight: 30},
};

class ServicesForOrganisationsBulletPoints extends React.Component {
    render() {
        return (
            <Parallax bgImage={scales} strength={500}>
                <Card className='width100 border-0 no-background ' style={{color: "black"}}>
                    <Grid marginTop="S" paddingTop="0">
                        <Col size={12} style={style.MEDIUM}>
                            <Card className='width100 border-0 no-background ' style={{color: "black"}}>
                                <CardText className="CormorantGaramondWithoutSizeAndSpacing " style={{
                                    fontSize: '16px',
                                    color: "black", textAlign: "left"
                                }}>
                                    <br/>
                                    <ul>
                                        <Fade left >
                                            <div>
                                                <li style={{fontSize: '16px'}}>Raise their self-awareness – meaning <h4
                                                    className='CormorantGaramondWithoutSizeAndSpacing'
                                                    style={{fontWeight: 'bold', fontSize: '16px'}}>improved
                                                    relationships and outstanding results
                                                </h4>
                                                </li>
                                                <br/>
                                                <li style={{fontSize: '16px'}}>Develop their emotional intelligence – so
                                                    they
                                                    can <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                            style={{fontWeight: 'bold', fontSize: '16px'}}>get the best
                                                        out of
                                                        their teams
                                                    </h4>
                                                </li>
                                                <br/>
                                                <li style={{fontSize: '16px'}}>Become highly effective communicators –
                                                    to <h4
                                                        className='CormorantGaramondWithoutSizeAndSpacing'
                                                        style={{fontWeight: 'bold', fontSize: '16px'}}>keep morale
                                                        high</h4></li>
                                                <br/>
                                                <li style={{fontSize: '16px'}}>Have the <h4
                                                    className='CormorantGaramondWithoutSizeAndSpacing'
                                                    style={{
                                                        fontWeight: 'bold',
                                                        fontSize: '16px'
                                                    }}>ability to problem solve</h4> –
                                                    to nip issues in the bud
                                                </li>
                                                <br/>
                                                <li style={{fontSize: '16px'}}>Become the best <h4
                                                    className='CormorantGaramondWithoutSizeAndSpacing'
                                                    style={{
                                                        fontWeight: 'bold',
                                                        fontSize: '16px'
                                                    }}>high-performing, confident
                                                    and successful leaders</h4> they can be
                                                </li>
                                                <br/>
                                            </div>
                                        </Fade>
                                    </ul>
                                </CardText>
                                <Fade bottom >
                                    <div>
                                        <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left "
                                                  style={{
                                                      fontSize: '16px',
                                                      color: "black", textAlign: "left",
                                                  }}>
                                            <CustomButton color='#85B2EE' text="I'm ready to find out more"/>
                                        </CardText>
                                    </div>
                                </Fade>
                            </Card>
                        </Col>
                    </Grid>
                </Card>
            </Parallax>
        )


    }
}

export default ServicesForOrganisationsBulletPoints;