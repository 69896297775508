import React from "react";
import '../App.css';


class OnceHubPopUpWidget extends React.Component {

    loadScript = () => new Promise((resolve) => {
        if (window.EBWidgets) {
            return resolve(window.EBWidgets);
        }

        const script = document.createElement('script');
        script.id = 'scriptId';
        script.async = true;
        script.src = "https://cdn.oncehub.com/mergedjs/so.js";
        script.addEventListener('load', () => resolve(window.EBWidgets));

        document.head.appendChild(script);
    });

    async componentWillMount() {
        try {
            await this.loadScript();
        } catch (e) {
            console.log("Could not load widget")
        }
    }

    render() {
        return (
            <div id="SOIWGT_JoannaStokes" data-so-page="JoannaStokes"
                 data-wgt-style="ttl: Book a free call with me; bg: #333333; clr: #ffffff; align: right;"
                 data-height="580" data-tmp="17.40" data-pos="120"
                 data-btn-style="ttl: Schedule Online; txt: Book Your Call Now; bg: #006DAF; clr: #ffffff;"
                 data-eml-style="ttl: Email Joanna; txt: Send; bg: #006DAF; clr: #ffffff"
                 data-email="JoannaStokes(joanna@mycoachjoanna.co.uk)"
                 data-message="Thank you for your message.&lt;br /&gt;I'll be in touch with you as soon as I can.&lt;br /&gt;&lt;br /&gt;Joanna"
                 data-delay="1"/>
        );
    }
}


export default OnceHubPopUpWidget