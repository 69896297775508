import React from 'react';
import '../css/Alignment.css';
import '../css/Components.css';
import '../css/Animations.css';
import '../css/Elements.css'
import '../css/Fonts.css'
import '../css/Parallax.css'
import '../css/Size.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Grid, GymnastProvider} from "gymnast";
import MyNavBar from "../components/NavBar";
import {Card, CardText} from "reactstrap";
import LifeCoachQuote from "../components/mobile/LifeCoachQuote";
import Footer from "../components/Footer";
import {Parallax} from "react-parallax";
import CustomButton from "../components/OneceHubBookingButton";
import Fade from "react-reveal";
import HowIHelpIndividuals from "../components/mobile/HowIHelpIndividuals";
import HowIHelpOrganisations from "../components/mobile/HowIHelpOrganisations";
import ServicesForIndividuals from "../components/mobile/ServicesForIndividuals";
import pier from "../resources/images/cliffes.jpg";
import CoachingPackagesForIndividuals from "../components/mobile/CoachingPackagesForIndividuals";
import ServicesForOrganisations from "../components/mobile/ServicesForOrganisations";
import CoachingPackagesForOrganisations from "../components/mobile/CoachingPackagesForOrganisations";
import ServicesForOrganisationsBulletPoints from "../components/mobile/ServicesForOrganisationsBulletPoints";
import OnceHubPopUpWidget from "../components/OnceHubPopUpWidget";

const transparent = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...transparent, minHeight: 500},
    MEDIUM_NO_BORDER: {...transparent, minHeight: 650},
    TALL: {...transparent, minHeight: 1600},
    XTALL: {...transparent, minHeight: 5000, align: "top"},
    SMALL: {...transparent, minHeight: 100},
    MEDIUM_WHITE: {...transparent, minHeight: 300},
    XSMALL: {...transparent, minHeight: 75}
};

class ServicesPageMobile extends React.Component {
    render() {
        function FullWidthGrid() {

            return (
                <div className="background">
                    <GymnastProvider>
                        <MyNavBar/>
                        <Grid marginTop="S" paddingTop="0">
                            <Parallax bgImage={pier} strength={500}>
                                <Card className='no-background width100vw  border-0 '>
                                    <CardText className='ibarra' style={{
                                        fontSize: '38px',
                                        color: "white",
                                        fontWeight: 'bold',
                                        textAlign: 'center'
                                    }}>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <h1 style={{
                                            fontSize: '48px',
                                            fontWeight: 'bold',
                                            fontFamily: 'Ibarra'
                                        }}> Lets Unlock Your Full Career Potential</h1>
                                        <CardText className="playfair_Display " style={{
                                            fontSize: '22px',
                                            color: "black"
                                        }}>
                                            <CustomButton color='#85B2EE' text="Yes - I'm Ready. Book my free call"/>
                                        </CardText>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <br/>
                                    </CardText>
                                    <OnceHubPopUpWidget/>
                                </Card>
                            </Parallax>
                            <Card className='width100 border-0 ' style={{color: "black", backgroundColor: 'white'}}>
                                <br/>
                                <Fade bottom big>
                                    <div>
                                        <Col style={style.SMALL}>
                                            <Card className='width100 border-0' style={{backgroundColor: '#F9EBEA'}}>
                                                <CardText className='playfair_Display text-padding'
                                                          style={{fontSize: '18px', color: "black"}}>
                                                    <Fade left >
                                                        <div>
                                                            <br/>
                                                            <p className='playfair_Display'>
                                                                I work with individuals and organisations to develop
                                                                highly
                                                                successful leaders who show up as a human being first
                                                                and demonstrate inspirational leadership.
                                                            </p>
                                                            <br/>
                                                        </div>
                                                    </Fade>
                                                </CardText>
                                            </Card>
                                        </Col>
                                    </div>
                                </Fade>
                            </Card>
                            <Col style={style.MEDIUM_WHITE}>
                                <HowIHelpIndividuals/>
                            </Col>

                            <Col style={style.MEDIUM_WHITE}>
                                <HowIHelpOrganisations/>
                            </Col>

                            <a id="my_services_for_individuals"/>
                            <Col style={style.SMALL}>
                                <Card className='width100 border-0'>
                                    <CardText className='ibarraSize22' style={{fontSize: '56px', color: "black"}}>
                                        <Fade left big>
                                            <div>
                                            <h2 style={{fontSize: '30px', fontFamily: 'Ibarra'}}>My Services For
                                                Individuals </h2>
                                            </div>
                                        </Fade>
                                    </CardText>
                                </Card>
                            </Col>
                            <Col style={style.MEDIUM_WHITE}>
                                <ServicesForIndividuals/>
                            </Col>

                            <Col style={style.SMALL}>
                                <Card className='width100 border-0 no-background'>
                                    <CardText className='ibarraSize22' style={{ color: "black"}}>
                                        <Fade left big>
                                            <div>
                                            <h2 style={{fontSize: '28px', fontFamily: 'Ibarra'}}>My Coaching Packages
                                                For Individuals </h2>
                                            </div>
                                        </Fade>
                                    </CardText>
                                </Card>
                            </Col>
                            <Col style={style.SMALL_GREY}>
                                <CoachingPackagesForIndividuals/>
                            </Col>

                            <Col style={style.SMALL}>
                                <LifeCoachQuote/>
                            </Col>
                            <a id="my_services_for_organisations"/>
                            <Col style={style.SMALL}>
                                <Card className='width100 border-0'>
                                    <CardText className='ibarraSize22' style={{color: "black"}}>
                                        <Fade left big>
                                        <div>
                                            <h2 style={{fontSize: '30px', fontFamily: 'Ibarra'}}>My Services For
                                                Organisations </h2>
                                        </div>
                                        </Fade>
                                    </CardText>
                                </Card>
                            </Col>
                            <Col style={style.MEDIUM_WHITE}>
                                <ServicesForOrganisations/>
                                <Col style={style.SMALL}>
                                    <Card className='width100 border-0'>
                                        <CardText style={{color: "black", textAlign: 'left'}}>
                                            <Fade right big>
                                                <div>
                                                <br/>
                                                <h2 className='playfair_Display' style={{fontSize: '28px'}}>Through my
                                                    blend of coaching, mentoring and training, I teach your valued
                                                    leaders exactly how to:</h2>
                                                </div>
                                            </Fade>
                                        </CardText>
                                    </Card>
                                </Col>
                                <ServicesForOrganisationsBulletPoints/>
                            </Col>
                            <Col style={style.SMALL}>
                                <Card className='width100 border-0 no-background'>
                                    <CardText className='ibarraSize22' style={{fontSize: '56px', color: "black"}}>
                                        <Fade left big>
                                            <div>
                                            <h3 style={{fontSize: '30px', fontFamily: 'Ibarra'}}>Examples Of The
                                                Leadership And Management Work I Offer </h3>
                                            </div>
                                        </Fade>
                                    </CardText>
                                </Card>
                            </Col>
                            <Col style={style.MEDIUM_WHITE}>
                                <CoachingPackagesForOrganisations/>
                            </Col>
                        </Grid>
                        <Footer/>
                    </GymnastProvider>
                </div>
            );
        }
        return (
            <FullWidthGrid/>
        );
    }
}

export default ServicesPageMobile;
