import React from 'react';
import '../../css/Alignment.css';
import '../../css/Components.css';
import '../../css/Animations.css';
import '../../css/Elements.css'
import '../../css/Fonts.css'
import '../../css/Parallax.css'
import '../../css/Size.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Grid} from "gymnast";
import {Responsive} from '../../components/Responsive';
import TestimonialsPage from "./TestimonialsPage";
import TestimonialsPageMobile from "./TestimonialsPageMobile";

class TestimonialsPageSwitch extends React.Component {
    render() {
        function FullWidthGrid() {
            return (
                <Grid marginTop="S" paddingTop="0">
                        <Responsive displayIn={["Laptop"]}>
                         <TestimonialsPage/>
                        </Responsive>
                        <Responsive displayIn={["Mobile"]}>
                           <TestimonialsPageMobile/>
                        </Responsive>
                    <Responsive displayIn={["Tablet"]}>
                        <TestimonialsPage/>
                        </Responsive>
                </Grid>
                    );
                    }
                    return (
                    <FullWidthGrid/>
                    );
                    }
                    }

                    export default TestimonialsPageSwitch;
