import React from "react";
import {Card, CardText} from "reactstrap";
import '../css/Alignment.css';
import '../css/Components.css';
import '../css/Animations.css';
import '../css/Elements.css'
import '../css/Fonts.css'
import '../css/Parallax.css'
import '../css/Size.css'
import {Col, Grid, GymnastProvider} from "gymnast";
import {Parallax} from "react-parallax";
import yellow from "../resources/images/yellow-stars.png";
import {CardImg} from "react-bootstrap";
import woman2 from "../resources/images/coffee_full.jpg";
import red from "../resources/images/portrait_new.jpg";
import Fade from "react-reveal";


const translucent = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    TALL: {...translucent, minHeight: 200, align: "top"},
    MEDIUM: {...translucent, minHeight: 400},
    SMALL: {...translucent, minHeight: 50}
};

class AboutMeStory extends React.Component {
    render() {
        function FullWidthGrid() {

            return (
                <div className="background">
                    <GymnastProvider>
                        <Parallax bgImage={yellow} strength={500}>
                            <Col style={style.SMALL}/>

                            <Col style={{minHeight: '20vw', align: "top", backgroundColor: 'white'}}>
                                <Card className='border-0'>
                                    <Grid marginTop="S" paddingTop="0">
                                        <Col style={style.SMALL}/>
                                        <Col style={style.SMALL}/>
                                        <Col style={style.SMALL}/>
                                        <Col style={style.SMALL}/>
                                        <Col style={style.SMALL}/>

                                        <Col size={2} style={style.SMALL}/>
                                        <Col size={4} style={style.SMALL} className="border-0">
                                            <Card className=' width100 border-0 '
                                                  style={{
                                                      backgroundColor: '#FFFFFF',
                                                      marginLeft: '10px',
                                                      marginRight: '10px',
                                                      width: '100%'
                                                  }}>
                                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                                          style={{color: "black", textAlign: 'left'}}>
                                                    <Fade top big >
                                                        <div>
                                                            <h2 className='playfair_Display'
                                                                style={{fontSize: '40px'}}>I
                                                                Understand The
                                                                Pressures Of Leadership
                                                            </h2>
                                                        </div>
                                                    </Fade>
                                                    <Fade left big >
                                                        <div>
                                                            <p style={{fontSize: '24px'}}>I was a hotel manager by the
                                                                age of 22
                                                                – so
                                                                believe me when I tell you I understand the leadership
                                                                pressures
                                                                you
                                                                face.</p>
                                                            <p style={{fontSize: '24px'}}>(I fought my way there even
                                                                though I
                                                                was too
                                                                young to hold the licence and my employer insisted I
                                                                wore a name
                                                                badge
                                                                pretending I was married!)
                                                            </p>
                                                            <p style={{fontSize: '24px'}}>From that young age I was
                                                                already
                                                                employing
                                                                apprentices, teaching them about productivity and at the
                                                                same
                                                                time
                                                                caring about their well-being.
                                                            </p>
                                                        </div>
                                                    </Fade>
                                                </CardText>
                                            </Card>
                                            <br/>
                                            <Card className=' width100 border-0 '
                                                  style={{
                                                      backgroundColor: '#FFFFFF',
                                                      marginLeft: '10px',
                                                      marginRight: '10px',
                                                      width: '100%'
                                                  }}>
                                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                                          style={{color: "black", textAlign: 'left'}}>
                                                    <Fade right big >
                                                        <div>
                                                            <h2 className='playfair_Display'
                                                                style={{fontSize: '40px'}}>My Path
                                                                To
                                                                Leadership</h2>
                                                        </div>
                                                    </Fade>
                                                    <Fade left big >
                                                        <div>
                                                            <p style={{fontSize: '24px'}}>But I won’t pretend my 25
                                                                years in
                                                                leadership
                                                                have been easy.
                                                            </p>
                                                            <p style={{fontSize: '24px'}}>For 18 of those years I’ve
                                                                worked in
                                                                Further
                                                                Education, just like you.
                                                            </p>
                                                            <p style={{fontSize: '24px'}}>I began my Further Education
                                                                career as
                                                                a
                                                                teacher, then assessor, working my way up the curriculum
                                                                ladder
                                                                in
                                                                various organisations to Assistant Principal, Vice
                                                                Principal and
                                                                CEO.
                                                            </p>
                                                        </div>
                                                    </Fade>
                                                </CardText>
                                                <br/>
                                            </Card>
                                        </Col>

                                        <Col size={4} style={style.SMALL} className="border-0">
                                            <Card className=' border-0' style={{backgroundColor: 'rgba(0,0,0,0)'}}>
                                                <br/>
                                                <br/>
                                                <CardImg src={woman2}/>
                                            </Card>
                                        </Col>
                                        <Col size={2} style={style.SMALL}/>

                                        <Col size={12} style={style.SMALL} className="border-0">
                                            <Card className=' width100 border-0 center'
                                                  style={{
                                                      backgroundColor: '#FFFFFF',
                                                      marginLeft: '10px',
                                                      marginRight: '10px',
                                                      width: '100%'
                                                  }}>
                                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                                          style={{color: "black", textAlign: 'left', fontSize: '24px'}}>
                                                    <br/>
                                                    <Fade right big >
                                                        <div>
                                                            <p className='nixie' style={{fontSize: '64px'}}>
                                                                The desire to lead well and inspire others has always
                                                                been part
                                                                of
                                                                me...</p>
                                                        </div>
                                                    </Fade>
                                                    <Fade bottom big >
                                                        <div>
                                                            <p className='CormorantGaramondWithoutSizeAndSpacing' style={{fontSize: '22px'}}>
                                                                <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                                    style={{fontWeight: 'bold', fontSize: '26px'}}> I
                                                                    was even an Ofsted Inspector for 5 years. </h4>
                                                            </p>
                                                        </div>
                                                    </Fade>

                                                </CardText>
                                            </Card>

                                        </Col>
                                        <Col style={style.SMALL}/>
                                        <Col style={style.SMALL}/>
                                        <Col style={style.SMALL}/>
                                        <Col style={style.SMALL}/>
                                        <Col style={style.SMALL}/>

                                    </Grid>
                                </Card>
                            </Col>
                            <Col style={style.SMALL}/>
                        </Parallax>

                        <Parallax bgImage={yellow} strength={500}>
                            <Col style={{minHeight: '20vw', align: "top", backgroundColor: 'white'}}>
                                <Card className='border-0' style={{}}>
                                    <Grid marginTop="S" paddingTop="0">
                                        <Col style={style.SMALL}/>
                                        <Col style={style.SMALL}/>
                                        <Col style={style.SMALL}/>
                                        <Col style={style.SMALL}/>
                                        <Col style={style.SMALL}/>
                                        <Col size={12} style={style.SMALL}/>
                                        <Col size={2} style={style.SMALL}/>
                                        <Col size={4} style={style.SMALL} className="border-0">
                                            <Card className=' width100 border-0 '
                                                  style={{
                                                      backgroundColor: '#FFFFFF',
                                                      marginLeft: '10px',
                                                      marginRight: '10px',
                                                      width: '100%'
                                                  }}>
                                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                                          style={{color: "black", textAlign: 'left'}}>
                                                    <div>
                                                        <h2 className='playfair_Display'
                                                            style={{fontSize: '40px'}}>The
                                                            Further
                                                            Education Treadmill
                                                        </h2>
                                                    </div>
                                                    <div>
                                                        <p style={{fontSize: '24px'}}>But sometimes it felt like I
                                                            was on a
                                                            treadmill.
                                                        </p>
                                                        <p style={{fontSize: '24px'}}>I lived and breathed data,
                                                            analysed
                                                            numerous 3
                                                            year trends, carried out countless observations of
                                                            teaching and
                                                            learning…

                                                        </p>
                                                        <p style={{fontSize: '24px'}}>And I lost count of the number
                                                            of
                                                            times I
                                                            adapted to yet another change imposed on us.
                                                        </p>
                                                    </div>
                                                </CardText>
                                            </Card>
                                            <br/>
                                            <Card className=' width100 border-0 '
                                                  style={{
                                                      backgroundColor: '#FFFFFF',
                                                      marginLeft: '10px',
                                                      marginRight: '10px',
                                                      width: '100%'
                                                  }}>
                                                <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                                          style={{color: "black", textAlign: 'left'}}>
                                                    <div>
                                                        <h2 className='playfair_Display'
                                                            style={{fontSize: '40px'}}>Further
                                                            Education Leadership – The Good Days </h2>
                                                    </div>
                                                    <div>
                                                        <p style={{fontSize: '24px'}}>I worked closely with
                                                            individuals and
                                                            teams to
                                                            deliver high quality teaching, learning and assessment,
                                                            achieve
                                                            targets
                                                            and most importantly develop people to become great
                                                            leaders.

                                                        </p>
                                                        <p style={{fontSize: '24px'}}>I knew I was good at leading
                                                            people.

                                                        </p>
                                                        <p style={{fontSize: '24px'}}>My team members frequently
                                                            said I was
                                                            the best
                                                            manager they’d ever had.
                                                        </p>
                                                        <p style={{fontSize: '24px'}}>They knew I cared for them and
                                                            I
                                                            invested in
                                                            their growth.
                                                        </p>
                                                    </div>
                                                </CardText>
                                                <br/>
                                            </Card>
                                        </Col>

                                        <Col size={4} style={style.SMALL} className="border-0">
                                            <Card className=' border-0' style={{backgroundColor: 'rgba(0,0,0,0)'}}>
                                                <CardImg src={red} />
                                            </Card>
                                        </Col>
                                        <Col size={2} style={style.SMALL}/>

                                        <Fade left big >

                                            <br/>
                                            <br/>
                                            <br/>
                                        <Card className=' width100 border-0 center'
                                              style={{
                                                  backgroundColor: '#FFFFFF',
                                                  marginLeft: '10px',
                                                  marginRight: '10px',
                                                  width: '100%'
                                              }}>
                                            <br/>
                                            <br/>

                                            <CardText className='CormorantGaramondWithoutSizeAndSpacing'
                                                      style={{color: "black", textAlign: 'left'}}>
                                                    <div>
                                                        <p className='nixie' style={{fontSize: '55px'}}>
                                                            The same challenges year after year. At times I was
                                                            bored and
                                                            frustrated
                                                            with it.</p>
                                                    </div>
                                                <br/>
                                                <br/>
                                                <div>
                                                        <p className='CormorantGaramondWithoutSizeAndSpacing' style={{fontSize: '22px'}}>
                                                            Maybe you’ve experienced similar struggles
                                                            <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                                style={{fontWeight: 'bold', fontSize: '22px'}}> –
                                                                perhaps that’s why you’re reading this now? </h4>
                                                        </p>
                                                        <p style={{fontSize: '24px'}}>Yet I still believed that
                                                            education
                                                            was the
                                                            biggest change maker in life, and that Further
                                                            Education, giving
                                                            people
                                                            second, third and fourth chances, was where I wanted to
                                                            be.
                                                        </p>
                                                    </div>
                                            </CardText>
                                            <br/>
                                            <br/>
                                            <br/>
                                        </Card>
                                    </Fade>
                                    <br/>
                                        <Fade right big >

                                        </Fade>

                                        <Col style={style.SMALL}/>
                                        <Col style={style.SMALL}/>
                                        <Col style={style.SMALL}/>
                                        <Col style={style.SMALL}/>
                                        <Col style={style.SMALL}/>
                                    </Grid>
                                </Card>
                            </Col>
                            <Col style={style.SMALL}/>
                        </Parallax>
                    </GymnastProvider>
                </div>


            );
        }

        return (
            <FullWidthGrid/>
        );
    }
}

export default AboutMeStory;