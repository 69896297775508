import React from "react";
import {Card, CardText} from "reactstrap";
import {Col, Grid} from "gymnast";
import CustomButton from "./OneceHubBookingButton";
import {Button, CardImg} from "react-bootstrap";
import chris from "../resources/images/chris-corcoran.png";
import {Parallax} from "react-parallax";
import marble from "../resources/images/marble.png";
import Fade from "react-reveal";

const boxStyleGrey = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...boxStyleGrey, minHeight: '20vw', align: "top"},
    TALL: {...boxStyleGrey, minHeight: '50vw'},
    XTALL: {...boxStyleGrey, minHeight: '100vw', align: "top"},
    SMALL: {...boxStyleGrey, minHeight: 100},
    MEDIUM_WHITE: {...boxStyleGrey, minHeight: 300},
    XSMALL: {...boxStyleGrey, minHeight: 30},
};

class CoachingPackagesForIndividuals extends React.Component {
    render() {
        return (
            <Grid marginTop="S" paddingTop="0">
                <Parallax bgImage={marble} strength={500}>
                    <Col style={style.SMALL}/>
                    <Col style={{minHeight: '20vw', align: "top", backgroundColor: 'white'}}>
                        <Col size={6} style={style.MEDIUM}>
                            <Card className=' width100 border-0 center' style={{
                                backgroundColor: '#FFFFFF',
                                marginLeft: '10px',
                                marginRight: '10px',
                                width: '90%'
                            }}>
                                <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                                    fontSize: '28px',
                                    color: "black", textAlign: "left",
                                    width: "90%",
                                }}>
                                    <Fade left >
                                        <div>
                                            <h2 className='playfair_Display' style={{fontSize: '60px'}}>Career Crossroads</h2>
                                            <h2 className='playfair_Display' style={{fontSize: '20px'}}>Career Change Coaching Program </h2>
                                        </div>
                                    </Fade>
                                </CardText>
                            </Card>
                        </Col>
                        <Col size={6} style={style.MEDIUM}>
                            <br/>
                            <Card className=' width100 border-0 center'
                                  style={{
                                      backgroundColor: '#FFFFFF',
                                      marginLeft: '10px',
                                      marginRight: '10px',
                                      width: '90%'
                                  }}>
                                <Fade right >
                                    <div>
                                        <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                                            fontSize: '24px',
                                            color: "black", textAlign: "left",
                                            width: "90%",
                                        }}>

                                            <p className='playfair_Display'
                                               style={{fontSize: '22px', fontWeight: 'bold'}}>This is perfect if
                                                you're:</p>
                                            <ul>
                                                <li style={{fontSize: '24px'}}>At a crossroads in your career and not sure which direction to take</li>
                                                <li style={{fontSize: '24px'}}>You know you want to change your career but have no idea what you want to do
                                                </li>
                                                <li style={{fontSize: '24px'}}>You are unhappy where you are and know you want to change something, but you are unsure where to start
                                                </li>
                                            </ul>
                                        </CardText>
                                        <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                                            fontSize: '24px',
                                            color: "black", textAlign: "left",
                                            width: "90%",
                                        }}>
                                            <a className='CormorantGaramondWithoutSizeAndSpacing' style={{
                                                color: '#000000',
                                                backgroundColor: '#F9EBEA',
                                                borderRadius: '10px',
                                                border: '0',
                                                fontSize: '24px'
                                            }} target='blank' href="/career-crossroads">Read More</a>
                                        </CardText>
                                    </div>
                                </Fade>
                                <br/>
                            </Card>
                            <br/>
                        </Col>
                    </Col>
                    <Col style={style.SMALL}/>
                    <Col style={{minHeight: '20vw', align: "top", backgroundColor: 'white'}}>
                    <Col size={6} style={style.MEDIUM}>
                        <Card className=' width100 border-0 center' style={{
                            backgroundColor: '#FFFFFF',
                            marginLeft: '10px',
                            marginRight: '10px',
                            width: '90%'
                        }}>
                            <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                                fontSize: '28px',
                                color: "black", textAlign: "left",
                                width: "90%",
                            }}>
                                <Fade left >
                                    <div>
                                        <h2 className='playfair_Display' style={{fontSize: '60px'}}>Express Coaching Power Hour</h2>
                                        <h2 className='playfair_Display' style={{fontSize: '20px'}}>Your Solution for Immediate Professional Challenges</h2>
                                    </div>
                                </Fade>
                            </CardText>
                        </Card>
                    </Col>
                    <Col size={6} style={style.MEDIUM}>
                        <Card className=' width100 border-0 center'
                              style={{
                                  backgroundColor: '#FFFFFF',
                                  marginLeft: '10px',
                                  marginRight: '10px',
                                  width: '90%'
                              }}>
                            <Fade right >
                                <div>
                                    <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                                        fontSize: '24px',
                                        color: "black", textAlign: "left",
                                        width: "90%",
                                    }}>
                                        <br/>
                                        <p className='playfair_Display'
                                           style={{fontSize: '22px'}}>When professional challenges can't wait, the Express Coaching Power Hour is here to deliver swift, strategic support tailored to your urgent needs.
                                        </p>
                                        <br/>
                                        <p className='playfair_Display'
                                           style={{fontSize: '22px'}}>Priced at £150, this 90-minute one-on-one session is your fast track to overcoming obstacles such as workload overwhelm, navigating difficult conversations, boss frustrations, or CV concerns that aren't getting you interviews.
                                        </p>
                                    </CardText>
                                </div>
                            </Fade>
                        </Card>
                    </Col>
                    <Col>
                        <Card className=' width100 border-0 center' style={{
                            backgroundColor: '#FFFFFF',
                            marginLeft: '10px',
                            marginRight: '10px',
                            width: '100%'
                        }}>
                            <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                                fontSize: '28px',
                                color: "black", textAlign: "left",
                                width: "95%",
                            }}>
                                <h2 className='playfair_Display'
                                    style={{fontSize: '22px', fontStyle: 'italic'}}>What's included?</h2>
                                <CardText className='playfair_Display' style={{fontSize: '20px'}}><h4
                                    className='playfair_Display'
                                    style={{fontSize: '20px', fontWeight: 'bold'}}>Rapid Response: </h4>
                                    Fast-track booking to get you support within 48 hours, based on availability.
                                    This coaching session is specifically designed for quick turnaround when you
                                    need help asap
                                </CardText>
                                <CardText className='playfair_Display' style={{fontSize: '20px'}}><h4
                                    className='playfair_Display'
                                    style={{fontSize: '20px', fontWeight: 'bold'}}>Focused Coaching: </h4> Directly tackle your most pressing professional issue with expert coaching, tailored to target exactly what you need, when you need it most.
                                </CardText>
                                <CardText className='playfair_Display' style={{fontSize: '20px'}}><h4
                                    className='playfair_Display'
                                    style={{fontSize: '20px', fontWeight: 'bold'}}>Actionable Outcomes: </h4>
                                    Walk away with clear, actionable steps to immediately address your challenge but also equip you for future challenges.
                                </CardText>
                                <br/>
                                <p className='playfair_Display' style={{fontSize: '20px'}}><h2
                                    className='playfair_Display'
                                    style={{fontSize: '20px', fontWeight: 'bold'}}>Your Investment: £150</h2>
                                    In times of professional urgency, the Express Coaching Power Hour is your key to swift and effective resolution. Don't let challenges derail your progress—tackle them head-on with expert coaching.
                                </p>
                                <br/>
                                <p className='playfair_Display' style={{fontSize: '20px'}}><h2
                                    className='playfair_Display'
                                    style={{fontSize: '20px', fontWeight: 'bold'}}>Ready to get help?</h2>
                                    Spots for the Express Coaching Power Hour are highly sought after and fill up fast. Don't miss your chance for immediate, impactful coaching support.
                                </p>
                            </CardText>
                            <CardText className='playfair_Display'
                                      style={{color: "black", textAlign: 'left', fontSize:'20px'}}>
                                <Button className='playfair_Display'
                                        href="https://go.oncehub.com/JoannaStokesCoaching"
                                        style={{
                                            backgroundColor: '#85B2EE',
                                            borderColor: '#85B2EE',
                                            color: 'black',
                                            fontSize: '20px',
                                            fontWeight: 'bold'
                                        }} target='blank'
                                >Book Your Express Session Now</Button>
                            </CardText>
                        </Card>
                    </Col>
                </Col>
                    <Col style={style.SMALL}/>
                    <Col style={{minHeight: '20vw', align: "top", backgroundColor: 'white'}}>
                        <Col size={6} style={style.MEDIUM}>
                            <Card className=' width100 border-0 center' style={{
                                backgroundColor: '#FFFFFF',
                                marginLeft: '10px',
                                marginRight: '10px',
                                width: '90%'
                            }}>
                                <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                                    fontSize: '28px',
                                    color: "black", textAlign: "left",
                                    width: "90%",
                                }}>
                                    <Fade left >
                                        <div>
                                            <h2 className='playfair_Display' style={{fontSize: '60px'}}>Transform Your CV and LinkedIn Presence</h2>
                                        </div>
                                    </Fade>
                                </CardText>
                            </Card>
                        </Col>
                        <Col size={6} style={style.MEDIUM}>
                            <Card className=' width100 border-0 center'
                                  style={{
                                      backgroundColor: '#FFFFFF',
                                      marginLeft: '10px',
                                      marginRight: '10px',
                                      width: '90%'
                                  }}>
                                <Fade right >
                                    <div>
                                        <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                                            fontSize: '24px',
                                            color: "black", textAlign: "left",
                                            width: "90%",
                                        }}>
                                            <br/>
                                            <p className='playfair_Display'
                                               style={{fontSize: '22px'}}>Does your CV and LinkedIn profile need a makeover?  Whether you're eyeing that next career move or simply want to ensure your CV and LinkedIn profile are first rate, you're in the right place.
                                            </p>
                                            <br/>
                                            <p className='playfair_Display'
                                               style={{fontSize: '22px'}}>For just £250, I offer a comprehensive 90-minute 1:1 coaching session designed to transform your professional narrative.
                                            </p>
                                        </CardText>
                                    </div>
                                </Fade>
                            </Card>
                        </Col>
                        <Col>
                            <Card className=' width100 border-0 center' style={{
                                backgroundColor: '#FFFFFF',
                                marginLeft: '10px',
                                marginRight: '10px',
                                width: '100%'
                            }}>
                                <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                                    fontSize: '28px',
                                    color: "black", textAlign: "left",
                                    width: "95%",
                                }}>
                                    <h2 className='playfair_Display'
                                        style={{fontSize: '22px', fontStyle: 'italic'}}>How it works:</h2>
                                    <CardText className='playfair_Display' style={{fontSize: '20px'}}><h4
                                        className='playfair_Display'
                                        style={{fontSize: '20px', fontWeight: 'bold'}}>Pre-Session Audit & Reflection: </h4>
                                        Before we meet, I'll conduct a detailed audit of your LinkedIn profile. Plus, I'll send you a thought-provoking questionnaire designed to get you reflecting on your achievements, values, experience, and skills. This prep work ensures our session is laser-focused on maximising your profile's impact and aligning your professional story with your career aspirations.
                                    </CardText>
                                    <CardText className='playfair_Display' style={{fontSize: '20px'}}><h4
                                        className='playfair_Display'
                                        style={{fontSize: '20px', fontWeight: 'bold'}}>Tailored 1:1 Coaching: </h4> In our 90-minute session, we'll dive deep into your career aspirations, skills, and experiences. I'll guide you on how to craft a compelling CV and LinkedIn profile that not only showcases your professional journey but also resonates with your dream employers or clients.
                                    </CardText>
                                    <CardText className='playfair_Display' style={{fontSize: '20px'}}><h4
                                        className='playfair_Display'
                                        style={{fontSize: '20px', fontWeight: 'bold'}}>Follow-Up Feedback: </h4>
                                        After our session, you'll have the opportunity to send me your updated CV or LinkedIn profile for one final round of feedback. This ensures your documents are polished, professional, and ready to make an outstanding first impression.
                                    </CardText>
                                    <br/>
                                    <p className='playfair_Display' style={{fontSize: '20px'}}><h2
                                        className='playfair_Display'
                                        style={{fontSize: '20px', fontWeight: 'bold'}}>Your Investment: £250</h2>
                                        This one-off investment in your professional development is an investment in your future success. Ready to stand out from the crowd and make your mark?
                                    </p>
                                    <br/>
                                    <CardText className='playfair_Display' style={{fontSize: '20px'}}><h2 className='playfair_Display' style={{fontSize: '20px', fontWeight: 'bold'}}>Let's Get Started</h2>
                                        Spaces are limited, and my diary fills up fast. So secure your spot,
                                         and let's embark on this journey to raise your professional presence.
                                    </CardText>
                                    <br/>
                                    <CardText className='playfair_Display'
                                              style={{color: "black", textAlign: 'left', fontSize:'20px'}}>
                                        <Button className='playfair_Display'
                                                href="https://go.oncehub.com/JoannaStokesCoaching"
                                                style={{
                                                    backgroundColor: '#85B2EE',
                                                    borderColor: '#85B2EE',
                                                    color: 'black',
                                                    fontSize: '20px',
                                                    fontWeight: 'bold'
                                                }} target='blank'
                                        >Book Now</Button>
                                    </CardText>
                                </CardText>
                            </Card>
                        </Col>
                    </Col>
                    <Col style={style.SMALL}/>
                </Parallax>
                <Col style={style.XSMALL}/>
                <Col size={6} style={style.XSMALL}>
                    <Card className='width100 border-0 center '
                          style={{color: "black", backgroundColor: 'white'}}>
                        <br/>
                        <Fade left >
                            <div>
                                <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                                    fontSize: '28px',
                                    color: "black", textAlign: "left",
                                    width: "90%"
                                }}>I love helping my clients to <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                                    style={{
                                                                        fontWeight: 'bold',
                                                                        fontSize: '32px'
                                                                    }}>rediscover their passion and
                                    self-belief</h4>;
                                    and it
                                    seems they love it too.
                                    Have a look at this testimonial.
                                    It had me smiling for days…
                                    <br/>
                                    <br/>
                                </CardText>
                            </div>
                        </Fade>
                    </Card>
                    <Card className='width100 border-0 right '>
                        <Col style={style.XSMALL}>
                            <Col size={6} style={style.XSMALL}/>
                            <Col size={6} style={style.XSMALL}>
                                <Card className='border-0' style={{backgroundColor: 'rgba(0,0,0,0)'}}>
                                    <br/>
                                    <CardImg src={chris} style={{borderRadius: '10px'}}/>
                                </Card>
                            </Col>
                        </Col>
                    </Card>
                </Col>
                <Col size={6} style={style.MEDIUM}>
                    <Card style={{
                        color: "black",
                        backgroundColor: 'white',
                        borderColor: '#EAF8F9',
                        borderWidth: '5px'
                    }}>
                        <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                            fontSize: '20px',
                            color: "black", textAlign: "left",
                            fontStyle: 'italic'
                        }}>
                            <br/>
                            <p>“When I first contacted Joanna <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                                  style={{
                                                                      fontWeight: 'bold',
                                                                      fontSize: '22px'
                                                                  }}>I was completely lost</h4>.
                                I had long been unhappy in my work, but had a very well paid job and a very
                                specific
                                knowledge base that allowed me to be good at it.
                            </p>
                            <p><h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                   style={{
                                       fontWeight: 'bold',
                                       fontSize: '22px'
                                   }}> I wanted change but couldn’t see how. </h4></p>
                            <p> Joanna asked me all the right questions. At the times I didn’t have an
                                answer, but
                                Joanna kept pushing and kept digging until I found my next steps.</p>
                            <p> As fantastic as those conversations were, <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                                                              style={{
                                                                                  fontWeight: 'bold',
                                                                                  fontSize: '22px'
                                                                              }}>the best thing Joanna did
                                for me
                                was to
                                believe in me</h4>, even when I didn’t believe in myself.
                            </p>
                            <p><h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                   style={{
                                       fontWeight: 'bold',
                                       fontSize: '22px'
                                   }}> She has been invaluable</h4> to getting me to where I am now.
                            </p>
                            <p> Where is that? I’m starting out on my own, in an industry I have never
                                worked in,
                                with
                                no knowledge of what it takes to become as successful as I was in my last
                                career.</p>
                            <p><h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                   style={{
                                       fontWeight: 'bold',
                                       fontSize: '22px'
                                   }}> But what I do have now is a passion and a belief.</h4></p>
                            <p> I used to say I want to succeed.</p>
                            <p> Now, <h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                         style={{
                                             fontWeight: 'bold',
                                             fontSize: '22px'
                                         }}>because of my work with Joanna</h4>, I say I will.”
                            </p>
                            <br/>
                            <p><h4 className='CormorantGaramondWithoutSizeAndSpacing'
                                   style={{
                                       fontWeight: 'bold',
                                       fontSize: '22px',
                                       fontStyle: 'initial'
                                   }}>Chris Corcoran. Redcar, June 2020</h4>
                            </p>
                            <br/>
                        </CardText>
                    </Card>
                </Col>
                <Parallax bgImage={marble} strength={500}>
                    <Col style={style.SMALL}/>
                    <Col style={{minHeight: '20vw', align: "top", backgroundColor: 'white'}}>
                        <Col size={6} style={style.MEDIUM}>
                            <Card className=' width100 border-0 center'
                                  style={{
                                      backgroundColor: '#FFFFFF',
                                      marginLeft: '10px',
                                      marginRight: '10px',
                                      width: '100%'
                                  }}>
                                <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                                    fontSize: '28px',
                                    color: "black", textAlign: "left",
                                    width: "90%",
                                }}>
                                    <Fade top >
                                        <div>
                                            <h2 className='playfair_Display' style={{fontSize: '60px'}}> Bespoke
                                                Coaching
                                                Packages </h2>
                                        </div>
                                    </Fade>
                                    <Fade left >
                                        <div>
                                            <br/>
                                            I can offer you a completely bespoke coaching package, tailored to your
                                            exact
                                            needs.
                                            This starts with an introductory free call, where we chat for up to an hour.
                                            I
                                            ask
                                            you lots
                                            of questions to help me get to know you, your situation and your concerns.
                                        </div>
                                    </Fade>
                                </CardText>
                                <br/>
                            </Card>
                        </Col>

                        <Col size={6} style={style.MEDIUM}>
                            <Card className=' width100 border-0 center' style={{
                                backgroundColor: '#FFFFFF',
                                marginLeft: '10px',
                                marginRight: '10px',
                                width: '100%'
                            }}>
                                <Fade right >
                                    <div>
                                        <CardText className="CormorantGaramondWithoutSizeAndSpacing margin-left " style={{
                                            fontSize: '28px',
                                            color: "black", textAlign: "left",
                                            width: "90%",
                                        }}>
                                            <br/>
                                            We then explore the ways I can help you.
                                            The call also lets you get a feel for me and how I work, so you can decide
                                            if
                                            we’re
                                            a good
                                            fit.
                                            I then provide you with a quote for exactly what you need.
                                            <br/>
                                            <br/>
                                            You have 28 days to think
                                            about it with absolutely no pressure or obligation.
                                            If you’re ready to work together, I send over a secure link for payment and
                                            then
                                            our
                                            adventure begins!
                                            <br/>
                                            <br/>
                                        </CardText>
                                        <CardText className="playfair_Display margin-left " style={{
                                            fontSize: '28px',
                                            color: "black", textAlign: "left",
                                            width: "90%",
                                        }}>
                                            <CustomButton text="Book A Free 60 Minute Chat" color='#85B2EE'/>
                                            <br/>
                                        </CardText>
                                    </div>
                                </Fade>
                            </Card>
                        </Col>
                    </Col>
                    <Col style={style.SMALL}/>
                </Parallax>

                <Col style={style.SMALL}/>

            </Grid>
        )


    }
}

export default CoachingPackagesForIndividuals;