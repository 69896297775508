import React from "react";
import {Card, CardText} from "reactstrap";
import Fade from 'react-reveal';

class WhatCoachingCanDoForYou extends React.Component {
    render() {
        return (
            <Card className='width100 border-0'>
                <Fade right big >
                    <div>
                <CardText className='CormorantGaramond' style={{fontSize: '4vw', color: "black"}}>
                    What Leadership And Career Coaching Can Do For <h4 style={{fontStyle: 'italic', fontSize: '4vw'}}>you</h4>
                </CardText>
                    </div>
                </Fade>
                <CardText className='CormorantGaramond' style={{fontSize: '2vw', color: "black"}}>
                    <Fade left big >
                        <div>
                            <p className='CormorantGaramond'><h4 className='CormorantGaramond'
                                                                 style={{fontWeight: 'bold'}}
                            >Builds </h4> your self-awareness and empowers you to make the right choices – for a more balanced career and life

                            </p>
                            <p className='CormorantGaramond'><h4 className='CormorantGaramond'
                                                                 style={{fontWeight: 'bold'}}
                            >Unlocks </h4> your potential to perform to your best – so you can see real results from your hard work
                            </p>
                            <p className='CormorantGaramond'><h4 className='CormorantGaramond'
                                                                 style={{fontWeight: 'bold'}}
                            >Guides </h4> you to move your career in the right direction for you – finding a path to success and happiness

                            </p>
                            <p className='CormorantGaramond'><h4 className='CormorantGaramond'
                                                                 style={{fontWeight: 'bold'}}
                            >Helps </h4> you tune in to yourself and others – so you can lead from the heart and inspire
                            </p>
                            <p className='CormorantGaramond'><h4 className='CormorantGaramond'
                                                                 style={{fontWeight: 'bold'}}
                            >Provides </h4> you with the tools, structure and support to accomplish more in less time
                            </p>
                            <p className='CormorantGaramond'><h4 className='CormorantGaramond'
                                                                 style={{fontWeight: 'bold'}}
                            >Inspires </h4> you to take control of your career and start living the life you deserve
                            </p>
                        </div>
                    </Fade>
                </CardText>
            </Card>
        )
    }
}

export default WhatCoachingCanDoForYou;