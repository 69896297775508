import React from "react";
import {Card, CardImg, CardText} from "reactstrap";
import {Col, Grid} from "gymnast";
import coffee from "../../resources/images/desk_coffee.jpeg";
import Fade from "react-reveal";

const transparent = {
    color: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(0,0,0,0)'
};

const style = {
    HEADER: {position: "fixed", align: "top"},
    MEDIUM: {...transparent, minHeight: 500, align: "top"},
    TALL: {...transparent, minHeight: 1600},
    XTALL: {...transparent, minHeight: 5000, align: "top"},
    SMALL: {...transparent, minHeight: 100},
    SMALL_WHITE: {...transparent, minHeight: 100},
    SMALL_GREY: {...transparent, minHeight: 100},
    MEDIUM_WHITE: {...transparent, minHeight: 300},
    XSMALL: {...transparent, minHeight: 75}
};

class Testimonial3 extends React.Component {
    render() {
        return (
            <Card className='width100 border-0 h-100 '
                  style={{fontSize: '12px', color: "black", backgroundColor: '#F9EBEA'}}>
                <Grid marginTop="S" paddingTop="M">
                    <Col size={6} style={style.SMALL}>
                        <Card className='width100 border-0 h-100 no-background'
                              style={{fontSize: '11px', color: "black"}}>
                            <CardText>
                                <Fade left >
                                    <div>
                                        <br/>
                                        <p className='ibarra'> "I was able to expand on my comfort zone..." </p>
                                        <br/>
                                        <p className='ibarra' style={{fontWeight: 'bold', fontSize: '9px'}}> KL, Redcar,
                                            May 2020</p>
                                        <a className='CormorantGaramondWithoutSize' style={{fontSize: '9px'}}
                                           target='blank' href="/testimonialThree"> Read More </a>
                                        <br/>
                                    </div>
                                </Fade>
                            </CardText>
                        </Card>
                    </Col>
                    <Col size={6} style={style.SMALL}>
                        <Card className='h-150 border-0 width100 no-background'>
                            <CardImg src={coffee} style={{borderRadius: '10px', alignSelf: 'center', marginTop: '20px'}}
                                     className='card-img '/>
                        </Card>
                    </Col>
                </Grid>
            </Card>
        )
    }
}

export default Testimonial3;